import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-challengeResult',
  templateUrl: './challengeResult.component.html',
  styleUrls: ['./challengeResult.component.css']
})
export class ChallengeResultComponent implements OnInit {
  user!: string;
  pass!: string;
  country!: string;
  challenge_startedid!: number;
  Challenger: any;
  Opponent: any;
  ResultChallenge: any;

  constructor(
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    let countryapp = localStorage.getItem('country');
    if (userapp != null && passapp != null && countryapp != null) {
      this.user = userapp;
      this.pass = passapp;
      this.country = countryapp;
    }
    this.challenge_startedid = this.route.snapshot.params.id;

    this.CheckChallengeWinner();
  }

  async CheckChallengeWinner(): Promise<void> {

    await this.categoryService.CheckChallengeWinner(this.challenge_startedid,this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);
          data.user.imageFullPath = this.categoryService.baseURLimg + data.user.imageFullPath;
          data.userChallenged.imageFullPath = this.categoryService.baseURLimg + data.userChallenged.imageFullPath;
          this.Challenger = data.user;
          this.Opponent = data.userChallenged;
          this.ResultChallenge = data;
        },
        error => {
          console.log(error);
        }
      );
  }

}
