import { Directive, ElementRef, HostListener } from '@angular/core';
import { NavigationService } from '../services/Navigation.service';

@Directive({
  selector: '[backButton]',
})
export class BackButtonDirective {
  constructor(private navigation: NavigationService, private el: ElementRef) {}

  @HostListener('click')
  onClick(): void {
    console.log('clicked');
    this.navigation.back();
  }
}