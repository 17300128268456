import { UsersService } from './../../services/users.service';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent implements OnInit {


  hide: boolean = true;
  errorLogin: boolean = false;
  popup: boolean = false;
  popupSent: boolean = false;
  wait: boolean = false;
  emailExist: boolean = false;

  constructor(private router: Router,
    private usersService: UsersService,
    private fb: FormBuilder) {
  }



  userLoginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  userResetForm = this.fb.group({
    email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
  });
  // loginForm = new FormGroup({
  //   email: new FormControl(''),
  //   password: new FormControl(''),
  // });

  onSubmit(): void {

    const user = this.userLoginForm.get('email')?.value;
    const pass = this.userLoginForm.get('password')?.value;

    this.usersService.userLogin(user, pass)
      .subscribe(
        data => {
          if (data.result) {
            localStorage.setItem('user', user);
            localStorage.setItem('pass', pass);
            this.errorLogin = false;
            this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
          }
          else {
            console.log(data.error);
            this.errorLogin = true;
          }
        },
        error => {
          console.log(error);
        });

    // TODO: Use EventEmitter with form value
    //console.warn(this.loginForm.value);
  }
  popupForgotPassword() {
    this.popup = true;
  }
  ClosePopup() {
    this.popup = false;
  }
  ClosePopupSent() {
    this.popupSent = false;
  }
  ngOnInit() {
  }
  onSubmitReset(): void {
    this.usersService.ForgotPassword(this.userResetForm.get('email')?.value)
      .subscribe(
        data => {
          this.popup = false;
          this.popupSent = true;
        },
        error => {
          this.popup = false;
        });
  }
  checkEmailExist(): void {
    console.log("checked");
    this.wait = true;
    this.usersService.EmailExist(this.userResetForm.get('email')?.value)
      .subscribe(
        data => {
          this.emailExist = data;

          if (!data) {
            this.userResetForm.get('email')?.setErrors({ invalid: true });

          }
          this.wait = false;
          console.log(this.emailExist);
        },
        error => {
          console.log(error);
        });
  }

}
