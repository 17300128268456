import { XiaomiBadgeComponent } from './components/custom/xiaomi-badge/xiaomi-badge.component';
import { XiaomiImprintComponent } from './components/custom/xiaomi-imprint/xiaomi-imprint.component';
import { ChallengescomingsoonComponent } from './components/main-page/challenges/challengescomingsoon/challengescomingsoon.component';
import { KeywordInputComponent } from './components/search-page/keyword_input_mobile/keyword-input/keyword-input.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { NRelatedPillsComponent } from './components/right-container/n-related-pills/n-related-pills.component';
import { NNewsComponent } from './components/right-container/n-news/n-news.component';
import { MatIconModule } from '@angular/material/icon';
import { XiaomiButtonComponent } from './components/custom/xiaomi-button/xiaomi-button.component';
import { PilldetailComponent } from './components/main-page/homepage/pilldetail/pilldetail.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomepageComponent } from './components/main-page/homepage/homepage.component';
import { InterceptorService } from './services/loader/interceptor.service';
import { GridColsDirective } from './directives/gridCols.directive';
import { EditUserComponent } from './components/main-page/user-profile/edit-user/edit-user.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SurveysComponent } from './components/main-page/surveys/surveys.component';
import { NewsComponent } from './components/main-page/news/news.component';
import { NewsdetailComponent } from './components/main-page/news/newsdetail/newsdetail.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SupportComponent } from './components/main-page/support/support.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { UserProfileComponent } from './components/main-page/user-profile/user-profile.component';
import { HowitworksComponent } from './components/main-page/howitworks/howitworks.component';
import { LeaderboardComponent } from './components/main-page/leaderboard/leaderboard.component';
import { RewardsComponent } from './components/main-page/rewards/rewards.component';
import { BookmarksComponent} from './components/main-page/bookmarks/bookmarks.component';
import { BackButtonDirective } from './directives/backButton.directive';
import { NotificationsarchiveComponent } from './components/main-page/notificationsarchive/notificationsarchive.component';
import { TermsconditionComponent } from './components/main-page/termscondition/termscondition.component';
import { PrivacypolicyComponent } from './components/main-page/privacypolicy/privacypolicy.component';
import { NForyouPillsComponent } from './components/right-container/n-foryou-pills/n-foryou-pills.component';
import { RewardscomingsoonComponent } from './components/main-page/rewards/rewardscomingsoon/rewardscomingsoon.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { ChallengesComponent } from './components/main-page/challenges/challenges.component';
import { ChallengeSearchOpponentComponent } from './components/main-page/challenges/challengeSearchOpponent/challengeSearchOpponent.component';
import { ChallengesLeaderboardComponent } from './components/main-page/challenges/challengesLeaderboard/challengesLeaderboard.component';
import { ChallengeResultComponent } from './components/main-page/challenges/challengeResult/challengeResult.component';
import { SureToDeleteComponent } from './components/main-page/user-profile/edit-user/sureToDelete/sureToDelete.component';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    RegistrationFormComponent,
    HomepageComponent,
    MainPageComponent,
    PilldetailComponent,
    GridColsDirective,
    XiaomiButtonComponent,
    XiaomiImprintComponent,
    XiaomiBadgeComponent,
    EditUserComponent,
    SurveysComponent,
    NewsComponent,
    NewsdetailComponent,
    SupportComponent,
    UserProfileComponent,
    HowitworksComponent,
    LeaderboardComponent,
    RewardsComponent,
    RewardscomingsoonComponent,
    BookmarksComponent,
    BackButtonDirective,
    NotificationsarchiveComponent,
    TermsconditionComponent,
    PrivacypolicyComponent,
    NNewsComponent,
    NForyouPillsComponent,
    NRelatedPillsComponent,
    SearchPageComponent,
    KeywordInputComponent,
    ChallengescomingsoonComponent,
    WelcomePageComponent,
    ChallengesComponent,
    ChallengeSearchOpponentComponent,
    ChallengesLeaderboardComponent,
    ChallengeResultComponent,
    SureToDeleteComponent
   ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }
    ),
    AngularSvgIconModule.forRoot(),
    NgbModule,
    CarouselModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass:InterceptorService,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
