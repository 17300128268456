<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
  <h1 class="col-md-6 mx-auto mt-3 mb-5 titlenews">{{'menu_news' | translate}}</h1>
  <div *ngIf="counter">
    <div *ngFor="let notice of news" [id]="notice.id" class="col-md-6 mx-auto mt-3" layout="row" layout-align="center center" [routerLink]="['/mainpage/newsdetail', notice.id]">
      <div style="width: 50%;float:left;height: 100px;">
        <img src="{{baseURL}}{{notice.imageLargeFullPath}}" style="max-width: 120px; max-height: 80px;border-radius: 15%;">
      </div>
      <div style="height: 110px;">
        <span class="date">{{notice.dateText}}</span>
        <p class="title">{{notice.title}}</p>
        <span class="category">{{notice.category}}</span>
        <p class="category">{{notice.subCategory}}</p>
      </div>
      <div style="height: 1px;background-color: #B9B6B6;"></div> 
    </div>
  </div>

  <h1 *ngIf="counter == false"  class="center" style="color: var(--main-text-color);">{{'home_sorry' | translate}}</h1>