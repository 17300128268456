import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.css']
})
export class BookmarksComponent implements OnInit {

  user!: string;
  pass!: string;
  bookmarksAll: any = [];
  completed: any = [];
  onGoing: any = [];
  constructor(private categoryService: CategoryService,
    public mainpage: MainPageComponent) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }

    this.getPills();
  }

  OnPillTabsChange(event: any) {


  }
  async getPills(): Promise<void> {

    await this.categoryService.GetPillsAuth(this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);


          for (let i = 0; i < data.length; i++) {
            if (data[i].favourite) {
              data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath.replace('~', '')
              this.bookmarksAll.push(data[i]);
              if (data[i].isCompleted || data[i].startText == "REPEAT") {
                this.completed.push(data[i]);
              }
              else if (data[i].isOnGoing || data[i].startText == "CONTINUE") {
                this.onGoing.push(data[i]);
              }
            }
          }
          console.log(this.bookmarksAll);
          console.log(this.completed);
          console.log(this.onGoing);
        },
        error => {
          console.log(error);
        }
      );
  }

}
