import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xiaomi-button',
  templateUrl: './xiaomi-button.component.html',
  styleUrls: ['./xiaomi-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class XiaomiButtonComponent implements OnInit {

  @Input() type = 'button'; // default value if none is passed
  @Input() disabled = false; // default value if none is passed
  @Output() click: EventEmitter<any> = new EventEmitter<any>();


  onClickButton(event: any) {
    event.stopPropagation();
    this.click.emit(event);
  }

  constructor() { }

  ngOnInit() {
  }

}
