<div style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="container-fluid mx-auto p-0" style="max-width: 98%;">

  <div class="row ml-0 mr-0 pl-0 pr-0">
    <h1 class="col-sm-12" style="padding-top: 10px;">{{'home_leaderboard' | translate}}</h1>
  </div>
  <div class="row ml-0 mr-0 pl-0 pr-0">
    <table class="table table-responsive text-center table-borderless"
      style="background-color: #FF6900;height: 80px;display: table;">
      <tbody>
        <tr>
          <td style="vertical-align: middle;"><img class="AvatarImgBig" src="{{AvatarImgSrc}}"></td>
          <td style="vertical-align: middle;">
            <p class="white-text" style="font-weight: bold;">#{{UserPosition}}</p>
          </td>
          <td style="vertical-align: middle">
            <svg-icon [name]="'points_white'" [svgStyle]="{ 'width.px': '35' }"></svg-icon>
            <div class="white-text"> {{'CoursePage_mypoints' | translate}} <p style="font-weight: bold;"> {{UserPoint}}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row ml-0 mr-0">
    <div class="col-12 p-0">

      <table class="table table-responsive text-center" style="display: table">
        <thead>
          <tr>
            <td colspan="2">

            </td>
            <td>
              {{'challenges_position' | translate}}
            </td>
            <td>
              {{'CoursePage_points' | translate}}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let leadearboad of Leadearboads">
            <td style="align-content: center;"><img class="AvatarImg" src="{{leadearboad.imageFullPath}}"></td>

            <td style="vertical-align: middle; overflow: hidden;">
              <p style="font-weight: bold; max-width: 95px;">{{leadearboad.nickName}}</p>
            </td>
            <td style="vertical-align: middle;">
              <p style="color: #FF6900;font-weight: bold;">{{leadearboad.position}}</p>
            </td>
            <td style="vertical-align: middle;">
              <p style="font-weight: bold;">{{leadearboad.point}} </p>
            </td>

          </tr>
        </tbody>
      </table>


    </div>
  </div>
</div>