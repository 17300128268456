import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PolicyInput } from 'src/app/model/ModelInput';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-termscondition',
  templateUrl: './termscondition.component.html',
  styleUrls: ['./termscondition.component.css']
})
export class TermsconditionComponent implements OnInit {

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;
  country = localStorage.getItem("country")!;

  Terms: any;

  constructor(
    private categoryService: CategoryService,
    public mainpage: MainPageComponent) { 
    this.TermsSer();
  }

  ngOnInit() {
  }

  TermsSer(){
    let data: PolicyInput;
    data = new PolicyInput(0,this.country);
    this.categoryService.getPolicyAuth(data, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.Terms = data.body;
          console.log(this.Terms);
        },
        error => {
          console.log(error);
        });
  }
}
