import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  email: any;

  constructor(
    private router: Router,
    translate: TranslateService,
    public mainpage: MainPageComponent
  ) { 

    //SCOMMENTARE uso in caso di test
    //translate.use('uk');

    translate
      .get('support_mailtraining')
      .subscribe((text:string) => {this.email = text});
  }

  ngOnInit() {
  }

  GoToPage(){
     window.open('https://www.mi.com/global/verify', "_blank");
  }

  sendEmail(){
    window.location.href = 'mailto:' + this.email;
  }
}