import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//everest
const baseURL = 'http://151.11.49.215:8090/api/Catalog/';

//pre-prod
// const baseURL = 'https://xiaomitrainingapp.azurewebsites.net/api/Catalog/';
 

/*PROD*/
// const baseURL = 'https://xiaomiacademy.azurewebsites.net/api/Catalog/';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
//TO-DO rendere uguali in tutti gli ambienti

  //EVEREST
  //email: string = 'usertest@ulisse1984.it';

  //PRE-PROD
  email: string = 'giuseppe.cirino@ulisse1984.it';

  /*PROD*/
  // email: string = 'usertest@ulisse1984.it';
  pass: string = 'P@ssword0';
  authorizationData: string = 'Basic ' + btoa(this.email + ':' + this.pass);

  constructor(private httpClient: HttpClient) { }

  userLogin(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'


      })
    };

    const body = {
      "Email": email,
      "Password": password,
      "RememberMe": false
    };
    //console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'LoginApp', body, options);
  }
  userRegister(Email: string, Password: string, ConfirmPassword: string,
    Name: string, Surname: string, Country: string, IdChannel: string,
    IdRegion: string, Nickname: string, IdStore: number, UserId: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      "Email": Email,
      "Password": Password,
      "ConfirmPassword": ConfirmPassword,
      "Name": Name,
      "Surname": Surname,
      "Country": Country,
      "IdChannel": IdChannel,
      "IdRegion": IdRegion,
      "Nickname": Nickname,
      "IdStore": IdStore,
      "UserId": UserId
    }
    console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'RegisterWebApp', body, options);
  }

  nicKNameExist(nickname: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': this.authorizationData
      })
    };

    const body = {
      "Nickname": nickname,
    };
    console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'NickNameExist', body, options);
  }

  EmailExist(email: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };

    const body = {
      "Email": email,
    };
    console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'EmailExist', body, options);
  }

  postFileDefault(email: string, password: string): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //'Authorization': 'Basic ' + btoa(email + ':' + password)
      })
    };

    const formData: FormData = new FormData();
    formData.append('Image', "");
    formData.append('email',email);
    formData.append('photodefault',"true");
    return this.httpClient
      .post(baseURL + 'photo', formData, options);
  }
  postFile(fileToUpload: File, email: string, password: string): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //'Authorization': 'Basic ' + btoa(email + ':' + password)
      })
    };

    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    formData.append('email',email);
    formData.append('photodefault',"false");
    return this.httpClient
      .post(baseURL + 'photo', formData, options);
  }
  ForgotPassword(email: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };

    const body = {
      "Email": email,
    };
    console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'ForgotPassword', body, options);
  }

  DeleteUser(email: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };

    const body = {
      "Email": email,
    };
    console.log(JSON.stringify(body));
    return this.httpClient
      .post(baseURL + 'ConfirmDeleteAccount', body, options);
  }

}
