import { CategoryService } from 'src/app/services/category.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaderBoardModel } from 'src/app/model/LeaderBoardModel';
import { ChallengeLimitInput, UserLeaderBoardChallengeLimitInput } from 'src/app/model/ModelInput';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-challengesLeaderboard',
  templateUrl: './challengesLeaderboard.component.html',
  styleUrls: ['./challengesLeaderboard.component.css']
})
export class ChallengesLeaderboardComponent implements OnInit {

  user!: string;
  pass!: string;
  AvatarImgSrc!:string;
  UserPoint!:string;
  UserCompletedChallenges!:string;
  UserPosition!:string;
  Leadearboads:LeaderBoardModel[]=[];
  constructor(private categoryService: CategoryService,
    private router: Router,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    this.GetChallengeLeaderboard();
    this.UsersLeaderBoardChallengeUserLimitAuth();
  }

  async GetChallengeLeaderboard(): Promise<void> {
    let order: any = null;
    // let dataChallenge = new ChallengeLimitInput(0,10,order!);
    await this.categoryService.GetChallengeLeaderboard( this.user, this.pass)
      .subscribe(
        data => {
          
          data.imageFullPath = this.categoryService.baseURLimg + data.imagePath+"?"+(new Date()).getTime();
          
          //this.Leadearboads=data;

          this.AvatarImgSrc = this.categoryService.baseURLimg + data.imagePath;
          this.UserPoint = data.victoryChallenge;
          this.UserCompletedChallenges = data.completedChallenge;
          this.UserPosition = "#" + data.position;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  async UsersLeaderBoardChallengeUserLimitAuth(): Promise<void> {
    let order: any = null;
    let dataChallenge = new UserLeaderBoardChallengeLimitInput(0,999,1,0,0);
    await this.categoryService.UsersLeaderBoardChallengeUserLimitAuth(dataChallenge, this.user, this.pass)
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath+"?"+(new Date()).getTime();
          }
          this.Leadearboads=data;

          // this.AvatarImgSrc = this.categoryService.baseURLimg + data.personlData.imageFullPath;
          // this.UserPoint = data.personlData.point;
          // this.UserPosition = data.personlData.position;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
}

