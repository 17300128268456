import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-redirectpage',
  templateUrl: './redirectpage.component.html',
  styleUrls: ['./redirectpage.component.css']
})
export class RedirectpageComponent implements OnInit {
  pageId!: number;
  contentId!: number;
  user!: string;
  pass!: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.pageId = this.route.snapshot.params.PageId;
    this.contentId = this.route.snapshot.params.ContentId;
    console.log(this.pageId);
    console.log(this.contentId);

    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');



    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;

    }
    else {
      this.router.navigateByUrl('/login');
    }

    if (this.pageId == 1) {
      //newCourse;
      this.router.navigateByUrl('/mainpage/(pilldetail/' + this.contentId + '//sidebar:related/'+ this.contentId +')' );

    }
    else if (this.pageId == 2) {
      //newSurvey;
      this.router.navigateByUrl('/mainpage/(surveys//sidebar:news)' );

    } else if (this.pageId == 3) {
      //newNews;
      this.router.navigateByUrl('/mainpage/(newsdetail/' + this.contentId + '//sidebar:foryou)' );

    } else if (this.pageId == 4) {
      //newTOS;
      this.router.navigateByUrl('/mainpage/(termscondition//sidebar:news)' );

    }
    else if (this.pageId == 5) {
      //NewPrivacy;
      this.router.navigateByUrl('/mainpage/(privacypolicy//sidebar:news)' );

    } else if (this.pageId == 6) {
      //Badge;
      this.router.navigateByUrl('/mainpage/(userprofile//sidebar:news)' );
    }else if (this.pageId == 7) {
      //Leaderboard;
      this.router.navigateByUrl('/mainpage/(leaderboard//sidebar:news)' );
    }else if (this.pageId == 8) {
      //challengestarted;
      this.router.navigateByUrl('/mainpage/(challenges//sidebar:news)?section=1' );
    }else if (this.pageId == 9) {
      //Leaderboard;
      this.router.navigateByUrl('/mainpage/(challenges//sidebar:news)?section=2' );
    }


  }



}
