import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueRewardModel } from 'src/app/model/CatalogueRewardModel';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {

  UserPoint!: number;
  user!: string;
  pass!: string;
  catalogo!: CatalogueRewardModel[];
  collected:CatalogueRewardModel[]=[];
  counterCatalogo: boolean = false;
  counterCollected: boolean = false;
  constructor(
    private categoryService: CategoryService,
    private router: Router,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    this.CatalogueUserAuth();
    this.getUserAuth();
  }
  OnPillTabsChange(event: any) {

  }

  takeRewards(idPremio: number) {
    let i = 0;
    let found = false;
    let points = 0;
    while (i < this.catalogo.length) {
      if (this.catalogo[i].id == idPremio) {
        found = true;
        points = this.catalogo[i].point;
      }
      i++;
    }
    if (points != 0) {
      this.RequestCatalogueUserAuth(idPremio, points);
    }
  }


  async RequestCatalogueUserAuth(idPremio: number, points: number): Promise<void> {
    await this.categoryService.RequestCatalogueUserAuth(idPremio, points, this.user, this.pass)
      .subscribe(
        data => {
          this.CatalogueUserAuth();
          this.getUserAuth();
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  async CatalogueUserAuth(): Promise<void> {
    await this.categoryService.CatalogueUserAuth(this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);
          this.collected=[];
          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath;
            if(data[i].isTake)
            this.collected.push(data[i]);
          }
          console.log(this.collected);
          this.catalogo = data.sort((a:any, b:any) => (a.point < b.point ? -1 : 1));
          this.catalogo = data.sort((a:any, b:any) => (a.isTake < b.isTake ? -1 : 1));

          if(this.catalogo.length == 0)
            this.counterCatalogo = false;
          else
            this.counterCatalogo = true;

          if(this.collected.length == 0)
            this.counterCollected = false;
          else
            this.counterCollected = true;
          
        },
        error => {
          console.log(error);
        });
  }
  async getUserAuth(): Promise<void> {
    await this.categoryService.getUserAuth(this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);
          this.UserPoint = data.point - data.pointConsumed;
        },
        error => {
          console.log(error);
        });
  }
  getTimeLoaded(tab: number): number {
    return tab;
  }
}
