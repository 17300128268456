<svg-icon backButton style="cursor: pointer; float: right;" class="mt-3 mr-5" [name]="'esc'" [svgStyle]="{ 'width.px': '40' }"></svg-icon><br>
<div class="col-md-6 mx-auto mt-5" layout="row" layout-align="center center">

  <div>
    <div>
      <!--<a backButton class="close">&times;</a>-->
      <!--<div [innerHTML]="Terms"></div>-->
        <div class="center mb-5">
          <img src="../../../assets/images/logo_grande.png"
              style="width: 250px;" class="p-3">
        </div>
        <span class="text center">{{'challenges_challengesleade' | translate}}</span><br>
        <span class="text center" style="text-align: center;">{{'menu_nochallenges' | translate}}</span><br>
        <svg-icon class="center" [name]="'challenge_orange'" [svgStyle]="{ 'width.px': '280' }"></svg-icon><br>
        <div class="mx-auto" style="max-width: 220px;">
        <span class="text center" style="text-align: center;line-height: 1.2">{{'rewards_rewadswillbeadd' | translate}}</span>
      </div>
    </div>
  </div>
</div>