<div class="searchOpponentContainer">
<!--BACK BUTTON-->
  <div style="background-color: black;height: 40px;">

    <div [routerLink]="['/mainpage/challenges']" [queryParams]="{section: 0}" style="cursor: pointer;">
      <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
        style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
      <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
    </div>
  </div>

  <div class="row ml-0 mr-0 pl-0 pr-0">
    <h1 class="col-sm-12 col-md-10" style="padding-top: 10px;">{{'menu_challenges' | translate}}</h1>
    <h2 class="col-sm-12 col-md-10"> {{'challenges_searchforoppone' | translate}}</h2>
  </div>

  <div class="list row center" id="filter_container">
    <!-- category contariner -->
    <div>
      <mat-button-toggle-group class="filterGroup pt-2 pb-2" (change)="toggleChangeSearch($event)">
            <mat-button-toggle disableRipple id="1" checked="true"
              color="accent" class="filter position-relative">
              <img src="../../../assets/images/region_filter.png" class="mt-2" style="width: 40px; height: 40px; position: absolute; left: 30%;">
              <div class="challenge-text">{{'challenges_same' | translate}}</div>
              <div class="challenge-counter">{{'challenges_region' | translate}}</div>
            </mat-button-toggle>
          <mat-button-toggle disableRipple id="2"
            color="accent" class="filter position-relative">
            <img src="../../../assets/images/channel_filter.png" class="mt-2" style="width: 40px; height: 40px; position: absolute; left: 30%;">
            <div class="challenge-text">{{'challenges_by' | translate}}</div>
            <div class="challenge-counter">{{'challenges_channel' | translate}}</div>
          </mat-button-toggle>
          <mat-button-toggle disableRipple id="3"
            color="accent" class="filter position-relative">
            <img src="../../../assets/images/name_filter.png" class="mt-2" style="width: 40px; height: 40px; position: absolute; left: 30%;">
            <div class="challenge-text">{{'challenges_name' | translate}}</div>
            <div class="challenge-counter">{{'challenges_list' | translate}}</div>
          </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- LIST SAME REGION -->
  <div class="mb-3 mt-3" style="max-width: 550px; margin: auto" *ngIf = "filterSelected == 1">
    <div class="contentChannels mx-auto">
      <!--<img src="../../../assets/images/down.png" class="mt-2 mb-2 center" style="width: 20px;margin-right: 90%;margin-left: auto;">-->
      <table class="table table-responsive text-center" style="display: table" *ngIf="usersChallenge?.length > 0">
        <tbody>
          <tr *ngFor="let user of usersChallenge">
            <td style="align-content: center; width: 20%;"><img class="AvatarImg" src="{{user.imageFullPath}}"></td>
  
            <td style="vertical-align: middle; width: 20%;">
              <p style="font-weight: bold;">{{user.nickName}}</p>
              <p style="margin-top: -8px; color: grey;">{{user.region}}</p>
              <p style="margin-top: -10px; color: grey">{{user.channel}}</p>
            </td> 
            <td style="width: 30%;"></td>

            <td style="vertical-align: middle; width: 30%;">
              <xiaomi-button (click)="invite(user.id)">
                {{'Buttons_invite' | translate}}
              </xiaomi-button>
            </td>
  
          </tr>
        </tbody>
      </table>
      <p *ngIf="usersChallenge?.length < 1" class="text-center" style="color: var(--main-text-color); font-size: 18px; font-weight: bold;">{{'challenges_therearenouser' | translate}}</p>
    </div>
  </div>

  <!-- LIST CHANNEL SELECTED -->
  <div class="mb-3" style="max-width: 350px; margin: auto" *ngIf = "filterSelected == 2">
    <div class="contentChannels mx-auto">
      <img src="../../../assets/images/down.png" class="mt-2 mb-2 center" style="width: 20px;margin-right: auto;margin-left: auto;">
      <div style="height: 3px;background-color: var(--main-text-color);"></div>
      <mat-button-toggle-group class="w-100 mr-3" required vertical="true"  style="overflow-y: scroll; max-height: 400px;">
        <mat-button-toggle *ngFor="let channel of channels" color="accent" [value]="channel.id" class="channelList" (click)="userChannelSelected(channel.id)"
          style="padding: 5px;">
          <div style="line-height: 1.5">
            <span>{{channel.canale}}</span><br>
            <span class="sottoCanale">{{channel.sottoCanale}}</span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div style="height: 3px;background-color: var(--main-text-color);"></div>
    </div>
  </div>

  <div class="mb-3" style="max-width: 550px; margin: auto" *ngIf = "channelSelected">
    <div class="contentChannels mx-auto">
      <img src="../../../assets/images/down.png" class="mt-2 mb-2 center" style="width: 20px;margin-right: auto;margin-left: auto;">
      <table class="table table-responsive text-center" style="display: table" *ngIf="usersChallenge?.length > 0">
        <tbody>
          <tr *ngFor="let user of usersChallenge">
            <td style="align-content: center; width: 20%;"><img class="AvatarImg" src="{{user.imageFullPath}}"></td>

            <td style="vertical-align: middle; width: 20%;">
              <p style="font-weight: bold;">{{user.nickName}}</p>
              <p style="margin-top: -8px; color: grey;">{{user.region}}</p>
              <p style="margin-top: -10px; color: grey">{{user.channel}}</p>
            </td> 
            <td style="width: 30%;"></td>

            <td style="vertical-align: middle; width: 30%;">
              <xiaomi-button (click)="invite(user.id)">
                {{'Buttons_invite' | translate}}
              </xiaomi-button>
            </td>

          </tr>
        </tbody>
      </table>
      <p *ngIf="usersChallenge?.length < 1" class="text-center" style="color: var(--main-text-color); font-size: 18px; font-weight: bold;">{{'challenges_therearenouser' | translate}}</p>
    </div>
  </div>

  <!-- LIST SEARCH BAR -->
  <div class="mb-3" style="margin: auto" *ngIf = "filterSelected == 3">
    <div class="contentChannels mx-auto" style="max-width: 350px;">
      <!--<img src="../../../assets/images/down.png" class="mt-2 mb-2 center" style="width: 20px;margin-right: auto;margin-left: 90%;">-->
      <div class="inputContainer search-desktop d-inline-block">
        <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
        <input class="Field" [(ngModel)]="keywordSearch" (keyup.enter)="searchContent(keywordSearch)" type="text" placeholder="Search"/>
      </div>
    </div>

    <div *ngIf = "counterUserSearched" style="max-width: 550px;margin: auto">
      <div class="contentChannels mx-auto">
        <!--<img src="../../../assets/images/down.png" class="mt-2 mb-2 center" style="width: 20px;margin-right: auto;margin-left: auto;">-->
        <table class="table table-responsive text-center" style="display: table" *ngIf="usersChallenge?.length > 0">
          <tbody>
            <tr *ngFor="let user of usersChallenge">
              <td style="align-content: center; width: 20%;"><img class="AvatarImg" src="{{user.imageFullPath}}"></td>
  
              <td style="vertical-align: middle; width: 20%;">
                <p style="font-weight: bold;">{{user.nickName}}</p>
                <p style="margin-top: -8px; color: grey;">{{user.region}}</p>
                <p style="margin-top: -10px; color: grey">{{user.channel}}</p>
              </td> 
              <td style="width: 30%;"></td>
  
              <td style="vertical-align: middle; width: 30%;">
                <xiaomi-button (click)="invite(user.id)">
                  {{'Buttons_invite' | translate}}
                </xiaomi-button>
              </td>
  
            </tr>
          </tbody>
        </table>
        <p *ngIf="usersChallenge?.length < 1" class="text-center" style="color: var(--main-text-color); font-size: 18px; font-weight: bold;">{{'challenges_therearenouser' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="overlay" *ngIf="inviteSent === '1'" style="z-index: 99; color: gray;">
    <div class="externalDiv">

      <div class="popup" style="background-color: #F5F5F5; max-width: 90%; min-height: 250px; vertical-align: middle;">
        <!-- <a class="close" (click)="inviteSent = '2'">&times;</a> -->
        <div class="row">
          <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="inviteSent = '2'" class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
        </div> 
        <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 15px;">{{'challenges_invitesent' | translate}} <br> {{'challenges_senttoopponent' | translate}}</p>
        <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 20px;">{{'challenges_jumpin' | translate}}</p>
        <!--<mat-grid-tile style="overflow: initial; position: relative; margin-top: 30px;">
          <button class="btn btn-outline shadow-none" style="background-color: #F5F5F5;" [routerLink]="['/mainpage/challenges']" [queryParams]="{section: 1}">
            <svg-icon [svgStyle]="{ 'width.px': '18'}"></svg-icon>
            {{'Buttons_start' | translate}}
          </button>
        </mat-grid-tile>-->
      </div>

    </div>
  </div>

  <div class="overlay" *ngIf="inviteSent === '0'" style="z-index: 99; color: gray;">
    <div class="externalDiv">

      <div class="popup" style="background-color: #F5F5F5; max-width: 90%; min-height: 270px; vertical-align: middle;">
        <!-- <a class="close" (click)="inviteSent = '2'">&times;</a> -->
        <div class="row">
          <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="inviteSent = '2'" class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
        </div> 
        <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 15px;">Error</p>
      </div>

    </div>
  </div>
</div>