<div style="max-width: 425px;" class="p-3">
  <!-- <svg-icon [name]="'logo'" class="center" [svgStyle]="{ 'width.px': '300'}"></svg-icon> -->
  <div class="center">
    <img src="../../../assets/images/logo_grande.png"  style="width: 300px;"/>
  </div>
  <ng-template [ngIf]="currentPage===1">
  <div class="p-5">
    <h1>{{'wizard_howitworks' | translate}}</h1>
    <p class="mt-5">{{'wizard_letsspendafewti' | translate}}</p>
    <xiaomi-button class="center mt-5" (click)="nextButton()">
      {{'Buttons_next' | translate}}
    </xiaomi-button>
    <xiaomi-button class="center mt-3" (click)="skipButton()">
      {{'Buttons_skip' | translate}}
    </xiaomi-button>
  </div>
</ng-template>
<ng-template [ngIf]="currentPage===2">
  <div class="p-5">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <p>{{'home_courses' | translate}}</p>
        <svg-icon [name]="'wizard_1'" [svgStyle]="{ 'width.px': '70' }" class="center p-3"></svg-icon>
        <p>{{'wizard_coursessearcham' | translate}}</p>
      </ng-template>  
      <ng-template carouselSlide>
        <p>{{'wizard_scan' | translate}}</p>
        <svg-icon [name]="'wizard_2'" [svgStyle]="{ 'width.px': '70' }" class="center p-3"></svg-icon>
        <p>{{'wizard_scanusethescanf' | translate}}</p>
      </ng-template>  
      <ng-template carouselSlide>
        <p>{{'wizard_point' | translate}}</p>
        <svg-icon [name]="'wizard_3'" [svgStyle]="{ 'width.px': '70' }" class="center p-3"></svg-icon>
        <p>{{'wizard_Points&rewardsc' | translate}}</p>
      </ng-template>  
      <ng-template carouselSlide>
        <p>{{'menu_challenges' | translate}}</p>
        <svg-icon [name]="'wizard_4'" [svgStyle]="{ 'width.px': '70' }" class="center p-3"></svg-icon>
        <p>{{'wizard_challengeschall' | translate}}</p>
      </ng-template>  
      <ng-template carouselSlide>
        <p>{{'menu_forum' | translate}}</p>
        <svg-icon [name]="'wizard_5'" [svgStyle]="{ 'width.px': '70' }" class="center p-3"></svg-icon>
        <p>{{'wizard_forumSearchfort' | translate}}</p>
      </ng-template>  
    </owl-carousel-o>
    <xiaomi-button class="center mt-5" (click)="skipButton()">
      {{'Buttons_skip' | translate}}
    </xiaomi-button>
  </div>
</ng-template>
</div>