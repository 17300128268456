<div class="mainpageContainer">
<!-- sidenavbar -->
<ng-container>
  <!-- top navigation -->
  <mat-toolbar class="example-header bg-white">
    <button mat-icon-button aria-label="Toggle sidenav" 
            (click)="sidenav.toggle();"
            *ngIf="mobile == true" class="float-left">
      <mat-icon style="line-height: 19px;" aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <img src="../../../assets/images/logo_grande.png" *ngIf="mobile == false" style="width: 160px; float: left;"/>
    <ul class="float-left mb-0">
      <li [ngClass]="{'active': active_tab==='homepage'}">
        <a [routerLink]="[{ outlets: { primary: ['homepage'], sidebar: ['news'] } }]"
           class="text-decoration-none" (click)="this.dropdownVisible = false;">
          <svg-icon [name]="'1_home'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
          <div *ngIf="desktop" class="text-uppercase">{{'menu_home' | translate}}</div>
        </a>
      </li>
      <li [ngClass]="{'active': active_tab==='news'}">
        <a mat-list-item [routerLink]="[{ outlets: { primary: ['news'], sidebar: ['foryou'] } }]"
           class="text-decoration-none" (click)="this.dropdownVisible = false;">
          <svg-icon [name]="'5_news'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
          <div *ngIf="desktop" class="text-uppercase">{{'menu_news' | translate}}</div>
        </a>
      </li>
      <li [ngClass]="{'active': active_tab==='bookmarks'}">
        <a mat-list-item [routerLink]="[{ outlets: { primary: ['bookmarks'], sidebar: ['news'] } }]"
           class="text-decoration-none" (click)="this.dropdownVisible = false;">
          <svg-icon [name]="'4_bookmarks'"[svgStyle]="{ 'width.px': '19'}"></svg-icon>
          <div *ngIf="desktop" class="text-uppercase">{{'home_bookmarks' | translate}}</div>
        </a>
      </li>
      <li *ngIf="desktop">
        <a mat-list-item (click)="active_tab ='share'; PopUpShare(); dropdownVisible = false;" class="text-decoration-none">
          <svg-icon [name]="'share'"[svgStyle]="{ 'width.px': '19'}"></svg-icon>
          <div class="text-uppercase">SHARE</div>
        </a>
      </li>
      <!-- search mobile -->
      <li [ngClass]="{'active': active_tab==='keywordinput'}" *ngIf="mobile">
        <a mat-list-item [routerLink]="[{ outlets: { primary: ['keywordinput'], sidebar: ['news'] } }]"
           (click)="this.dropdownVisible = false;">
          <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
        </a>
      </li>
    </ul>
    <span class="example-spacer"></span>
    <div>
      <!-- search   -->
      <div class="inputContainer search-desktop d-inline-block" *ngIf="desktop">
        <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
        <input class="Field" [(ngModel)]="keywordSearch" (keyup.enter)="searchContent(keywordSearch)" type="text" placeholder="Search"/>
        <button mat-button *ngIf="keywordSearch" mat-icon-button aria-label="Clear" (click)="keywordSearch=''; dropdownVisible = false">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <!-- no notifications -->
      <a *ngIf="notifications_counter < 1" (click)="notificationDropDown()"
          class="text-decoration-none no_not" [ngClass]="{'active': active_tab==='notificationsarchive'}">
        <svg-icon [name]="'notifications'" [svgStyle]="{ 'width.px': '22'}"></svg-icon>
      </a>
      <!-- notifications greater than 0 -->
      <a *ngIf="notifications_counter > 0" class="text-decoration-none notification-icon" (click)="notificationDropDown()"
         [ngClass]="{'active': active_tab==='notificationsarchive'}">
        <svg-icon [name]="'notifications'" matBadge="{{notifications_counter}}" matBadgeColor="primary" matBadgeSize="small"
                  [svgStyle]="{ 'width.px': '17'}"></svg-icon>
      </a>
      
      <!-- user profile link -->
      <a [routerLink]="[{ outlets: { primary: ['userprofile'], sidebar: ['news'] } }]"
         [ngClass]="{'active': active_tab==='userprofile'}"
         class="text-decoration-none userprofile-icon" (click)="this.dropdownVisible = false;">
        <svg-icon [name]="'5_profile'"[svgStyle]="{ 'width.px': '35'}"></svg-icon>
      </a>
    </div>

  </mat-toolbar>

  <mat-sidenav-container class="example-container">
    <!-- left navigation (desktop) -->
    <mat-sidenav #sidenav [mode]="mobile == true ? 'over' : 'side'" class="example-sidenav"
                 [opened]="mobile == false"
                 fixedInViewport="false" fixedTopGap="0"
                 fixedBottomGap="0">
        <mat-nav-list (click)="this.dropdownVisible = false;">

          <a mat-list-item [ngClass]="{'active': active_tab==='homepage'}" [routerLink]="[{ outlets: { primary: ['homepage'], sidebar: ['news'] } }]">
            <svg-icon [name]="'1_home'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'menu_home' | translate}}</div>
          </a>

          <a mat-list-item [ngClass]="{'active': active_tab==='leaderboard'}" [routerLink]="[{ outlets: { primary: ['leaderboard'],sidebar: ['news'] } }]">
            <svg-icon [name]="'3_leaderboard'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'menu_coursesleaderbo' | translate}}</div>
          </a>

          <a mat-list-item [ngClass]="{'active': active_tab==='rewards'}" [routerLink]="[{ outlets: { primary: ['rewardscomingsoon'],sidebar: ['news'] } }]">
            <svg-icon [name]="'4_reward'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'profile_rewards' | translate}}</div>
          </a>

          <a mat-list-item [ngClass]="{'active': active_tab==='news'}" [routerLink]="[{ outlets: { primary: ['news'],sidebar: ['foryou'] } }]">
            <svg-icon [name]="'5_news'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'menu_news' | translate}}</div>
          </a>

          <a mat-list-item [routerLink]="[{ outlets: { primary: ['challenges'],sidebar: ['news'] } }]">
            <svg-icon [name]="'7_challenge'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'menu_challenges' | translate}}</div>
          </a>

          <a mat-list-item [ngClass]="{'active': active_tab==='surveys'}" [routerLink]="[{ outlets: { primary: ['surveys'],sidebar: ['news'] } }]">
            <svg-icon [name]="'8_surveys'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
            <div class="inline-block mt-1 ml-3">{{'menu_surveys' | translate}}</div>
          </a>

          <!--<div class="position-absolute text-uppercase w-100">-->
            <a mat-list-item [ngClass]="{'active': active_tab==='support'}" [routerLink]="[{ outlets: { primary: ['support'],sidebar: ['news'] } }]">
              <svg-icon [name]="'10_contatti'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
              <div class="inline-block ml-3">{{'menu_support' | translate}}</div>
            </a>
            <a mat-list-item (click)="logOutUser()" class="logout" style="background-color: white;border-top:1px solid lightgray">
              <svg-icon class = "logout" [name]="'log_out'"[svgStyle]="{ 'width.px': '30'}"></svg-icon>
              <div class="inline-block ml-3" style="color: var(--main-text-color);">{{'menu_logout' | translate}}</div>
            </a>
            <a mat-list-item [ngClass]="{'active': active_tab==='howitworks'}" [routerLink]="[{ outlets: { primary: ['howitworks'],sidebar: ['news'] } }]">
              <div style="text-transform: none; font-size: 15px;" class="inline-block ml-3"><i>{{'menu_howitworks' | translate}}</i></div>
            </a>
            <a mat-list-item [ngClass]="{'active': active_tab==='termscondition'}" [routerLink]="[{ outlets: { primary: ['termscondition'],sidebar: ['news'] } }]">
              <div style="text-transform: none; font-size: 15px;" class="inline-block ml-3"><i>{{'menu_termsofuse' | translate}}</i></div>
            </a>
            <a mat-list-item [ngClass]="{'active': active_tab==='privacypolicy'}" [routerLink]="[{ outlets: { primary: ['privacypolicy'],sidebar: ['news'] } }]">
              <div style="text-transform: none; font-size: 15px;" class="inline-block ml-3"><i>{{'menu_privacy' | translate}}</i></div>
            </a>
            <!-- play store link -->
            <div mat-list-item>
              <a href="https://play.google.com/store/apps/details?id=com.companyname.miacademy" target="_blank">
                <img src="https://pblalliance.com/wp-content/uploads/revslider/pca-rev1/playstore-button.png"
                     class="ml-4 mt-3 w-50" alt="">
              </a>
            </div>
            <xiaomi-imprint class="home_imprint"></xiaomi-imprint>
          <!--</div>-->
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content (click)="this.dropdownVisible = false;">
      <router-outlet></router-outlet>
    </mat-sidenav-content>

    <!-- right container (desktop only) -->
    <mat-sidenav mode="side" position="end" [opened]="desktop" class="right-sidenav pt-3 pr-0 pl-0" (click)="this.dropdownVisible = false;">
      <router-outlet name="sidebar"></router-outlet>
    </mat-sidenav>

  </mat-sidenav-container>
<!--
  <mat-toolbar class="example-footer" color='primary'>Footer</mat-toolbar> -->
</ng-container>

<div id="fb-root"></div>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v10.0" nonce="o7ORvSQW"></script>

<div class="overlay" *ngIf="popUpShare === '1'" style="z-index: 99; color: gray;">
  <div class="externalDiv">

    <div class="popup" style="background-color: #F5F5F5; max-width: 90%; min-height: 270px; vertical-align: middle;">

      <!-- <a class="close" (click)="popUpShare = '2'">&times;</a> -->
      <div class="row">
        <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="popUpShare = '2'" class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
      </div>

        <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 5px; margin-bottom: 30px;">SHARE</p>

        <mat-grid-list cols="3" rowHeight="1:1">
          <mat-grid-tile>
            <div class="fb-share-button" data-href="https://xiaomitraining.com/XiaomiWeb/" data-layout="button" data-size="large">
              <a href="" target="popup" onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fxiaomitraining.com%2FXiaomiWeb%2F&amp;src=sdkpreparse','popup','width=600,height=600'); return false;" class="fb-xfbml-parse-ignore">
                <img src="../../../assets/images/facebook_logo.png"  alt="" style="display: block; margin-left: auto; margin-right: auto; width: 65%;">
              </a>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <a onclick="window.open('https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw&amp;url=https://xiaomitraining.com/XiaomiWeb/','popup','width=600,height=600'); return false;">
              <i></i>
              <span class="label" id="l">
                <img src="../../../assets/images/twitter_logo.png"  alt="" style="display: block; margin-left: auto; margin-right: auto; width: 50%;">
              </span>
            </a>
          </mat-grid-tile>
          <mat-grid-tile>
            <a href="" target="popup" onclick="window.open('mailto:?body=Go%20To%20XiaomiTraining:%20https://xiaomitraining.com/XiaomiWeb/'); return false;">
              <i></i>
              <span class="label" id="l">
                <img src="../../../assets/images/mail_logo.png"  alt="" style="display: block; margin-left: auto; margin-right: auto; width: 50%;">
              </span>
            </a>
          </mat-grid-tile>
        </mat-grid-list>

    </div>

  </div>
</div>

<div class="dropdown" style="z-index:99;" tabindex="0"
     *ngIf="dropdownVisible">
  <div *ngIf="notifications_list?.length > 0">
    <!--TODAY-->
    <div *ngIf="today?.length > 0">
      <div class="col-md-11 mx-auto mt-3">
        <p class="day col-sm-12 p-0">{{'notification_today' | translate}}</p>
      </div>
      <div class="col-md-11 mx-auto mt-3" *ngFor="let notify of today; let i = index"
        [id]="notify.idNotifica">
        <div *ngIf="i<2">
          <span class="title" [ngClass]="{'boldtext': !notify.seen}">{{notify.title}}</span>
          <p class="text" [ngClass]="{'boldtext': !notify.seen}">
            {{notify.dateText}}
          </p>
          <span class="date">{{notify.dateSend}}</span>
          <div style="float: right;cursor: pointer;" (click)="GoToDetail(notify.idNotifica); dropdownVisible = false">
            <span class="discovertext" *ngIf="notify.pageId != 0"> {{'notification_discover' | translate}}</span>
          </div>
          <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
        </div>
      </div>
    </div>
  
    <!--WEEK-->
    <div *ngIf="week?.length > 0">
      <div class="col-md-11 mx-auto mt-3">
        <p class="day col-sm-12 p-0">{{'notification_thisweek' | translate}}</p>
      </div>
      <div class="col-md-11 mx-auto mt-3" *ngFor="let notify of week"
        [id]="notify.idNotifica">
        <span class="title" [ngClass]="{'boldtext': !notify.seen}">{{notify.title}}</span>
        <p class="text" [ngClass]="{'boldtext': !notify.seen}">
          {{notify.dateText}}
        </p>
        <span class="date">{{notify.dateSend}}</span>
        <div style="float: right;cursor: pointer;" (click)="GoToDetail(notify.idNotifica); dropdownVisible = false">
          <span class="discovertext" *ngIf="notify.pageId != 0"> {{'notification_discover' | translate}}</span>
        </div>
        <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
      </div>
    </div>
  
    <!--MONTH-->
    <div *ngIf="month?.length > 0">
      <div class="col-md-11 mx-auto mt-3">
        <p class="day col-sm-12 p-0">{{'notification_thismonth' | translate}}</p>
      </div>
      <div class="col-md-11 mx-auto mt-3" *ngFor="let notify of month"
        [id]="notify.idNotifica">
        <span class="title" [ngClass]="{'boldtext': !notify.seen}">{{notify.title}}</span>
        <p class="text" [ngClass]="{'boldtext': !notify.seen}">
          {{notify.dateText}}</p>
        <span class="text">{{notify.dateSend}}</span>
        <div style="float: right;cursor: pointer;" (click)="GoToDetail(notify.idNotifica); dropdownVisible = false">
          <span class="discovertext" *ngIf="notify.pageId != 0"> {{'notification_discover' | translate}}</span>
        </div>
        <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
      </div>
    </div>
  
    <!--YEAR-->
    <div *ngIf="year?.length > 0">
      <div class="col-md-11 mx-auto mt-3">
        <p class="day col-sm-12 p-0">{{'notification_thisyear' | translate}}</p>
      </div>
      <div class="col-md-11 mx-auto mt-3" *ngFor="let notify of year"
        [id]="notify.idNotifica">
        <span class="title" [ngClass]="{'boldtext': !notify.seen}">{{notify.title}}</span>
        <p class="text" [ngClass]="{'boldtext': !notify.seen}">
          {{notify.dateText}}
        </p>
        <span class="date">{{notify.dateSend}}</span>
        <div style="float: right;cursor: pointer;" (click)="GoToDetail(notify.idNotifica); dropdownVisible = false">
          <span class="discovertext" *ngIf="notify.pageId != 0"> {{'notification_discover' | translate}}</span>
        </div>
        <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
      </div>
    </div>
  
    <!--OTHER-->
    <div *ngIf="other?.length > 0">
      <div class="col-md-11 mx-auto mt-3">
        <p class="day col-sm-12 p-0">{{'notification_other' | translate}}</p>
      </div>
      <div class="col-md-11 mx-auto mt-3" *ngFor="let notify of other"
        [id]="notify.idNotifica">
        <span class="title" [ngClass]="{'boldtext': !notify.seen}">{{notify.title}}</span>
        <p class="text" [ngClass]="{'boldtext': !notify.seen}">
          {{notify.dateText}}
        </p>
        <span class="date">{{notify.dateSend}}</span>
        <div style="float: right;cursor: pointer;" (click)="GoToDetail(notify.idNotifica); dropdownVisible = false">
          <span class="discovertext" *ngIf="notify.pageId != 0"> {{'notification_discover' | translate}}</span>
        </div>
        <div class="mt-3" style="height: 1px;background-color: #d4d4d4;"></div>
      </div>
    </div>
    <xiaomi-button  class="center mt-4 mb-4" (click)="dropdownVisible = false" [routerLink]="[{ outlets: { primary: ['notificationsarchive'], sidebar: ['news'] } }]">
      {{'Buttons_seeall' | translate}}
    </xiaomi-button>
  </div>
  <h2 *ngIf="notifications_list?.length < 1" class="text-center ml-5 mr-5 mt-4" style="color: var(--main-text-color);">{{'notification_nonenotific' | translate}}</h2>
</div>
</div>