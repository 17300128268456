<!--BACK BUTTON-->
<div style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="container-fluid mx-auto p-0" style="max-width: 760px;">

  <!--Legal terms-->
  <div class="row ml-0 mr-0 pl-0 pr-0">
    <div class="col-sm-12" style="padding-top: 10px; padding-left: 40px;">
      {{'rewards_legalt' | translate}}
    </div>
  </div>
  <!--Title & points-->
  <div class="row ml-0 mr-0 pl-0 pr-0">
    <table class="table table-responsive text-center table-borderless" style="display: table;">
      <tbody>
        <tr>
          <td style="vertical-align: middle; text-align: start;">
            <h1 class="col-sm-12" style="padding-top: 10px;">{{'profile_rewards' | translate}}</h1>
          </td>
          <td style="vertical-align: middle; text-align: start;">
            <svg-icon [name]="'points_blu'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
            <div class="white-text"> {{'rewards_mypoints' | translate}} <p style="font-weight: bold;"> {{UserPoint}}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--Tab button-->
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="OnPillTabsChange($event)" animationDuration="0ms"
    >
    <mat-tab [label]="'rewards_all' | translate | uppercase">
      <ng-template matTabContent>
        <!--Rewards Disponibili-->
        <table *ngIf="counterCatalogo" class="table table-responsive text-center table-borderless" style="display: table;">
          <tbody>
            <tr *ngFor="let premio of catalogo">
              <td style="align-content: center;"><img class="AvatarImg" src="{{premio.imageFullPath}}"></td>

              <td style="vertical-align: middle;">
                <p style="color: #FF6900;font-weight: bold;">{{premio.title}}</p>
                <p >{{premio.body}}</p>
              </td>

              <td style="vertical-align: middle;">
                <svg-icon [name]="'points_blu'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
                  {{'CoursePage_points' | translate}}<br>
                  <p style="font-weight: bold;">{{premio.point}}</p>
                  <button *ngIf="UserPoint>premio.point" class="btn btn-outline-black" (click)="takeRewards(premio.id)"
                  [hidden]="premio.isTake">
                  {{'Buttons_take' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 *ngIf="counterCatalogo == false"  class="text-center" style="color: var(--main-text-color);">{{'home_sorry' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'rewards_collected' | translate | uppercase">
      <ng-template matTabContent>
        <!--Rewards Collected-->
        <table *ngIf="counterCollected" class="table table-responsive text-center table-borderless" style="display: table;">
          <tbody>
            <tr *ngFor="let premio of collected">
              <td style="align-content: center;"><img class="AvatarImg" src="{{premio.imageFullPath}}"></td>

              <td style="vertical-align: middle;">
                <p style="color: #FF6900;font-weight: bold;">{{premio.title}}</p>
                <p >{{premio.body}}</p>
              </td>

              <td style="vertical-align: middle;">
                <svg-icon [name]="'points_blu'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
                  {{'CoursePage_points' | translate}}<br>
                  <p style="font-weight: bold;">{{premio.point}}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 *ngIf="counterCollected == false"  class="text-center" style="color: var(--main-text-color);">{{'home_sorry' | translate}}</h1>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</div>