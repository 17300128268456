<div class="homepageContainer">
<!-- sliders -->
<!-- (initialized)="getPassedData($event)" -->
<!-- [owlRouterLink]="['/mainpage',{ outlets: { primary: ['news'], sidebar: ['foryou'] } }]" -->
<owl-carousel-o [options]="customOptions" (dragging)="isDragging = $event.dragging">
  <ng-container *ngFor="let slider of sliders">
    <ng-template carouselSlide>
      <div class="slider"> 
        <img src="{{slider.sliderImgPath}}" [alt]="slider.id" [stopLink]="isDragging"
        [owlRouterLink]="['/mainpage',{ outlets: { primary: ['redirectSliderPage', slider.externalLinkActive, slider.externalLink, slider.linkType, slider.link]} }]"
        class="item responsive-slider-img"/>
      </div>
    </ng-template>  
  </ng-container>
</owl-carousel-o>

<!-- user overview -->
<div class="row ml-0 mr-0 p-0" [ngClass]="{'multiplesliders': sliders?.length > 1}">
  <div class="col-12 col-lg-7 mx-auto pt-2 pb-2 pl-0 pr-3" style="background-color: white">
    <mat-grid-list cols="4" rowHeight="36px" class="bg-dark">
      <mat-grid-tile class="overview">
        <div>
          <div>{{'home_courses' | translate}}</div>
          <div>{{leaderboard?.corsi}}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="overview">
        <div>
          <div>{{'home_completed' | translate}}</div>
          <div>{{leaderboard?.completati}}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="overview">
        <div>
          <div>{{'home_points' | translate}}</div>
          <div>{{leaderboard?.point}}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="overview">
        <div>
          <div>{{'home_leaderboard' | translate}}</div>
          <div>{{leaderboard?.position}} {{'rewards_of' | translate}} {{leaderboard?.userCount}}</div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<!-- filter container -->
<div class="list row ml-1 mr-1" id="filter_container">
    <!-- category contariner -->
    <div class="col-12 mt-3 col-12 pl-3 pl-lg-4 mt-3 position-relative">
      <h2 class="text-uppercase filterLabel pl-1 mb-0">{{'home_categories' | translate}}</h2>
      <mat-icon class="position-absolute left d-lg-none">chevron_left</mat-icon>
      <mat-button-toggle-group class="pt-2 pb-2" (change)="toggleChangeCategory($event)" multiple>
            <mat-button-toggle disableRipple *ngFor="let category of categories"
              color="accent" [value]="category.id" class="position-relative catbutton">
              <img src="{{category.imageFullPath}}" class="mt-2" style="width: 40px; height: 40px; position: absolute; left: 30%;">
              <div class="cat-text">{{category.text}} </div>
              <div class="cat-counter">{{category.coursecounter}}</div>
            </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-icon class="position-absolute right d-lg-none">chevron_right</mat-icon>
     
      <!-- <ul class="list-group">
        <li
          class="list-group-item"
          *ngFor="let category of categories; let i = index"
        >
        {{category.text}}  
        </li>
      </ul> -->
    </div>
    <!-- family container -->
    <div class="col-12 pl-3 mt-0" *ngIf="selectedCategory != -1 && filtered_families?.length > 0">
      <h2 class="text-uppercase filterLabel pl-2 mb-0">{{'home_families' | translate}}</h2> 
      <mat-grid-list [gridCols]="{xs: 3, sm: 3, md: 4, lg: 5, xl: 6}"  class="ml-2" rowHeight="50px">
        <mat-grid-tile *ngFor="let family of filtered_families">
          <mat-button-toggle #fams [value]="family.id" name="fam_filter" (change)="onFamilyChange($event)" [id]="'fam_filter-' + family.id">
            <span class="family-text">{{family.text}}</span>
          </mat-button-toggle>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <!-- subfamily container -->
    <div class="col-12 pl-3 mt-0" *ngIf="selectedFamily != -1 && filtered_subfamilies?.length > 0">
      <h2 class="text-uppercase filterLabel pl-2 mb-0">{{'home_products' | translate}}</h2> 
      <mat-grid-list [gridCols]="{xs: 3, sm: 3, md: 4, lg: 5, xl: 6}"  class="ml-2" rowHeight="50px">
        <mat-grid-tile *ngFor="let subfamily of filtered_subfamilies">
          <mat-button-toggle #subfams [value]="subfamily.id" name="subfam_filter" (change)="onSubFamilyChange($event)" [id]="'subfam_filter-' + subfamily.id">
            <span class="family-text">{{subfamily.text}}</span>
          </mat-button-toggle>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
</div>

<!-- pill tabs -->
<mat-tab-group mat-align-tabs="center" (selectedTabChange)="OnPillTabsChange($event)"
 animationDuration="0ms" class="text-uppercase">
  <mat-tab [label]="'home_foryou' | translate">
    <!-- <ng-template matTabContent>
      Content 2 - Loaded: {{getTimeLoaded(2) | date:'medium'}}
    </ng-template> -->
  </mat-tab>
  <mat-tab [label]="'home_popular' | translate">
    <!-- <ng-template matTabContent>
      Content 1 - Loaded: {{getTimeLoaded(1) | date:'medium'}}
    </ng-template> -->
  </mat-tab>
  <mat-tab [label]="'home_ongoing' | translate">
    <!-- <ng-template matTabContent>
      Content 3 - Loaded: {{getTimeLoaded(3) | date:'medium'}}
    </ng-template> -->
  </mat-tab>
</mat-tab-group>

<!-- pills list -->
<mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" 
               rowHeight="285px"
               *ngIf="filtered_pills?.length > 0">
  <mat-grid-tile *ngFor="let pill of filtered_pills">
    <mat-card class="example-card">
      <mat-card-content class="position-relative">
        <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
        <span class="pill-title" [ngStyle]="{'color': pill?.titleColor}">{{pill.title}}</span>
        <svg-icon [name]="pill.favourite ? '4_bookmarks_orange_filled' : '4_bookmarks_orange'" [svgStyle]="{ 'width.px': '30' }" class="pill-heart-icon icon-white"></svg-icon>
        <mat-grid-list cols="3">
          <mat-grid-tile colspan="2">
            <div class="text-left w-100">
              <p class="mb-0">{{pill.subFamilyName}}</p>
              <div class="line-clamp"> {{pill.abstractBox}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile style="overflow: initial;">
             <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.isCompleted === true && pill?.iD_Type == 0"
                     [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', pill.id], sidebar: ['related', pill.id] } }]">

                     <svg-icon *ngIf="pill?.isCompleted === true && pill?.iD_Type == 0 " [name]="'repeat_arrow'"
                     [svgStyle]="{ 'width.px': '18'}"></svg-icon>
                     {{pill.buttonText}}
            </button>
          </mat-grid-tile>
        </mat-grid-list >
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
<div *ngIf="!isOngoing">
  <h1 *ngIf="filtered_pills?.length == 0" class="text-center mt-2" style="color:var(--main-text-color);">{{'home_sorry' | translate}}</h1>
</div>
<div *ngIf="isOngoing">
  <h1 *ngIf="filtered_pills?.length == 0" class="text-center mt-2" style="color:var(--main-text-color);">{{'CoursePage_noongoingcourse' | translate}}</h1>
</div>


<!-- popup complete course -->
<div class="overlay" *ngIf="sorry" style="z-index: 99; color: gray;">
  <div class="externalDiv">
    <div class="popup_complete_course" style="background-color: #F5F5F5; max-width: 90%; min-height: 300px; vertical-align: middle;">
      <!-- <a class="close" (click)="inviteSent = '2'">&times;</a> -->
      <div class="row">
        <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="commonService.changeIdChallengeData(0)"
          class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
      </div>
      <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 15px;">
        <b>{{'challenges_wearesor' | translate}}</b><br> {{'challenges_cannotplay' | translate}}<br>
        {{'challenges_challengeyet' | translate}} </p>
      <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 10px;">
        {{'challenges_youneedcomplete' | translate}}</p>
      <mat-grid-tile style="overflow: initial; position: relative;">
        <button class="btn btn-outline shadow-none" (click)="redirectToCourseToComplete()">
          <svg-icon [svgStyle]="{ 'width.px': '18'}"></svg-icon>
          {{'challenges_gotocourse' | translate}}
        </button>
      </mat-grid-tile>
    </div>
  </div>
</div>

</div>
