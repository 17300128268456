import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NavigationService } from 'src/app/services/Navigation.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.scss']
})
export class HowitworksComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoWidth: true,
    center: false,
    items: 1,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    nav: true,
    navSpeed: 700,
    navText: ['◄', '►'],
  
  };
  
  constructor(
    private router: Router,
    private navigation: NavigationService,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
  }

  currentPage: number = 1;

  skipButton(){
    this.navigation.back();
  }

  nextButton(){
    this.currentPage = 2
  }

}
