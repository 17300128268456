import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  surveys: any;

  popup = false;
  urlSafe: SafeResourceUrl | undefined;

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    public sanitizer: DomSanitizer,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    this.getSurveys();
  }

  getSurveys(){
    this.categoryService.getSurveysAuth( this.userEmail, this.pass)
      .subscribe(
        data => {
          this.surveys = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  GoToLink(url : string){
    //this.popup = true;
    console.log(url);
    //this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(url);
    var win = window.open(url, '_blank');
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert('Please allow popups for this website');
      }
  }

}
