import { CommonService } from '../../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-notificationsarchive',
  templateUrl: './notificationsarchive.component.html',
  styleUrls: ['./notificationsarchive.component.css']
})
export class NotificationsarchiveComponent implements OnInit {

  userEmail = localStorage.getItem('user')!;
  pass = localStorage.getItem('pass')!;

  notifications: any;
  notcounter: number = 0;
  activationDate = new Date();
  date = new Date(this.activationDate.getUTCFullYear(),
  this.activationDate.getUTCMonth(),
  this.activationDate.getUTCDate(),
  this.activationDate.getUTCHours(),
  this.activationDate.getUTCMinutes(),
  this.activationDate.getUTCSeconds()
  );
  today: any = [];
  week: any = [];
  month: any = [];
  year: any = [];
  other: any = [];
  constructor(
    private router: Router,
    private categoryService: CategoryService,
    public commonService: CommonService,
    public mainpage: MainPageComponent) { }

  ngOnInit() {
    this.getNotifications();
    console.log(this.date);
  }

  getNotifications() {
    this.categoryService.getNotificheUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.notifications = data;
          var notcounter = 0;

          data.forEach((element: any) => {
            //console.log(element.dateSend);
            let app: Date = new Date(element.dateSend.split("-")[0], element.dateSend.split("-")[1] - 1, element.dateSend.split("-")[2].substring(0, 2));
            //console.log(app);
            let dateforminH: Date = new Date
              (element.dateSend.split("-")[0],
              element.dateSend.split("-")[1] - 1,
              element.dateSend.split("-")[2].substring(0, 2),
              element.dateSend.split("-")[2].substring(3,5),
              element.dateSend.split("-")[2].substring(6,8));
            //console.log(dateforminH.valueOf());

            element.dateSend = Math.floor((this.date.valueOf() - app.valueOf()) / 1000 / 60 / 24 / 60);
            //console.log(element.dateSend)
            if(element.dateSend < 1){
              let min = Math.floor((this.date.valueOf() - dateforminH.valueOf()) / 1000 / 60);
              let hour = Math.floor((this.date.valueOf() - dateforminH.valueOf()) / 1000 / 60/ 60);
              //console.log(min);
              if(min < 60){
                element.dateSend = min + "min"
                this.today.push(element);
              }else{
                element.dateSend = hour + "h"
                this.today.push(element);
              }
            }
            else if (element.dateSend <= 7){
              element.dateSend = element.dateSend + "d";
              this.week.push(element);
            }
            else if (element.dateSend <= 30){
              element.dateSend = element.dateSend + "d";
              this.month.push(element);
            }
            else if (element.dateSend <= 365){
              element.dateSend = element.dateSend + "d";
              this.year.push(element);
            }
            else{
              element.dateSend = element.dateSend + "d";
              this.other.push(element);
            }

            if(!element.seen)
              notcounter++
          });

          if (this.notifications != null)
            this.commonService.changeData(notcounter);
          else
            console.warn('notifications list is null');
        },
        error => {
          console.log(error);
        });
  }

  testContatoreNotifiche(id: number) {
    this.notcounter--;
    this.commonService.changeData(this.notcounter);  //invia numero notifiche aggiornato al parent
  }

  deleteSingleNotification(idNot: number) {
    //console.log(idNot);
    this.categoryService.deleteNotificaUserAuth(idNot, this.userEmail, this.pass)
      .subscribe(
        data => {

          this.today = [];
          this.week = [];
          this.month = [];
          this.year = [];
          this.other = [];

          this.getNotifications();
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  setNotificationAsSeen(idNot: number) {
    //console.log(idNot);
    this.categoryService.setNotificaAsSeen(idNot, this.userEmail, this.pass)
      .subscribe(
        data => {
          console.log('notification ' + idNot + ' set as seen');
          this.notcounter--;
          this.commonService.changeData(this.notcounter);
        },
        error => {
          console.log(error);
        });
  }


  deleteAllNotification() {
    this.categoryService.deleteAllNotificheUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.notcounter = 0;
          this.getNotifications();
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  GoToDetail(notifica: any) {
    console.log(notifica.idNotifica);
    let i = 0;
    let found = false;
    while (!found && i < this.notifications.length) {
      if (this.notifications[i].idNotifica == notifica.idNotifica) {
        found = true;
      }
      else {
        i++;
      }
    }
    if(!notifica.seen){
      this.setNotificationAsSeen(notifica.idNotifica);
      notifica.seen = true;
    }
      

    if (this.notifications[i].pageId != 0) {
      this.router.navigateByUrl('/mainpage/redirectPage/' + this.notifications[i].pageId + '/' + this.notifications[i].contentId);
    }
  }
}
