<div class="pillDetailComponent">
<div style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }" [svgClass]="'white'"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="container-fluid mx-auto p-0" style="max-width: 760px;">


  <div class="row ml-0 mr-0 pl-0 pr-0">
    <img class="imgCorrection" src="{{pill?.imageFullPath}}">
  </div>
  <div class="p-4">
    <!-- title and heart icon -->
    <div class="row ml-0 mr-0">
      <div class="col-md-8 col-10 p-0">
        <div class="h3" style="color: var(--main-text-color);">{{pill?.title}}</div>
      </div>
      <div class="col-md-4 col-2" style="text-align: end;">
        <button mat-icon-button matSuffix (click)="addToBookmarks()" [disabled]="user_rate > -1">
          <svg-icon [name]="isLiked ? '4_bookmarks_orange_filled' : '4_bookmarks_orange'"
          [svgClass]="'clickable'" [svgStyle]="{'height.px':'30', 'width.px':'30'}">
          </svg-icon>
        </button>
      </div>
    </div>


    <!-- popup -->
    <div class="col-md-6 mx-auto mt-4" layout="row" layout-align="center center">

      <div class="overlay" *ngIf="popup" style="z-index: 400 !important;">
        <div class="popup">

          <a class="close" (click)="CloseCourse()">&times;</a>
          <!--<div [innerHTML]="Terms"></div>-->
          <iframe [src]="urlSafe"></iframe>
        </div>
      </div>
    </div>
    <!-- progress bar -->
    <div class="course-progress position-relative">
      <span class="position-absolute">{{pill_progress}} / 100</span>
      <div class="position-absolute" [ngClass]="{'w-50': pill_progress == 50, 'w-100': pill_progress == 100}"></div>
      <div style="border-bottom: 2px solid black; height: 25px;"></div>
    </div>
    <br>
    <p class="h6">
      {{pill?.abstractBox}}
    </p>
    <div class="row ml-0 mr-0">
          <div class="col-md-1 col-3 p-0">
            <mat-icon style="transform: scale(1.3);">schedule</mat-icon>
            <div class="d-inline position-relative" style="vertical-align: super;">
              <b style="position: absolute; left: 10px;">{{pill?.duration}}</b>
              <p style="position: absolute; left: 10px; top: 15px;">{{'CoursePage_minminutes' | translate}}</p>
            </div>
          </div>

          <div class="col-md-3 pl-md-4 col-3 p-0">
            <svg-icon [name]="'points'" [svgStyle]="{ 'width.px': '30' }"></svg-icon>
            <div class="d-inline position-relative" style="vertical-align: super;">
              <b style="position: absolute; left: 10px;">{{pill?.pointValue}}</b>
              <p style="position: absolute; left: 10px; top: 15px;">{{'home_points' | translate}}</p>
            </div>
          </div>

          <div class="col-md-8 col-6 p-0 text-right">
            <button class="btn btn-outline shadow-none" style="position: initial; max-width: 200px;" (click)="GoToCourse()"
              [class.button-repeat]="pill?.isCompleted === true && pill?.iD_Type == 0">
              <svg-icon *ngIf="pill?.isCompleted === true && pill?.iD_Type == 0 "
              [name]="'repeat_arrow'" [svgClass]="'xiaomibluegreen'"
              [svgStyle]="{ 'width.px': '18'}"></svg-icon>
              {{pill?.buttonText}}
            </button>
          </div>
    </div>
    <br>
    <mat-divider> </mat-divider>


    <!--Box Rating-->
    <div class="row ml-0 mr-0">
      <div class="col-12 p-0">
        <table style="overflow: hidden;" class="table table-responsive table-borderless">
          <tbody>
            <tr>
              <td class="pl-0 pb-0">
                <div class="text-uppercase h5" style="margin:0">{{'CoursePage_rating' | translate}}</div>
              </td>
            </tr>
            <tr>
              <td class="pl-0 pt-0">
                <table style="display: table">
                  <tbody>
                    <tr>
                      <td class="ratecircle">

                        <svg-icon [name]="pill_rating>0 ? 'rate_circle_full' : 'rate_circle_empty'">
                        </svg-icon>

                      </td>
                      <td class="ratecircle">

                        <svg-icon
                          [name]="pill_rating>1 ? pill_rating >=2 ? 'rate_circle_full' : 'rate_circle_half' : 'rate_circle_empty'">
                        </svg-icon>

                      </td>
                      <td class="ratecircle">

                        <svg-icon
                          [name]="pill_rating>2 ? pill_rating >=3 ? 'rate_circle_full' : 'rate_circle_half' : 'rate_circle_empty'">
                        </svg-icon>

                      </td>
                      <td class="ratecircle">

                        <svg-icon
                          [name]="pill_rating>3 ? pill_rating >=4 ? 'rate_circle_full' : 'rate_circle_half' : 'rate_circle_empty'">
                        </svg-icon>

                      </td>
                      <td class="ratecircle">

                        <svg-icon
                          [name]="pill_rating>4 ? pill_rating >=5 ? 'rate_circle_full' : 'rate_circle_half' : 'rate_circle_empty'">
                        </svg-icon>

                      </td>
                      <td>
                          <div class="vote-class">{{pill_rating}} / 5 </div>
                      </td>
                      <td>

                        <div [hidden]="user_rate == -1" class="myRate">
                          <div class="vote-class">{{user_rate}} / 5 </div>
                          {{'CoursePage_yourrate' | translate}}
                        </div>
                        <button [hidden]="user_rate != -1" class="btn btn-outlineRate" (click)="GoToRate()">
                          {{'CoursePage_leaverate' | translate}}
                        </button>

                      </td>
                    </tr>
                  </tbody>
                </table>
                <div [hidden]="user_rate != -1" *ngIf="myRate" class="mt-4">
                  <div style="color: var(--main-text-color); font-size: 18px;"><b>{{'CoursePage_Itisyourturn' | translate}}</b><br>{{'CoursePage_PleaseRate' | translate}}</div>
                  <table class="table table-responsive table-borderless" style="display: table">
                    <tbody>
                      <tr>
                        <td class="votesmile">
                          <button mat-icon-button matSuffix (click)="Rate(1)" (mouseenter)="mouseEnter(1) "
                            (mouseleave)="mouseLeave(1)" [disabled]="user_rate > -1">
                            <svg-icon [hidden]="Rate1selected" [name]="'rate_1'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                            <svg-icon [hidden]="!Rate1selected" [name]="'rate_1_tap'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                          </button>
                        </td>
                        <td class="votesmile">
                          <button mat-icon-button matSuffix (click)="Rate(2)" (mouseenter)="mouseEnter(2) "
                            (mouseleave)="mouseLeave(2)" [disabled]="user_rate > -1">
                            <svg-icon [hidden]="Rate2selected" [name]="'rate_2'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                            <svg-icon [hidden]="!Rate2selected" [name]="'rate_2_tap'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                          </button>
                        </td>
                        <td class="votesmile">
                          <button mat-icon-button matSuffix (click)="Rate(3)" (mouseenter)="mouseEnter(3) "
                            (mouseleave)="mouseLeave(3)" [disabled]="user_rate > -1">
                            <svg-icon [hidden]="Rate3selected" [name]="'rate_3'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                            <svg-icon [hidden]="!Rate3selected" [name]="'rate_3_tap'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                          </button>
                        </td>
                        <td class="votesmile">
                          <button mat-icon-button matSuffix (click)="Rate(4)" (mouseenter)="mouseEnter(4) "
                            (mouseleave)="mouseLeave(4)" [disabled]="user_rate > -1">
                            <svg-icon [hidden]="Rate4selected" [name]="'rate_4'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                            <svg-icon [hidden]="!Rate4selected" [name]="'rate_4_tap'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                          </button>
                        </td>
                        <td class="votesmile">
                          <button mat-icon-button matSuffix (click)="Rate(5)" (mouseenter)="mouseEnter(5) "
                            (mouseleave)="mouseLeave(5)" [disabled]="user_rate > -1">
                            <svg-icon [hidden]="Rate5selected" [name]="'rate_5'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                            <svg-icon [hidden]="!Rate5selected" [name]="'rate_5_tap'" [svgStyle]="{ 'width.px': '25' }">
                            </svg-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        
      </div>
    </div>
    <mat-divider class="mb-4"> </mat-divider>
    <!-- Box Inserisci commento--->
    <div class="row ml-0 mr-0">
      <div class="text-uppercase h5">{{'CoursePage_comments' | translate}}</div>
      <div class="col-12 p-0">
        <mat-form-field class="example-full-width">
          <textarea #newComment maxlength="217" matInput placeholder="{{'CoursePage_leaveyourcommen' | translate}}"></textarea>
        </mat-form-field>
      </div>

    </div>
    <div class="row ml-0 mr-0">
      <div class="col-6 p-0">

      </div>
      <div class="col-6 p-0">
        <button #sendButton class="btn btn-outline shadow-none" style="vertical-align: middle;" [disabled]="clicked"
          (click)="SendComment(newComment.value);newComment.value='';">
          {{'CoursePage_send' | translate}}
        </button>
      </div>
    </div>
    <br><br><br>
    <!-- Contenitore dei commenti-->
    <div class="row ml-0 mr-0">
      <div class="col-12 p-0">
        <mat-grid-list cols="3" rowHeight="100px" *ngFor="let commento of commentiViewed">
          <mat-grid-tile rowspan="{{commento.vote}}">
            <table class="table table-responsive text-center table-borderless" style="display: table">
              <tbody>
                <tr>
                  <td style="align-content: center;"><img class="AvatarImg" src="{{commento.userProPicPath}}"></td>
                </tr>
                <tr>

                  <td>
                    <p class="Avatar-text">{{commento.username}}</p>
                  </td>


                </tr>
              </tbody>
            </table>
          </mat-grid-tile>
          <mat-grid-tile rowspan="{{commento.vote}}" colspan="2">
            <mat-card class="example-card w-100">

              <p>{{commento.dateString}}</p>
              <p class="dashboard-card-content">{{commento.comment | slice:sliceOptions.start:commento.end}}</p>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 pl-0 ml-n2">
                    <button mat-icon-button matSuffix (click)="Like(commento.id)">
                      <svg-icon [name]="commento.isLiked ? 'likes_active' : 'like'" [svgStyle]="{ 'width.px': '20' }">
                      </svg-icon>
                    </button>
                    {{commento.numberLike}}
                  </div>
                  <div class="col-md-6"
                    *ngIf="commento.comment != null && commento.comment.length >= sliceOptions.default && commento.end == sliceOptions.default">
                    <label (click)="onExpandText($event,commento.id)" style="color: #FF6700; font-weight: bold;">
                      {{'CoursePage_readall' | translate}} &#5167;</label>
                  </div>
                  <div class="col-md-6"
                    *ngIf="commento.comment != null && commento.comment.length >= sliceOptions.default && commento.end != sliceOptions.default">
                    <label (click)="onExpandText($event,commento.id)" style="color: #FF6700; font-weight: bold;">
                      {{'forum_readless' | translate}} &#5169;</label>
                  </div>
                </div>
                <p>

                </p>
              </div>

            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>

      </div>
    </div>
    <div class="row ml-0 mr-0">
      <div class="col-12 p-0">
        <button class="btn btn-outline-black shadow-none" (click)="seeAllComment()"
          [hidden]="!(commenti.length>2) || commentiViewed.length!=2">
          {{'Buttons_seeall' | translate}}
        </button>
        <button class="btn btn-outline-black shadow-none" (click)="seeAllComment()"
          [hidden]="!(commenti.length>2) || commentiViewed.length==2">
          {{'Buttons_seeonlytwo' | translate}}
        </button>
      </div>
    </div>

    <br><br><br><br><br><br><br><br>
  </div>
</div>

<!-- popup Badge-->
  <div class="overlayBadge" *ngIf="!popupCertificate && popupBadge" style="z-index: 400 !important;">
    <div class="divBadge" >
    <div class="popupBadge" style="max-width: 300px;">
      <!--<div [innerHTML]="Terms"></div>-->
      <img src="{{urlBadgePopup}}" class="imgBadge" style="width:100%;" (click)="popupBadge = false">
    </div>
  </div>
  </div>

  <div class="overlayBadge" *ngIf="popupCertificate" style="z-index: 400 !important;">
    <div class="divBadge" >
    <div class="popupBadge" style="max-width: 300px; height: 578px; background-color: #74BEE9;">
      <svg-icon style="cursor: pointer; float: right;" class="icon-white mt-4 mr-4" [name]="'esc'" [svgStyle]="{ 'width.px': '40' }" (click)="popupCertificate = false"></svg-icon><br>
      <h1 class="ml-4" style="color: white; margin-top: 50px; font-size: 35px;">{{'certificate_certificate' | translate}}</h1>
      <div>
        <svg-icon class="icon-white center mt-5 ml-4 mr-4" [name]="'certificate_template'" [svgStyle]="{'width': '100%', 'height': 'auto'}"></svg-icon>
        <span class="position-absolute testo center">{{certificate?.title}}</span>
      </div>
      <div class="mt-5 ml-4 mr-4" style="line-height: 1;">
        <span class="center" style="font-weight: bold; color: white; font-size: 30px;">{{'certificate_congrats' | translate}}</span>
        <p class="center" style="text-align: center;color: white; font-size: 29px;">{{'certificate_youhavejustcomp' | translate}}</p>
        <p class="center mt-5" style="text-align: center;color: white; font-size: 18px;">{{'certificate_allthecertifica' | translate}}</p>
      </div>
    </div>
  </div>
  </div>


  <div class="overlayRate" *ngIf="ratesend" style="z-index: 99; color: gray;">
    <div class="externalDiv">
  
      <div class="popupRate" style="background-color: #F5F5F5; max-width: 90%; min-height: 300px; vertical-align: middle;">
        <div class="row">
          <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="ratesend = false"
            class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
        </div>
        <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 15px;">
          <b>{{'CoursePage_Wethank' | translate}}</b><br><br> {{'CoursePage_youropinion' | translate}} </p>
      </div>
  
    </div>
  </div>


</div>

