import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-n-news',
  templateUrl: './n-news.component.html',
  styleUrls: ['./n-news.component.scss']
})
export class NNewsComponent implements OnInit {


  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;
  news_loaded = localStorage.getItem('n_news' || 'empty');

  news!: any;
  baseURL!: string;
  screenHeight!: number;

  constructor(private categoryService: CategoryService) {
    this.baseURL = this.categoryService.baseURLimg;
  }

  ngOnInit() {
    this.screenHeight = window.innerHeight;
    if(this.news_loaded === 'empty')
      this.getnNews();
    else{
      //n_news already loaded, get it from loalStorage
      this.news = JSON.parse(this.news_loaded!!)
    }
  }

  getnNews(){

    var news_to_take = 0;
    const news_height = 133;
    const header = 55;
    const news_label = 75;
    this.screenHeight = this.screenHeight - (header + news_label);

    news_to_take = Math.floor(this.screenHeight / news_height);
    //console.warn('news to take are: ' + news_to_take);

    this.categoryService.getnNews(news_to_take, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.news = data;
          localStorage.setItem('n_news', JSON.stringify(data));
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

}
