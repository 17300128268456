import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.css']
})
export class NewsdetailComponent implements OnInit {
  baseURL = this.categoryService.baseURLimg;
  
  items = ['item1'];

  news_id!: number;

  news: any;
  notice: any;
  i!: number;
  linkAct = false;
  codVideo: any;
  safeVideo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    public sanitizer: DomSanitizer,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    this.news_id = this.route.snapshot.params.id;
  }

  addItem(newItem: any) {
    this.items.push(newItem);
    this.news = this.items[1];
    for( this.i = 0; this.i < this.news.length; this.i++ ){
      if(this.news_id == this.news[this.i].id)
        this.notice = this.news[this.i];
    }
    this.idVideo();
  }

  idVideo(){
    console.log(this.notice.link);
    if(this.notice.link){
      this.linkAct = true;
      this.codVideo = this.notice.link.replace('https://www.youtube.com/watch?v=','https://www.youtube.com/embed/');
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.codVideo);
    }
    else{
      this.linkAct = false;
    }

  }

}
