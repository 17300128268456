import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';

@Component({
  selector: 'app-keyword-input',
  templateUrl: './keyword-input.component.html',
  styleUrls: ['./keyword-input.component.scss']
})
export class KeywordInputComponent implements OnInit {

  keywordSearch: string = '';

  constructor(private router: Router,
    public mainpage: MainPageComponent) { }

  ngOnInit() {
  }


  // (keyup.enter)="searchContent()"

  searchContent(){
    if(!this.keywordSearch.match('^\\s*$'))
      this.router.navigate(['/mainpage', { outlets: { primary: ['search', this.keywordSearch], sidebar: ['news'] }}]);
    else{
      alert('empty string');
      this.keywordSearch = '';
    }
  }

}
