<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="col-md-8 mx-auto mt-3">

  <div class="row" style="margin-left: 0px;">
      <img src="{{imageUrl}}" style="max-width: 60px; max-height: 60px;border-radius: 50%;float:right">
      <span class="p-2" style="font-weight: bold; font-size: 17px;">{{'profile_hi' | translate}} <br> {{user?.name}}</span><br>
      <div (click)="GoToEdit()" class="row" style="margin-left: auto; float: right; margin-right: 10px; cursor: pointer;">
        <svg-icon [name]="'edit_my_data'" [svgStyle]="{ 'width.px': '50' }"></svg-icon>
        <span style="margin-top: 6px; text-decoration: underline; color: #757575; font-size: 15px; ">{{'profile_edit' | translate}} <br> {{'profile_editmydata' | translate}}</span>
    </div>
  </div><br>
  <div class="row ml-0 mr-0 p-0" style="margin-top: -2rem;">
    <div class="col-12 mx-auto mt-5 pt-2 pb-2 pl-0 pr-3" id="info">
      <mat-grid-list cols="4" rowHeight="36px" class="bg-dark">
        <mat-grid-tile class="overview">
          <div>
            <div>{{'home_courses' | translate}}</div>
            <div>{{leaderboard?.corsi}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="overview">
          <div>
            <div>{{'home_completed' | translate}}</div>
            <div>{{leaderboard?.completati}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="overview">
          <div>
            <div>{{'home_points' | translate}}</div>
            <div>{{leaderboard?.point}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="overview">
          <div>
            <div>{{'home_leaderboard' | translate}}</div>
            <div>{{leaderboard?.position}} {{'rewards_of' | translate}} {{leaderboard?.userCount}}</div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>

  <xiaomi-button class="center mt-4" (click)="GoToRewardsPage()">
    {{'profile_rewards' | translate}}
  </xiaomi-button>

  <div  class="mt-4 mb-2" style="height: 1px;background-color: #B9B6B6;"></div>

  <p style="text-transform: uppercase;font-size: 16px;text-transform: uppercase;">{{'home_ongoing' | translate}}</p>

  <div *ngIf="seenone">
    <span class="center" style="font-size: 20px; color: var(--main-text-color);">{{'profile_donothaveongoingcourse' | translate}}</span>
  </div>

  <div *ngIf="seetwo">
    <div *ngFor="let pill of pillsongoing; let i = index">
      <div class="mb-4" *ngIf="i<2" >

        <button class="btn btn-outline shadow-none text-uppercase"
                     [routerLink]="['/mainpage/pilldetail', pill.id]">
              {{'Buttons_continue' | translate}}
        </button>
        <p class="pilltitle">{{pill.title}}</p><br>
        <!-- progress bar -->
        <div class="course-progress position-relative">
          <div class="position-absolute w-50"></div>
          <div style="border-bottom: 2px solid black; height: 25px;"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="seemore">
    <div *ngFor="let pill of pillsongoing; let i = index">
      <div class="mb-4" *ngIf="i<2" >

        <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                    [routerLink]="['/mainpage/pilldetail', pill.id]">
             {{pill.startText}}
        </button>
        <p class="pilltitle">{{pill.title}}</p><br>
         <!-- progress bar -->
         <div class="course-progress position-relative">
          <div class="position-absolute w-50"></div>
          <div style="border-bottom: 2px solid black; height: 25px;"></div>
        </div>
      </div>

    </div>
    <xiaomi-button class="center mt-4" (click)="seeall()">
        {{'Buttons_seeall' | translate}}
    </xiaomi-button>
 </div>

 <div *ngIf="seeallpills">
    <div *ngFor="let pill of pillsongoing">
      <div class="mb-4" >

        <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                  [routerLink]="['/mainpage/pilldetail', pill.id]">
           {{pill.startText}}
        </button>
        <p class="pilltitle">{{pill.title}}</p><br>
        <!-- progress bar -->
        <div class="course-progress position-relative">
          <div class="position-absolute w-50"></div>
          <div style="border-bottom: 2px solid black; height: 25px;"></div>
        </div>
      </div>
    </div>
    <xiaomi-button class="center mt-4" (click)="seeless()">
      {{'Buttons_seeonlytwo' | translate}}
    </xiaomi-button>
</div>

<div  class="mt-5 mb-2" style="height: 1px;background-color: #B9B6B6;"></div>

<p style="text-transform: uppercase;font-size: 16px;">{{'home_completed' | translate}}</p>

<div *ngIf="seenonecomp">
  <p class="text-center" style="font-size: 20px; color: var(--main-text-color);">{{'profile_donothavecertificate' | translate}}</p>
</div>

<div *ngIf="seetwocomp">
  <div *ngFor="let certificate of userCertificates; let i = index">
    <div *ngIf="i<2" class="position-relative" style="float: left;width: 50%;">
      <div class="center row certificateContainer" style="display: flex; align-items: center; padding-bottom: 10px;">
        <svg-icon class="svg" [name]="'certificate_template'" [svgStyle]="{'width': '100%', 'height': 'auto'}"></svg-icon>
        <span class="position-absolute testo">{{certificate.subFamily}} <br> {{certificate.certificateName}}</span>
      </div>
    </div>
  </div>
  <br><br><br>
</div>

<div *ngIf="seemorecomp" class="div_certificate">
  <div *ngFor="let certificate of userCertificates; let i = index">
    <div class="mb-2" class="position-relative" *ngIf="i<2" style="float: left;width: 50%;">
      <div class="center row certificateContainer" style="display: flex; align-items: center; padding-bottom: 10px;">
        <svg-icon class="svg" [name]="'certificate_template'" [svgStyle]="{'width': '100%', 'height': 'auto'}" ></svg-icon>
        <span class="position-absolute testo">{{certificate.subFamily}} <br> {{certificate.certificateName}}</span>
      </div>
    </div>
  </div>
  <div style="width: auto;width:120px; margin: auto;" *ngIf="userCertificates?.length>2">
    <xiaomi-button class="center" style="display: block;" (click)="seeallcomp()">
        {{'Buttons_seeall' | translate}}
    </xiaomi-button>
  </div>
</div>

<div *ngIf="seeallpillscomp">
  <div *ngFor="let certificate of userCertificates">
    <div class="mb-2 position-relative" style="float: left;width: 50%;">
      <div class="center row certificateContainer" style="display: flex; align-items: center; padding-bottom: 10px;">
        <svg-icon class="svg" [name]="'certificate_template'" [svgStyle]="{'width': '100%', 'height': 'auto'}"></svg-icon>
        <span class="position-absolute testo">{{certificate.subFamily}} <br> {{certificate.certificateName}}</span>
      </div>
    </div>
  </div>
  <div style="width: auto;width:120px; margin: auto;">
    <xiaomi-button class="center" (click)="seelesscomp()"  style="display: block;">
      {{'Buttons_seeonlytwo' | translate}}
    </xiaomi-button>
  </div>
</div>

<div class="mt-5 mb-2" style="height: 1px;background-color: #B9B6B6;"></div>


<p style="text-transform: uppercase;font-size: 16px;text-transform: uppercase;">{{'badge_badge' | translate}}</p>
<div class="center" style="justify-content: space-evenly;">
  <div>
    <xiaomi-badge [name]="url10"></xiaomi-badge>
    <span *ngIf="!textmiss10" class="center mt-3" style="text-align: center;">{{'badge_stillmissing' | translate}} <br> {{stillmiss10}} {{'home_courses' | translate}}</span>
    <span *ngIf="textmiss10" class="center mt-3" style="text-align: center;">{{'home_completed' | translate}}</span>
  </div>

  <div>
    <xiaomi-badge [name]="url15"></xiaomi-badge>
    <span *ngIf="!textmiss15" class="center mt-3" style="text-align: center;">{{'badge_stillmissing' | translate}} <br> {{stillmiss15}} {{'home_courses' | translate}}</span>
    <span *ngIf="textmiss15" class="center mt-3" style="text-align: center;">{{'home_completed' | translate}}</span>
  </div>
  
  <div>
    <xiaomi-badge [name]="url20"></xiaomi-badge>
    <span *ngIf="!textmiss20" class="center mt-3" style="text-align: center;">{{'badge_stillmissing' | translate}} <br> {{stillmiss20}} {{'home_courses' | translate}}</span>
    <span *ngIf="textmiss20" class="center mt-3" style="text-align: center;">{{'home_completed' | translate}}</span>
  </div>
</div>

<div  class="mt-4 mb-2" style="height: 1px;background-color: #B9B6B6;"></div>

<p style="text-transform: uppercase;font-size: 16px;text-transform: uppercase;">{{'profile_expertise' | translate}}</p>

<div *ngFor="let grafic of expertise" style="float: left;width: 50%;">
    <img src="{{grafic[4]}}" style="max-width: 100px;float: left;"/>
    <div class="mt-2">
      <span style="text-transform: uppercase; color:var(--main-text-color);font-weight: bold;font-size: 15px;"><br>{{grafic[1]}}</span>
    </div>
</div>

</div>