import { CategoryService } from 'src/app/services/category.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaderBoardModel } from 'src/app/model/LeaderBoardModel';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent implements OnInit {
  user!: string;
  pass!: string;
  AvatarImgSrc!:string;
  UserPoint!:string;
  UserPosition!:string;
  Leadearboads:LeaderBoardModel[]=[];
  constructor(private categoryService: CategoryService,
    private router: Router,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    this.LeaderBoardUserAuth();
    this.UsersLeaderBoardUserAuth();
  }

  async LeaderBoardUserAuth(): Promise<void> {
    await this.categoryService.LeaderBoardUserAuth(this.user, this.pass)
      .subscribe(
        data => {

          this.AvatarImgSrc = this.categoryService.baseURLimg + data.imagePath.replace('~', '');
          this.UserPoint = data.point;
          this.UserPosition = data.position;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  async UsersLeaderBoardUserAuth(): Promise<void> {
    await this.categoryService.UsersLeaderBoardUserAuth(this.user, this.pass)
      .subscribe(
        data => {

          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath+"?"+(new Date()).getTime();
          }
          this.Leadearboads=data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
}
