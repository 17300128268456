export class UserModel {

    constructor(
      public Id: string,
      public Email: string,
      public Surname: string,
      public Name: string,
      public IdRegion: number,
      public IdChannel: number,
      public Region: string,
      public Channel: string,
      public EmailConfirmed: boolean,
      public Active: boolean,
      public ImageFullPath: string,
      public NickName: string,
      public Country: string,
      public Status: string,
      public DateRegistration: Date,
      public DateLastLogin: Date,
      public IsBanned: boolean,
      public Point: number,
      public Role: string,
      public RoleName: string,
      public PointConsumed: number,
      public IdStore: number,
      public UserId: string
      
    ) {  }
  
  }