import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  baseURL = this.categoryService.baseURLimg;

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  news: any;

  imageUrlND: any;
  titleND: any;
  textND: any;
  linkND: any;
  linkTextND: any;

  counter: boolean = false;

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    this.getNews();
  }

  getNews(){
    this.categoryService.getnewsAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.news = data;
          console.log(data);
          this.newItemEvent.emit(this.news);
          if(this.news.length == 0)
            this.counter = false;
          else
            this.counter = true;
        },
        error => {
          console.log(error);
        });
  }

  GoToNewsDetail(imgUrl:string, title: string, description: string, link: string, linkText:string){
    this.imageUrlND = imgUrl;
    this.titleND = title;
    this.textND = description;
    this.linkND = link;
    this.linkTextND = linkText;
  }
}
