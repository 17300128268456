import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-rewardscomingsoon',
  templateUrl: './rewardscomingsoon.component.html',
  styleUrls: ['./rewardscomingsoon.component.css']
})
export class RewardscomingsoonComponent implements OnInit {

  show_default_popup : boolean = true;
  show_nl_popup : boolean = false;

  constructor(public mainpage: MainPageComponent) { }

  ngOnInit() {

    //get current user language
    var curr_user_lang = localStorage.getItem('country');

    if(curr_user_lang == 'BN'){
      this.show_default_popup = false;
      this.show_nl_popup = true;
    }


  }

}
