 <!--BACK BUTTON-->
 <div style="background-color: black;height: 40px;">

  <div [routerLink]="['/mainpage/challenges']" [queryParams]="{section: 2}" style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>

<div class="container-fluid mx-auto p-0" style="max-width: 98%;">

  <div class="row ml-0 mr-0 pl-0 pr-0">
    <h1 class="col-sm-12" style="padding-top: 10px;">{{'challenges_correct' | translate}} {{'challenges_answer' | translate}}</h1>
  </div>

  <div class="row ml-0 mr-0 pl-0 pr-0 mt-3">
    <div style="width: 32%; margin-left:2%; text-align: center">
      <img class="challenge-image mb-2" style="margin-left: 10px;" mat-card-image src="{{Challenger?.imageFullPath}}"><br>
      <span style="margin-left: 10px; font-size: 16px;"><b>{{'challenges_challenger' | translate}}</b></span><br>
      <span *ngIf="Challenger?.email != user" style="margin-left: 10px; font-size: 16px;">{{Challenger?.nickName}}</span>
    </div>
    <div style="width: 32%;">
      <p style="text-align: center; vertical-align: middle; color: var(--main-text-color); font-size: 25px;" >VS.</p>
    </div>
    <div style="width: 32%;">
      <div style="text-align: center;margin-right: 10px;">
        <img class="challenge-image mb-2" mat-card-image src="{{Opponent?.imageFullPath}}"><br>
        <span style="font-size: 16px;"><b>{{'challenges_opponent' | translate}}</b></span><br>
        <span *ngIf="Opponent?.email != user" style="font-size: 16px;">{{Opponent?.nickName}}</span>
      </div>  
    </div>
  </div>

  <div class="mt-2 mb-3" style="height: 1px;background-color: #B9B6B6;"></div><br>

  <div class="row ml-0 mr-0 pl-0 pr-0 mt-5" style="font-size: 125px;">
    <span style="width: 32%; margin-left:2%; text-align: center">{{ResultChallenge?.correctAnswerUser}}</span>
    <span style="width: 32%; text-align: center">-</span>
    <span class="mb-4" style="width: 32%; text-align: center">{{ResultChallenge?.correctAnswerUserChallenged}}</span>
  </div>

  <div class="mt-5" style="height: 1px;background-color: #B9B6B6;"></div><br>

  <div *ngIf="ResultChallenge?.idWinner === Challenger?.id">
    <svg-icon class="center" [name]="'challenge'" [svgStyle]="{ 'width.px': '50'}"></svg-icon>
    <span class="center mt-1" style="font-size: 20px;">{{'challenges_thewinneris' | translate}}</span>
    <div class="center mt-3">
      <img class="imageWinner" style="margin-left: 10px;" mat-card-image src="{{Challenger?.imageFullPath}}">
    </div>
    <span class="center mt-3" style="font-size: 20px;">{{Challenger?.nickName}}</span>
  </div>

  <div *ngIf="ResultChallenge?.idWinner === Opponent?.id">
    <svg-icon class="center" [name]="'challenge'" [svgStyle]="{ 'width.px': '50'}"></svg-icon>
    <span class="center mt-1" style="font-size: 20px;">{{'challenges_thewinneris' | translate}}</span>
    <div class="center mt-3">
      <img class="imageWinner" style="margin-left: 10px;" mat-card-image src="{{Opponent?.imageFullPath}}">
    </div>
    <span class="center mt-3" style="font-size: 20px;">{{Opponent?.nickName}}</span>
  </div>

  <div *ngIf="ResultChallenge?.idWinner === 'x'">
    <svg-icon class="center" [name]="'challenge'" [svgStyle]="{ 'width.px': '50'}"></svg-icon>
    <span class="center mt-1" style="font-size: 20px;">{{'challenges_thewinneris' | translate}}</span>
    <div class="row">
      <div style="width: 50%;">
        <div class="center mt-3">
          <img class="imageWinner" mat-card-image src="{{Challenger?.imageFullPath}}">
        </div>
        <span class="center mt-3" style="font-size: 20px;">{{Challenger?.nickName}}</span>
      </div>
      <div style="width: 50%;">
        <div class="center mt-3">
          <img class="imageWinner" mat-card-image src="{{Opponent?.imageFullPath}}">
        </div>
        <span class="center mt-3" style="font-size: 20px;">{{Opponent?.nickName}}</span>
      </div>
    </div>
  </div>
</div>
