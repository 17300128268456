import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-n-foryou-pills',
  templateUrl: './n-foryou-pills.component.html',
  styleUrls: ['../n-related-pills/n-related-pills.component.scss']
})
export class NForyouPillsComponent implements OnInit {

  pills!: any;
  user!: string;
  pass!:string;
  screenHeight!: number;
  foryou_loaded = localStorage.getItem('for_you' || 'empty');
  constructor(private categoryService: CategoryService,
    public translate: TranslateService) {

  }

  ngOnInit() {
    this.screenHeight = window.innerHeight;

    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if(userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    this.getNPills();
  }

  getNPills(){

    var pills_to_take = 0;
    const pill_height = 266;
    const header = 55;
    const pills_label = 64;
    this.screenHeight = this.screenHeight - (header + pills_label);

    pills_to_take = Math.floor(this.screenHeight / pill_height);
    //console.warn('pills to take are: ' + pills_to_take);

    if(this.foryou_loaded === 'empty'){
      this.categoryService.GetPillsLimitAuth(0, pills_to_take, "", this.user, this.pass)
      .subscribe(
        data => {
          this.pills = data;
          data.forEach((element: any) => {
            element.imageFullPath = this.categoryService.baseURLimg + element.imageFullPath.replace('~','')
            switch(element.startText){
              case 'START':
                element.buttonText = this.translate.instant('Buttons_start');
                break;
              case 'DOWNLOAD':
                element.buttonText = this.translate.instant('Buttons_download');
                break;
              case 'REPEAT':
                element.buttonText = this.translate.instant('Buttons_repeat');
                break;
              case 'CONTINUE':
                element.buttonText = this.translate.instant('Buttons_confirm');
                break;
              default:
                element.buttonText = element.startText;
                break;
             }
          });
          localStorage.setItem('for_you', JSON.stringify(data));
          console.log(data);
        },
        error => {
          console.log(error);
        });
    }
    else{
        this.pills = JSON.parse(this.foryou_loaded!!);
      }
    }
    

}
