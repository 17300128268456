import { RedirectSliderPageComponent } from './components/main-page/homepage/redirect-slider-page/redirect-slider-page.component';
import { KeywordInputComponent } from './components/search-page/keyword_input_mobile/keyword-input/keyword-input.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { NNewsComponent } from './components/right-container/n-news/n-news.component';
import { PilldetailComponent } from './components/main-page/homepage/pilldetail/pilldetail.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';

import { LoginFormComponent } from './components/login-form/login-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/main-page/homepage/homepage.component';
import { EditUserComponent } from './components/main-page/user-profile/edit-user/edit-user.component';
import { SurveysComponent } from './components/main-page/surveys/surveys.component';
import { NewsComponent } from './components/main-page/news/news.component';
import { NewsdetailComponent } from './components/main-page/news/newsdetail/newsdetail.component';
import { SupportComponent } from './components/main-page/support/support.component';
import { UserProfileComponent } from './components/main-page/user-profile/user-profile.component';
import { HowitworksComponent } from './components/main-page/howitworks/howitworks.component';
import { LeaderboardComponent } from './components/main-page/leaderboard/leaderboard.component';
import { RewardsComponent } from './components/main-page/rewards/rewards.component';
import { BookmarksComponent} from './components/main-page/bookmarks/bookmarks.component';
import { NotificationsarchiveComponent } from './components/main-page/notificationsarchive/notificationsarchive.component';
import { NForyouPillsComponent } from './components/right-container/n-foryou-pills/n-foryou-pills.component';
import { NRelatedPillsComponent } from './components/right-container/n-related-pills/n-related-pills.component';
import { TermsconditionComponent } from './components/main-page/termscondition/termscondition.component';
import { PrivacypolicyComponent } from './components/main-page/privacypolicy/privacypolicy.component';
import { RewardscomingsoonComponent } from './components/main-page/rewards/rewardscomingsoon/rewardscomingsoon.component';
import { ChallengescomingsoonComponent } from './components/main-page/challenges/challengescomingsoon/challengescomingsoon.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { ChallengesComponent } from './components/main-page/challenges/challenges.component';
import { ChallengeSearchOpponentComponent } from './components/main-page/challenges/challengeSearchOpponent/challengeSearchOpponent.component';
import { ChallengesLeaderboardComponent } from './components/main-page/challenges/challengesLeaderboard/challengesLeaderboard.component';
import { ChallengeResultComponent } from './components/main-page/challenges/challengeResult/challengeResult.component';
import { RedirectpageComponent } from './components/main-page/notificationsarchive/redirectpage/redirectpage.component';
import { SureToDeleteComponent } from './components/main-page/user-profile/edit-user/sureToDelete/sureToDelete.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', component: WelcomePageComponent },
  {path: 'mainpage',
   component: MainPageComponent,
   children: [
      { path: 'homepage', component: HomepageComponent},
      { path: 'pilldetail/:id', component: PilldetailComponent },
      { path: 'edituser', component: EditUserComponent },
      { path: 'surveys', component: SurveysComponent },
      { path: 'news', component: NewsComponent },
      { path: 'newsdetail/:id', component: NewsdetailComponent },
      { path: 'support', component: SupportComponent },
      { path: 'userprofile', component: UserProfileComponent },
      { path: 'howitworks', component: HowitworksComponent },
      { path: 'leaderboard', component: LeaderboardComponent },
      { path: 'rewards', component: RewardsComponent },
      { path: 'rewardscomingsoon', component: RewardscomingsoonComponent },
      { path: 'challengescomingsoon', component: ChallengescomingsoonComponent },
      { path: 'bookmarks', component: BookmarksComponent },
      { path: 'notificationsarchive', component: NotificationsarchiveComponent },
      { path: 'termscondition', component: TermsconditionComponent },
      { path: 'privacypolicy', component: PrivacypolicyComponent },
      { path: 'bookmarks', component: BookmarksComponent },
      { path: 'search/:keyword', component:SearchPageComponent},
      { path: 'keywordinput', component:KeywordInputComponent},
      { path: 'challenges', component:ChallengesComponent},
      { path: 'challengesearchopponent/:id', component: ChallengeSearchOpponentComponent },
      { path: 'challengesleaderboard', component: ChallengesLeaderboardComponent },
      { path: 'challengeresult/:id', component: ChallengeResultComponent },
      { path: 'redirectPage/:PageId/:ContentId', component: RedirectpageComponent },
      { path: 'redirectSliderPage/:ExternalLinkActive/:ExternalLink/:LinkType/:Link', component: RedirectSliderPageComponent },
      { path: 'sureToDelete', component: SureToDeleteComponent },

      { path: 'news', component: NNewsComponent, outlet: 'sidebar'}, //for you pills
      { path: 'related/:id', component: NRelatedPillsComponent, outlet: 'sidebar' }, // related pills
      { path: 'foryou', component: NForyouPillsComponent, outlet: 'sidebar' }, // foryou pills
      //{ path: '', component: NNewsComponent, outlet: 'sidebar'}, //for you pills


   ]
  },
  {path: 'welcome', component: WelcomePageComponent},
  {path: 'login', component: LoginFormComponent},
  {path: 'signup', component: RegistrationFormComponent}
];

// const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
