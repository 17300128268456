<h1 class="text-uppercase ml-3" style="font-size: 18px;">{{'home_foryou' | translate}}</h1>
<div *ngFor="let pill of pills; last as isLast" [id]="pill.id" class="row center ml-0 mr-0"
  [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', pill.id], sidebar: ['related', pill.id] } }]">
  <mat-card class="example-card">
    <mat-card-content class="position-relative">
      <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
      <span class="pill-title" [ngStyle]="{'color': pill?.titleColor }">{{pill.title}}</span>
      <svg-icon [name]="pill.favourite ? '4_bookmarks_orange_filled' : '4_bookmarks_orange'"
        [svgStyle]="{ 'width.px': '30' }" class="pill-heart-icon icon-white"></svg-icon>
      <mat-grid-list cols="5">
        <mat-grid-tile colspan="3">
          <div class="text-left w-100">
            <div class="line-clamp"> {{pill.abstractBox}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" style="overflow: initial;">
          <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.isCompleted === true && pill?.iD_Type == 0"
          [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', pill.id], sidebar: ['related', pill.id] } }]">

            <svg-icon *ngIf="pill?.isCompleted === true && pill?.iD_Type == 0 " [name]="'repeat_arrow'"
            [svgStyle]="{ 'width.px': '18'}"></svg-icon>
            {{pill.buttonText}}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
  <mat-divider style="margin: 10px !important;" class="w-100 mt-3 mb-3" *ngIf="!isLast"></mat-divider>
</div>
