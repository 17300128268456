<div class="editUserContainer">
<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>

<div style="margin:auto; max-width: 425px;" class="p-3">


  <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
    <h1>{{'profile_personaldata' | translate}}</h1>

    <div class="person-icon">
      <img [src]="getLinkPicture()" class="AvatarImgBig">&nbsp;&nbsp;&nbsp;&nbsp;
      <input id="file" #newFile class="fileUpload"
      (change)="handleFileInput($event.target)" required accept=".jpg" type="file" style="margin-left: 10px;"/>
      <span (click)="fakeButtonClick()" >
        <svg-icon class="rotated45 hand" [name]="'esc'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>&nbsp;&nbsp;
        <label class="hand" >{{'CoursePage_taptoEDITthepic' | translate}}</label>
      </span>
      <xiaomi-button mat-raised-button color="primary"
    (click)="savePhoto()"
    [hidden]="fileToUpload==null">{{'Buttons_confirm' | translate}}</xiaomi-button>
    </div>

    <div style="height: 10px;"></div>
   <mat-form-field  class="d-block">
     <label>{{'registration_nickname' | translate}}</label>
     <input matInput type="text" formControlName="nickname"/>
     <mat-error *ngIf="nickname?.hasError('minlength')">{{'registration_correctnickname' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field  class="d-block">
     <label>{{'registration_name' | translate}}</label>
     <input matInput type="text" formControlName="name" required />
     <mat-error *ngIf="name?.hasError('pattern')">{{'registration_Plaeseinsertavalidna' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field class="d-block">
     <label>{{'registration_surname' | translate}}</label>
     <input matInput type="text" formControlName="surname" required />
     <mat-error *ngIf="surname?.hasError('pattern')">{{'registration_Plaeseinsertavalidsu' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field class="d-block">
     <label>{{'registration_e-mailaddress' | translate}}</label>
     <input matInput type="email" [value] ="userEmail"/>
     <mat-error *ngIf="email?.hasError('pattern')">{{'registration_Plaeseinsertavalidem' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field class="d-block">
     <label>{{'registration_password' | translate}}</label>
     <input matInput [type]="hide ? 'password' : 'text'" [value]="pass"/>
     <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <svg-icon [name]="hide ? 'hide' : 'see'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
     </button>
     <mat-error *ngIf="password?.hasError('pattern')"> {{'registration_Thepassworddoesntmee' | translate}}</mat-error>
   </mat-form-field>
   <div matTooltip="{{'registration_passwordmustcontain' | translate}}
   {{'registration_passwordlength' | translate}}
   {{'registration_passwordonelower' | translate}}
   {{'registration_passwordonenumber' | translate}}
   {{'registration_passwordonespecial' | translate}}
   {{'registration_passwordoneupper' | translate}}"
   matTooltipPosition="right" style="margin-left: -5px;" matTooltipClass="my-tooltip">
   <svg-icon [name]="'info'" [svgStyle]="{ 'width.px': '20' }"></svg-icon> <b>{{'registration_rulesforpasswordcrea' | translate}}</b>
  </div>
   <!--<mat-form-field class="d-block">
     <mat-label>Confirm Password</mat-label>
     <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="confirm_password" required />
     <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
       <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
     </button>
   <mat-error *ngIf="confirm_password?.hasError('confirmedValidator')">
     {{'registration_Passworddontmatch' | translate}}
   </mat-error>
   </mat-form-field>-->
   <mat-form-field class="d-block">
     <label>{{'registration_channel' | translate}}</label>
     <mat-select formControlName="channel" (ngModelChange)="getStores()" required>
       <mat-option *ngFor="let channel of channels" [value]="channel.id">
         <b>{{channel.canale}}</b> |
         <span style="color: orangered; font-weight: bold;">{{channel.sottoCanale}}</span>
       </mat-option>
     </mat-select>
     <mat-error *ngIf="channel?.hasError('required')">{{'registration_pleaseselectthechannel' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field class="d-block">
     <label>{{'registration_region' | translate}}</label>
     <mat-select formControlName="region" (ngModelChange)="getStores()" required>
      <div class="inputContainerEdit search-desktopEdit d-inline-block">
        <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
        <input class="FieldEdit" [(ngModel)]="keywordSearch" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchContentRegion(keywordSearch)" type="text" placeholder="Search"/>
      </div>
      <div style="height: 1px;background-color: #B9B6B6;margin-top: 10px;"></div>
       <mat-option *ngFor="let region of regionSearched" [value]="region.id">
         <b>{{region.region}}</b>
       </mat-option>
     </mat-select>
     <mat-error *ngIf="region?.hasError('required')">{{'registration_pleaseselecttheregion' | translate}}</mat-error>
   </mat-form-field>
   <mat-form-field class="d-block" *ngIf="stores?.length > 0">
    <label>STORE</label>
    <mat-select formControlName="store" >
      <div class="inputContainerEdit search-desktopEdit d-inline-block">
        <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
        <input class="FieldEdit" [(ngModel)]="keywordSearch" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchContentStore(keywordSearch)" type="text" placeholder="Search"/>
      </div>
      <div style="height: 1px;background-color: #B9B6B6;margin-top: 10px;"></div>
      <mat-option *ngFor="let store of storeSearched" [value]="store.id">
        <b>{{store.name}}</b>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="countryUser === 'ZZZ'">
    <mat-form-field  class="d-block" *ngIf="stores?.length > 0">
      <label>USER ID</label>
      <input matInput type="text" formControlName="userid"/>
    </mat-form-field>
  </div>

   <div>
     <mat-slide-toggle color='primary' formControlName="tos"></mat-slide-toggle>
     <div class="d-inline ml-2">{{'registration_iagreetomiacademyste' | translate}} <a (click)="TermsSer()">{{'menu_termsofuse' | translate}}</a></div>
   </div>

   <div class="overlay" *ngIf="popup" style="z-index: 99;">
    <div class="popup">
      <h2>{{'menu_termsofuse' | translate}}</h2>
      <a class="close" (click)="popup = false">&times;</a>
      <!--<div [innerHTML]="Terms"></div>-->
      <iframe [srcdoc]="Terms"></iframe>
    </div>
  </div>

  </form>

  <div class="center pt-4 mb-5">
    <xiaomi-button mat-raised-button color="primary"
    (click)="saveUser()"
    [disabled]="!editUserForm?.valid">{{'Buttons_confirm' | translate}}</xiaomi-button>
  </div>
</div>

  <span class="center mb-5" 
        style="color: var(--xiaomi-gray); font-weight: bold; text-decoration: underline; font-size: 18px; cursor: pointer;" 
        (click)="goToDelete()">{{'profile_deletemyaccount' | translate}}
  </span>
</div>