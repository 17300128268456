import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { StartChallengeInput, UserToInviteLimitInput } from 'src/app/model/ModelInput';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-challengeSearchOpponent',
  templateUrl: './challengeSearchOpponent.component.html',
  styleUrls: ['./challengeSearchOpponent.component.css']
})
export class ChallengeSearchOpponentComponent implements OnInit {

  user!: string;
  pass!: string;
  country!: string;
  challengeSelected: any;
  challenge_id!: number;
  channels: any;
  usersChallengeSameRegion:any = [];
  usersChallengeChannel:any = [];
  channelSelected: boolean = false;
  allUserChallenge: any = [];
  userSearched: any = [];
  counterUserSearched: boolean = false;
  inviteSent: any = '2';
  idPill!: number;
  usersChallenge: any = [];

  filterSelected: number = 1;
  keywordSearch: string = '';
  router: any;
  constructor(
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    public mainpage: MainPageComponent
    ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    let countryapp = localStorage.getItem('country');
    if (userapp != null && passapp != null && countryapp != null) {
      this.user = userapp;
      this.pass = passapp;
      this.country = countryapp;
    }

    this.challenge_id = this.route.snapshot.params.id;

    this.getChallenge();
    this.GetChannels();
    //this.UsersChallengeAuth();
  }

  async getChallenge(): Promise<void> {

    await this.categoryService.getchallengesAuth(this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);


          for (let i = 0; i < data.length; i++) {
            if (data[i].id = this.challenge_id) {
              data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath;
              this.challengeSelected = data[i];
            }
          }
          console.log(this.challengeSelected);
          this.idPill = this.challengeSelected.pillsId;
        },
        error => {
          console.log(error);
        }
      );
  }

  toggleChangeSearch(event: any) {
    this.filterSelected = event.source.id;  
    this.channelSelected = false;
    this.counterUserSearched = false;

    if (this.filterSelected == 1)
      this.GetUserToInviteLimitAuth(0,"",false,true);
  }

  searchContent(keyword: string){
    this.counterUserSearched = true;
    this.userSearched = [];
    this.GetUserToInviteLimitAuth(0,keyword,false,false);
  }

  userChannelSelected(idChannel: number): void{
    this.channelSelected = true;
    this.filterSelected = 0;
    this.usersChallengeChannel = [];
    this.GetUserToInviteLimitAuth(idChannel,"",true,false);
  }

  invite(idUser: string): void{
    var challengeId = this.challenge_id;
    var challengeStart = new StartChallengeInput(challengeId, idUser);
    console.log(challengeStart);
    this.categoryService.StartChallenge(challengeStart, this.user, this.pass).subscribe(
      data => {
        console.log("invito inviato");
        this.inviteSent = '1';
      },
      error => {
        console.log(error);
        this.inviteSent = '0';
      });
  }

  GetChannels(): void{
    this.categoryService.getchannelsAuth(this.country,this.user, this.pass)
      .subscribe(
        data => {
          this.channels = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  async GetUserToInviteLimitAuth(idChannel:number, search:string, searchChannel:boolean, searchRegion:boolean): Promise<void> {
    let dataChallenge = new UserToInviteLimitInput(0,999,this.idPill, idChannel, search, searchChannel, searchRegion);
    await this.categoryService.GetUserToInviteLimitAuth(dataChallenge,this.user, this.pass)
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
          data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath+"?"+(new Date()).getTime();
          }
          console.log(data);
          this.usersChallenge = data;
          console.log(this.usersChallenge);
        },
        error => {
          console.log(error);
        });
  }

}


