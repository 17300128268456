<div class="loginContainer">
<img src="../../../assets/images/logo_grande.png" style="width: 300px; margin-top:82px" class="p-3">
<!--login-->
<form [formGroup]="userLoginForm" (ngSubmit)="onSubmit()" class="position-relative" style="width: 100%;">

  <mat-form-field class="d-block">
    <label class="uppercase">{{'registration_e-mailaddress' | translate}}</label>
    <input matInput type="text" formControlName="email" required />
  </mat-form-field>

  <mat-form-field class="d-block">
    <label class="uppercase">{{'registration_password' | translate}}</label>
    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
    <button mat-icon-button [disableRipple]="true" matSuffix
      (click)="hide = !hide" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide">
      <svg-icon [name]="hide ? 'hide' : 'see'"
        [svgStyle]="{ 'width.px': '20' }"
        [svgClass]="'eyeicon'"></svg-icon>
    </button>
  </mat-form-field>
  <mat-error *ngIf="errorLogin">{{'registration_errorlogin' | translate}}</mat-error>

  <!-- <strong>Test</strong> -->

  <div class="w-100">
    <xiaomi-button [type]="'submit'" [disabled]="!userLoginForm.valid"
      style="position: absolute; left: 90px; top: 265px;">
      {{'Buttons_login' | translate}}
    </xiaomi-button>
  </div>

  <!-- <button mat-raised-button color="primary" style="width: 100%;" type="submit" [disabled]="!userLoginForm.valid">
    LOG IN
  </button> -->
</form>
<div>
  <label class="mt-3" (click)="popupForgotPassword()">
    <span style="cursor: pointer;">
      <u><b>{{'registration_Iforgotmypassword' | translate}}</b></u>
    </span>
  </label>

</div>
<div class="mt-3" style="position: absolute;top: 455px; font-size: 16px;">
  <div style="margin-left: 50px;">
    {{'registration_newtoMiAcademySignUp' | translate}}
    <span [routerLink]="['/signup']" routerLinkActive="router-link-active" style="cursor: pointer;">
      <u><b>{{'Buttons_signup' | translate}}</b></u>
    </span>
  </div>
  <xiaomi-imprint style="display: flex; margin-left: -50px; margin-top: 20px;"></xiaomi-imprint>
</div>
<br>

<!-- popups -->
<div class="overlay" *ngIf="popup">
  <div class="popup">
    <form [formGroup]="userResetForm" (ngSubmit)="onSubmitReset()" class="container container-popup">
      <a class="close" (click)="ClosePopup()">&times;</a>
      <label class="mt-3">{{'registration_resetpwd' | translate | uppercase}}</label><br>
      <mat-form-field class="d-block">
        <label class="uppercase">{{'registration_e-mailaddress' | translate | uppercase}}</label>
        <input matInput type="text" formControlName="email" required (keyup)="checkEmailExist()" />
        <mat-error [hidden]="emailExist">{{'registration_Plaeseinsertavalidem' | translate}}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field class="d-block">
        <input matInput type="text" formControlName="email" required (keyup)="checkEmailExist()" /><br><br>
        <mat-error [hidden]="emailExist">{{'registration_Plaeseinsertavalidem' | translate}}</mat-error>
      </mat-form-field> -->
      <br>
      <xiaomi-button [type]="'submit'" [disabled]="!userResetForm.valid || wait">
        {{'CoursePage_send' | translate | uppercase}}
      </xiaomi-button>
    </form>
  </div>
</div>

<div class="overlay" *ngIf="popupSent">
  <div class="popup">
    <div class="container container-popup">
      <a class="close" (click)="ClosePopupSent()">&times;</a>
      <label>{{'registration_emailsent' | translate | uppercase}}</label><br>
    </div>


  </div>
</div>
</div>