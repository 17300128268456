import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

constructor() { }

//notification counter
private data = new BehaviorSubject(0);
data$ = this.data.asObservable();
changeData(data: number) {
  this.data.next(data)
}

//id challenge
private idChallenge = new BehaviorSubject(0);
data_challenge$ = this.idChallenge.asObservable();
changeIdChallengeData(data: number){
  this.idChallenge.next(data);
}

//survey link
private surveyLink = new BehaviorSubject("");
data_surveyLink$ = this.surveyLink.asObservable();
changeSurveyLinkData(data: string){
  this.surveyLink.next(data);
}

}
