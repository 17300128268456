import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page/main-page.component';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

  baseURL = this.categoryService.baseURLimg;
  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;
  searchKeyword!: string|null;

  tabIndex: number = 0; //default tab is courses tab

  coursesFound!: any;
  challengesFound!: any;
  newsFound!: any;

  constructor( private categoryService: CategoryService,
               private route: ActivatedRoute,
               private router: Router,
               public mainpage: MainPageComponent) { }

  ngOnInit() {
    //this.searchKeyword = this.route.snapshot.paramMap.get('keyword');
    this.route.paramMap.subscribe( paramMap => {
      this.searchKeyword = paramMap.get('keyword');
      this.getSearchResultsFor(this.tabIndex, true);
    })
  }


  async getSearchResultsFor(current_tab: number, forceSearch: boolean){
    if(current_tab === 0 && (this.coursesFound == null || forceSearch)){
      this.GetPillsSearch();
    }else if(current_tab === 1 && (this.challengesFound == null || forceSearch)){
      this.GetChallengesSearch()
    }
    else if(current_tab === 2 && (this.newsFound == null || forceSearch)){
      this.GetNewsSearch();
    }
  }

   async GetPillsSearch(){
    await this.categoryService.getPillsSearchAuth(this.userEmail,this.pass, this.searchKeyword)
    .subscribe(
      data => {
        data.forEach((element: any) =>{
          element.imageFullPath = this.categoryService.baseURLimg + element.imageFullPath.replace('~','');
        });
        this.coursesFound = data;
        console.warn(data);
      },
      error => {
        console.log(error);
      });
   }

   async GetChallengesSearch(){
    await this.categoryService.getChallengesSearchAuth(this.userEmail,this.pass, this.searchKeyword)
    .subscribe(
      data => {
        this.challengesFound = data;
        console.warn(data);
      },
      error => {
        console.log(error);
      });
   }

   async GetNewsSearch(){
    await this.categoryService.getNewsSearchAuth(this.userEmail,this.pass, this.searchKeyword)
    .subscribe(
      data => {
        this.newsFound = data;
        console.warn(data);
      },
      error => {
        console.log(error);
      });
   }

  //index = 0 - courses
  //index = 1 - challenges
  //index = 2 - news
  OnTabChange(value:any){
    this.tabIndex = value.index;;

    this.getSearchResultsFor(this.tabIndex, false);
    switch(this.tabIndex){
      case 0: //courses
      case 1: //challenges
        this.router.navigate(['/mainpage',{ outlets: { sidebar: ['news'] }}]);
        break;
      case 2: //news
        this.router.navigate(['/mainpage',{ outlets: { sidebar: ['foryou'] }}]);
        //this.router.navigate(['/mainpage', { outlets: { primary: ['search', keyword], sidebar: ['news'] }}]);
        break;
    }
  }
}
