import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { ChallengeLimitInput } from 'src/app/model/ModelInput';
import { CategoryService } from 'src/app/services/category.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';  
import { TranslateService } from '@ngx-translate/core';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.css']
})
export class ChallengesComponent implements OnInit {

  user!: string;
  pass!: string;
  challengesAll: any = [];
  waiting: any = [];
  completed: any = [];
  urlSafe: SafeResourceUrl | undefined;
  popup = false;
  IdPill: any;
  sorry: boolean = false;
  @ViewChild('TabGroup') TabGroupVar : MatTabGroup | undefined;
  
  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }

    this.GetChallengesLimitAuth();
    this.GetUserchallengesOnGoingLimitAuth();
    this.GetUserChallengesCompletedLimitAuth();
    
  }

  ngAfterViewInit() {
    var section;
    this.route.queryParamMap.subscribe(params => section = params.get('section'));

    if(section == '0'){
      if(this.TabGroupVar != null) this.TabGroupVar.selectedIndex = 0;
    }
    
    if(section == '1'){
      if(this.TabGroupVar != null) this.TabGroupVar.selectedIndex = 1;
    }

    if(section == '2'){
      if(this.TabGroupVar != null) this.TabGroupVar.selectedIndex = 2;
    }
  }

  OnChallengeTabsChange(event: MatTabChangeEvent) {
    
    if(event.index == 2){
      this.GetUserChallengesCompletedLimitAuth();
    }else if(event.index == 1){
      this.GetUserchallengesOnGoingLimitAuth();
    }else if(event.index == 0){
      this.GetChallengesLimitAuth();
    }

  }

  goToSearchOpponent(id: number, completed: boolean, pillId: number) {
    this.IdPill = pillId;
    if (completed)
      this.router.navigateByUrl('/mainpage/(challengesearchopponent/' + id + '//sidebar:news)');
    else
      this.sorry = true;

  }

  async GetChallengesLimitAuth(): Promise<void> {
    let order: any = null;
    let dataChallenge = new ChallengeLimitInput(0, 999, order!);
    this.challengesAll = [];

    await this.categoryService.GetChallengesLimitAuth(dataChallenge, this.user, this.pass)
      .subscribe(
        data => {
          this.challengesAll = [];
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath;
            this.challengesAll.push(data[i]);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  async GetUserchallengesOnGoingLimitAuth(): Promise<void> {

    let order: any = null;
    let dataChallenge = new ChallengeLimitInput(0, 999, order!);
    this.waiting = [];

    await this.categoryService.GetUserchallengesOnGoingLimitAuth(dataChallenge, this.user, this.pass)
      .subscribe(
        data => {
          this.waiting = [];
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath;
            data[i].userImage = this.categoryService.baseURLimg + data[i].userImage;
            data[i].userOpponentImage = this.categoryService.baseURLimg + data[i].userOpponentImage;

            let app: any = data[i].dateRequest.split('-');
            let day: any = app[2].substring(0, 2);
            data[i].dateRequest = day + '/' + app[1] + '/' + app[0];

            if (data[i].state == 0)
              data[i].challengeButton = this.translate.instant('challenges_waitingopponent');
            else if (data[i].state == 1 || data[i].state == 5)
              data[i].challengeButton = this.translate.instant('Buttons_accept');
            else if (data[i].state == 2)
              data[i].challengeButton = this.translate.instant('Buttons_start');
            else if (data[i].state == 3)
              data[i].challengeButton = this.translate.instant('Buttons_continue');
                
            this.waiting.push(data[i]);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  async GetUserChallengesCompletedLimitAuth(): Promise<void> {
    let order: any = null;
    let dataChallenge = new ChallengeLimitInput(0, 999, order!);
    this.completed = [];
    await this.categoryService.GetUserChallengesCompletedLimitAuth(dataChallenge, this.user, this.pass)
      .subscribe(
        data => {
          this.completed = [];
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            data[i].imageFullPath = this.categoryService.baseURLimg + data[i].imageFullPath;
            data[i].userImage = this.categoryService.baseURLimg + data[i].userImage;
            data[i].userOpponentImage = this.categoryService.baseURLimg + data[i].userOpponentImage;

            let app: any = data[i].dateCompleted.split('-');
            let day: any = app[2].substring(0, 2);
            data[i].dateCompleted = day + '/' + app[1] + '/' + app[0];

            this.completed.push(data[i]);
          }
          //console.log(this.completed);
        },
        error => {
          console.log(error);
        }
      );
  }

  GoToChallenge(pill_id: number): void {
    let found = false;
    let i = 0;
    while (!found && this.waiting.length > i) {
      if (this.waiting[i].challengeStardedId == pill_id) {
        found = true;
      }
      else {
        i++;
      }
    }
    if (this.waiting[i].state == 5) {
        this.AcceptChallenge(pill_id);
    }
    let url = encodeURI(this.categoryService.baseURLimg + "/Player/Challenges?Id=" + pill_id + "&Email=" + this.user + "&Password=" + this.pass);
    console.log(url);
    this.popup = true;



    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async CloseCourse(): Promise<void> {

    this.popup = false;
    await this.delay(500);

    this.GetChallengesLimitAuth();
    this.GetUserchallengesOnGoingLimitAuth();
    this.GetUserChallengesCompletedLimitAuth();
  }
  async RefuseChallenge(challengeStartedId: number): Promise<void> {
    await this.categoryService.RefuseChallenge(challengeStartedId, this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
    setTimeout(() => {
      this.GetChallengesLimitAuth();
      this.GetUserchallengesOnGoingLimitAuth();
      this.GetUserChallengesCompletedLimitAuth();
    }, 200);
  }
  async AcceptChallenge(challengeStartedId: number): Promise<void> {
    await this.categoryService.AcceptChallenge(challengeStartedId, this.user, this.pass)
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }

}
