import { NavigationService } from './../../services/Navigation.service';
import { PasswordValidator } from './../../services/PasswordValidator';
import { CategoryService } from './../../services/category.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PolicyInput, StoreInputAuth } from 'src/app/model/ModelInput';
import { UsersService } from 'src/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';


interface Country {
  name: string;
  code: string;
}


@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationFormComponent implements OnInit {
  popup = false;
  popuppol = false;
  @ViewChild('file') myInputVariable: ElementRef | undefined;

  hide: boolean = true;
  hide2: boolean = true;


  channels: any;
  regions: any;
  stores: any;
  Terms: any;
  Policy: any;
  nick: any;

  keywordSearch: string = '';

  countrysel: any;

  regionSearched: any;
  storeSearched: any;
  

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private userService: UsersService,
    private navigation: NavigationService,
    public translate: TranslateService,
    private router: Router, ) {

    translate.addLangs(['uk', 'it', 'es', 'fr', 'de', 'bn']);
    translate.setDefaultLang('uk');

    const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/en|/it) ? browserLang : 'en');

  }

  ngOnInit() {
    console.log(this.currentPage);
  }

  //navigation data
  currentPage: number = 1;
  emailExist: boolean = false;
  nicknameExist: boolean = false;
  wait: boolean = false;
  imagePath: any;
  imgURL: any;
  //STEP 1 data
  countries: Country[] = [
    { name: 'FRANCE', code: 'FR' },
    { name: 'GERMANY', code: 'DE' },
    { name: 'SPAIN', code: 'ES' },
    { name: 'ITALY', code: 'IT' },
    { name: 'UNITED KINGDOM', code: 'UK' },
    { name: 'BENELUX', code: 'BN' },
    { name: 'PORTUGAL', code: 'PT' }
  ];



  // userRegistrationForm2 = this.fb.group({
  //   country: ['', Validators.required],
  //   surname: ['', Validators.required],
  //   email: ['', Validators.required],
  //   password: ['', Validators.required],
  //   confirm_password: ['', Validators.required],
  //   channel: ['', Validators.required],
  //   region: ['', Validators.required],
  //   nickname: [''],
  //   profile_picture: ['']
  // });



  userRegistrationForm = this.fb.group({
    country: [''],

    name: ['', Validators.pattern("[^0-9<|>]+")],
    surname: ['', Validators.pattern("[^0-9<|>]+")],
    email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
    password: ['', Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")],
    confirm_password: [''],
    channel: [''],
    store: [''],
    userid: [''],
    region: [''],
    tos: ['', Validators.requiredTrue],

    nickname: ['', Validators.minLength(6)],
    profile_picture: ['']
  }, { validators: PasswordValidator.confirmed("password", "confirm_password") });

  get country() { return this.userRegistrationForm.get('country'); }
  get email() { return this.userRegistrationForm.get('email'); }
  get name() { return this.userRegistrationForm.get('name'); }
  get surname() { return this.userRegistrationForm.get('surname'); }
  get password() { return this.userRegistrationForm.get('password'); }
  get confirm_password() { return this.userRegistrationForm.get('confirm_password'); }

  get channel() {return this.userRegistrationForm.get('channel'); }
  get region() { return this.userRegistrationForm.get('region'); }
  get store() {return this.userRegistrationForm.get('store'); }
  get userid() {return this.userRegistrationForm.get('userid'); }

  get nickname() { return this.userRegistrationForm.get('nickname'); }

  nextPage(): void {
    this.currentPage++;
    this.translate.use(this.country?.value.toLowerCase());
    //this.userRegistrationForm.setErrors({ 'invalid': true });
    if (this.currentPage === 2) {
      this.getChannels();
      this.getRegions();
      this.countrysel = this.country?.value;
    }

  }

  getChannels(): void {
    this.categoryService.getchannelsAuth(this.country?.value, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.channels = data;
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getStores(): void {
    console.log(this.region?.value);

    let data;
    if(this.region?.value == "")
      data = new StoreInputAuth(this.country?.value, this.channel?.value, -1);
    else
      data = new StoreInputAuth(this.country?.value, this.channel?.value, this.region?.value);

      console.log(data);
    
    this.categoryService.GetStoresAuth(data, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.stores = data;
          this.storeSearched = this.stores;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getRegions(): void {
    this.categoryService.getregionsAuth(this.country?.value, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.regions = data;
          this.regionSearched = this.regions;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  PriPol() {
    this.popuppol = true;
    let data: PolicyInput;
    data = new PolicyInput(1, this.country?.value);
    this.categoryService.getPolicyAuth(data, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.Policy = data.body;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  BackToPage() {
    if (this.currentPage === 1)
      this.navigation.back();
    this.currentPage--;
  }

  TermsSer() {
    this.popup = true;
    let data: PolicyInput;
    data = new PolicyInput(0, this.country?.value);
    this.categoryService.getPolicyAuth(data, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.Terms = data.body;
          console.log(this.Terms);
        },
        error => {
          console.log(error);
        });
  }

  checkEmailExist(): void {
    console.log("checked");
    this.wait = true;
    this.userService.EmailExist(this.email?.value)
      .subscribe(
        data => {
          this.emailExist = data;
          this.wait = false;
          if (data) {
            this.email?.setErrors({ invalid: true });
          }
          console.log(this.emailExist);
        },
        error => {
          console.log(error);
        });
  }
  nicKNameExist(): void {
    this.wait = true;
    if (this.nickname != undefined && this.nickname?.value && this.nickname?.value != "") {
      this.userService.nicKNameExist(this.nickname?.value)
        .subscribe(
          data => {
            this.wait = false;
            this.nicknameExist = data;
            if (data == true) {
              this.nickname?.setErrors({ invalid: true });
            }
          },
          error => {
            this.nicknameExist = true;
            this.nickname?.setErrors({ invalid: true });
            console.log(error);
          });
    }
    else {
      this.nicknameExist = false;
      this.wait = false;
    }
  }


  SubmitForm(): void {
    this.wait = true;
    if (this.nickname != undefined && this.nickname?.value && this.nickname?.value != "") {
      this.userService.nicKNameExist(this.nickname?.value)
        .subscribe(
          data => {
            this.wait = false;
            if (data) {
              this.nicknameExist = data;
              this.nickname?.setErrors({ invalid: true });
            }
            else {
              this.userService.userRegister(this.email?.value, this.password?.value,
                this.confirm_password?.value, this.name?.value, this.surname?.value,
                this.country?.value, this.channel?.value, this.region?.value, this.nickname?.value,
                this.store?.value, this.userid?.value)
                .subscribe(
                  data => {
                    console.log(data);
                    if (!data.hasError) {
                      this.savePhoto();

                    }
                  },
                  error => {
                    console.log(error);
                  });
            }
          },
          error => {
            console.log(error);
          });
    }
    else {
      this.userService.userRegister(this.email?.value, this.password?.value,
        this.confirm_password?.value, this.name?.value, this.surname?.value,
        this.country?.value, this.channel?.value, this.region?.value, "",
        this.store?.value, this.userid?.value)
        .subscribe(
          data => {
            console.log(data);
            if (!data.hasError) {
              this.savePhoto();

            }
          },
          error => {
            console.log(error);
          });
    }
  }




  preview(files: any) {
    if (files.length === 0)
      return;
    var reader = new FileReader();
    this.imagePath = files.item(0);
    console.log(this.imagePath);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
  fakeButtonClick() {
    //console.log("clicked")
    if (this.myInputVariable != null || this.myInputVariable != undefined)
      this.myInputVariable.nativeElement.click();
  }

  savePhoto() {
    if (this.imagePath == null || this.imagePath == undefined) {
      this.userService.postFileDefault(this.email?.value, this.password?.value)
        .subscribe(
          data => {
            this.currentPage++;
          },
          error => {
            this.currentPage++;
          });
      return;
    }
    let file: File = this.imagePath;
    this.userService.postFile(file, this.email?.value, this.password?.value)
      .subscribe(
        data => {
          this.currentPage++;
        },
        error => {
          this.currentPage++;
        });
  }

  searchContentRegion(keyword: string){
    this.regionSearched = [];
    if (keyword == ""){
      this.regionSearched = this.regions;
    }
    else{
      this.regions.forEach((element: any) => 
          {
            if(element.region.toLowerCase().includes(keyword.toLowerCase()))
              this.regionSearched.push(element);
          });
    }
    console.log(this.regionSearched);
  }

  searchContentStore(keyword: string){
    this.storeSearched = [];
    if (keyword == ""){
      this.storeSearched = this.stores;
    }
    else{
      this.stores.forEach((element: any) => 
          {
            if(element.name.toLowerCase().includes(keyword.toLowerCase()))
              this.storeSearched.push(element);
          });
    }
    console.log(this.storeSearched);
  }

  onSubmit() {
    //todo register user
  }
}
