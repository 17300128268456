<div class="challengesContainer">
<!--BACK BUTTON-->
<div style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="container-fluid mx-auto p-0" style="max-width: 100%;">

  <!--Title-->
  <div class="row ml-0 mr-0 pl-0 pr-0">
    <h1 class="titlechallenge" style="padding-top: 10px;">{{'challenges_challenges' | translate}}</h1>
    <svg-icon class="icon" [name]="'challenge'"
      [svgStyle]="{ 'width.px': '30', 'height': 'auto', 'margin-top.px': '18', 'margin-left.px': '10' }"></svg-icon>
    <a [routerLink]="['/mainpage/challengesleaderboard']"
      style="font-weight: bold; margin-top: auto; margin-bottom: auto; text-decoration: underline; color: gray; font-size: medium; margin-left: 10px;">{{'challenges_challenges' | translate}}<br>{{'home_leaderboard' | translate}}</a>

  </div>
  <!--Tab button-->
  <mat-tab-group #TabGroup mat-align-tabs="center" (selectedTabChange)="OnChallengeTabsChange($event)" animationDuration="0ms">
    <mat-tab [label]="'challenges_available' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px"
          *ngIf="challengesAll.length > 0">
          <mat-grid-tile *ngFor="let challenge of challengesAll">
            <mat-card class="example-card" [ngClass]="{'notCompleted': !challenge.pillCompleted}">
              <mat-card-content class="position-relative">
                <img class="challenge-image" mat-card-image src="{{challenge.imageFullPath}}">
                <span class="challenge-title">{{challenge.title}}</span>
                <mat-grid-list cols="3" style="margin-top: -100px !important; opacity: 1 !important;">
                  <mat-grid-tile style="overflow: initial;" class="divBottone">
                    <button class="btn btn-outline shadow-none" style="text-transform: uppercase;"
                      (click)="goToSearchOpponent(challenge.id, challenge.pillCompleted, challenge.pillsId)">
                      {{'challenges_challenge' | translate}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(challengesAll.length > 0)" class="text-center" style="color: var(--main-text-color);">{{'menu_nochallenges' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'challenges_waiting' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px" *ngIf="waiting.length > 0">
          <mat-grid-tile *ngFor="let challenge of waiting">
            <mat-card class="example-card-base">
              <mat-card-content class="position-relative">
                <img class="challenge-image-base" mat-card-image src="{{challenge.imageFullPath}}">
                <!--<svg-icon style="cursor: pointer; position: absolute; right: 15px;" [name]="'esc-01'" [svgStyle]="{ 'width.px': '20'}" (click)="RefuseChallenge(challenge.challengeStardedId)"></svg-icon>-->
                <span class="challenge-title">{{challenge.title}}</span>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <div class="row" style="margin-left: 5px;">
                        <img class="AvatarImg" src="{{challenge.userImage}}">
                        <div style="margin-left: 10px;">
                          <span style="font-weight: bold;">{{'challenges_challenger' | translate}}</span><br>
                          <span *ngIf="challenge.userName != user">{{challenge.userNickName}}</span>
                        </div>
                      </div>
                      <div class="row mt-3" style="margin-left: 5px;">
                        <img class="AvatarImg" src="{{challenge.userOpponentImage}}">
                        <div style="margin-left: 10px;">
                          <span style="font-weight: bold;">{{'challenges_opponent' | translate}}</span><br>
                          <span *ngIf="challenge.userOpponentName != user">{{challenge.userOpponentNickName}}</span>
                        </div>
                      </div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile class="dateButton">
                    <div class="completionDate">
                      <span>{{'challenges_invitationDate' | translate}}</span><br>
                      <span style="font-weight: bold;">{{challenge.dateRequest}}</span>
                    </div>
                    <button *ngIf="challenge.state != 0" class="btn btn-outline-base shadow-none"
                      [ngClass]="{'acceptButton': challenge.state===1}"
                      (click)="GoToChallenge(challenge.challengeStardedId)">
                      {{challenge.challengeButton}}
                    </button>
                    <p class="waitingopponent" *ngIf="challenge.state === 0">{{challenge.challengeButton}}</p>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(waiting.length > 0)" class="text-center" style="color: var(--main-text-color);">{{'menu_nochallenges' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'home_completed' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px" *ngIf="completed.length > 0">
          <mat-grid-tile *ngFor="let challenge of completed">
            <mat-card class="example-card-base">
              <mat-card-content class="position-relative">
                <img class="challenge-image-base" mat-card-image src="{{challenge.imageFullPath}}">
                <span class="challenge-title">{{challenge.title}}</span>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <div class="row" style="margin-left: 5px;">
                        <img class="AvatarImg" src="{{challenge.userImage}}">
                        <div class="row" style="margin-left: 0px;">
                          <div style="margin-left: 10px;">
                            <span style="font-weight: bold;"
                              [ngClass]="{'winner': challenge.userWinner}">{{'challenges_challenger' | translate}}</span><br>
                            <span *ngIf="challenge.userName != user"
                              [ngClass]="{'winner': challenge.userWinner}">{{challenge.userNickName}}</span>
                          </div>
                          <svg-icon *ngIf="challenge.userWinner" style="margin-top: 5px;" class="icon-orange"
                            [name]="'challenge'" [svgStyle]="{ 'width.px': '40'}"></svg-icon>
                        </div>
                      </div>
                      <div class="row mt-3" style="margin-left: 5px;">
                        <img class="AvatarImg" src="{{challenge.userOpponentImage}}">
                        <div style="margin-left: 10px;">
                          <span style="font-weight: bold;" [ngClass]="{'winner': challenge.userOpponentWinner}">{{'challenges_opponent' | translate}}</span><br>
                          <span *ngIf="challenge.userOpponentName != user" [ngClass]="{'winner': challenge.userOpponentWinner}">{{challenge.userOpponentNickName}}</span>
                        </div>
                        <svg-icon *ngIf="challenge.userOpponentWinner" style="margin-top: 5px;" class="icon-orange" [name]="'challenge'" [svgStyle]="{ 'width.px': '40'}"></svg-icon>
                      </div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile class="dateButton">
                    <div class="completionDate">
                      <span>{{'challenges_completiondate' | translate}}</span><br>
                      <span style="font-weight: bold;">{{challenge.dateCompleted}}</span>
                    </div>
                    <button class="btn btn-outline-base shadow-none" [routerLink]="['/mainpage/challengeresult', challenge.challengeStardedId]">
                      {{'challenges_seemore' | translate}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(completed.length > 0)" class="text-center" style="color: var(--main-text-color);">{{'menu_nochallenges' | translate}}</h1>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>

<div class="overlay" *ngIf="sorry" style="z-index: 99; color: gray;">
  <div class="externalDiv">

    <div class="popup_complete_course" style="background-color: #F5F5F5; max-width: 90%; min-height: 300px; vertical-align: middle;">
      <!-- <a class="close" (click)="inviteSent = '2'">&times;</a> -->
      <div class="row">
        <svg-icon style="cursor: pointer; margin-left: auto; margin-right: 30px !important;" (click)="sorry = false"
          class="mt-3 mr-2" [name]="'esc'" [svgStyle]="{ 'width.px': '30'}"></svg-icon><br>
      </div>
      <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 15px;">
        <b>{{'challenges_wearesor' | translate}}</b><br> {{'challenges_cannotplay' | translate}}<br>
        {{'challenges_challengeyet' | translate}} </p>
      <p style="color: var(--main-text-color); font-size: 22px; text-align: center; display: block; margin-top: 10px;">
        {{'challenges_youneedcomplete' | translate}}</p>
      <mat-grid-tile style="overflow: initial; position: relative;">
        <button class="btn btn-outline shadow-none"
          [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', IdPill], sidebar: ['related', IdPill] } }]">
          <svg-icon [svgStyle]="{ 'width.px': '18'}"></svg-icon>
          {{'challenges_gotocourse' | translate}}
        </button>
      </mat-grid-tile>
    </div>

  </div>
</div>
<!-- popup -->
<div class="col-md-6 mx-auto mt-4" layout="row" layout-align="center center">

  <div class="overlay-challenge" *ngIf="popup" style="z-index: 400 !important;">
    <div class="popup-challenge">

      <a class="close" (click)="CloseCourse()">&times;</a>
      <!--<div [innerHTML]="Terms"></div>-->
      <iframe [src]="urlSafe"></iframe>
    </div>
  </div>
</div>
</div>