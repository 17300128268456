import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-challengescomingsoon',
  templateUrl: './challengescomingsoon.component.html',
  styleUrls: ['./challengescomingsoon.component.css']
})
export class ChallengescomingsoonComponent implements OnInit {

  constructor(public mainpage: MainPageComponent) { }

  ngOnInit() {
  }

}
