import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PolicyInput, StoreInputAuth } from 'src/app/model/ModelInput';
import { UserModel } from 'src/app/model/UserModel';
import { CategoryService } from 'src/app/services/category.service';
import { UsersService } from 'src/app/services/users.service';
import { PasswordValidator } from 'src/app/services/PasswordValidator';
import { MainPageComponent } from '../../main-page.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  popup = false;

  userToSave: any;
  @ViewChild('newFile') myInputVariable: ElementRef | undefined;
  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  hide: boolean = true;
  hide2: boolean = true;
  previewImage:boolean = false;
  nicknameExist: boolean = false;
  channels: any;
  imgURL: any
  regions: any;
  stores: any
  users: any;
  picture: any;
  Terms: any;
  timeStamp: number = 0;
  fileToUpload?: File | null = null;

  countryUser: any;

  keywordSearch: string = '';

  regionSearched: any;
  storeSearched: any;

  idStore:number = 0;
  UserIdMatricola: string = "";


  public getLinkPicture() {
    if (this.timeStamp && !this.previewImage) {
      return this.picture + '?' + this.timeStamp;
    }
    return this.picture;
  }
  public setLinkPicture(url: string) {
    this.picture = url;
    this.timeStamp = (new Date()).getTime();
  }
  public setPreviewPicture() {
    this.previewImage = true;
    this.picture = this.imgURL;
    this.timeStamp = (new Date()).getTime();
  }
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UsersService,
    private categoryService: CategoryService,
    public mainpage: MainPageComponent) {
    this.getUser();
  }

  ngOnInit() {
  }

  editUserForm = this.fb.group({
    country: [''],

    nickname: ['', Validators.minLength(6)],
    name: ['', Validators.pattern("^[a-zA-Z]+(([' ][a-zA-Z])?[a-zA-Z]*)*$")],
    surname: ['', Validators.pattern("^[a-zA-Z]+(([' ][a-zA-Z])?[a-zA-Z]*)*$")],
    email: [{ value: '', disabled: true }, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
    password: [{ value: '', disabled: true }, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")],
    confirm_password: [''],
    channel: [''],
    region: [''],
    store: [''],
    userid: [''],
    tos: [{ value: 'true', disabled: true }],

    profile_picture: ['']
  }, { validators: PasswordValidator.confirmed("password", "confirm_password") });

  get country() { return this.editUserForm.get('country'); }
  get email() { return this.editUserForm.get('email'); }
  get name() { return this.editUserForm.get('name'); }
  get surname() { return this.editUserForm.get('surname'); }
  get password() { return this.editUserForm.get('password'); }
  //get confirm_password(){return this.editUserForm.get('confirm_password');}

  get channel() { return this.editUserForm.get('channel'); }
  get region() { return this.editUserForm.get('region'); }

  get store() {return this.editUserForm.get('store'); }
  get userid() {return this.editUserForm.get('userid'); }


  get nickname() { return this.editUserForm.get('nickname'); }

  //navigation data



  getChannels(country: string): void { //da aggiungere email e password da localstorage
    this.categoryService.getchannelsAuth(country, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.channels = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getRegions(country: string): void { //da aggiungere email e password da localstorage
    this.categoryService.getregionsAuth(country, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.regions = data;
          this.regionSearched = this.regions;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getStores(): void {
    console.log(this.region?.value);

    let data;
    if(this.region?.value == "")
      data = new StoreInputAuth(this.countryUser, this.channel?.value, -1);
    else
      data = new StoreInputAuth(this.countryUser, this.channel?.value, this.region?.value);

      console.log(data);
    
    this.categoryService.GetStoresAuth(data, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.stores = data;
          this.storeSearched = this.stores;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getStoresChannel(country:string, channel: number): void {
    console.log(channel);

    let data;
    if(this.region?.value == "")
      data = new StoreInputAuth(country, channel, -1);
    else
      data = new StoreInputAuth(country, channel, this.region?.value);

      console.log(data);
    
    this.categoryService.GetStoresAuth(data, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.stores = data;
          this.storeSearched = this.stores;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  setDataForm() {
    let dataForm = this.editUserForm.setValue({
      country: this.users.country,
      nickname: this.users.nickName,
      name: this.users.name,
      surname: this.users.surname,
      email: this.users.email,
      password: '',
      confirm_password: '',
      channel: this.users.idChannel,
      region: this.users.idRegion,
      store: Number(this.users.idStore),
      userid: this.users.idUserStore,
      tos: 'true',
      profile_picture: this.categoryService.baseURLimg + this.users.imageFullPath
    });
    console.log(this.users.idRegion);
    console.log(this.users.idStore);
    return dataForm;
  };


  reset() {
    if (this.myInputVariable != null || this.myInputVariable != undefined)
      this.myInputVariable.nativeElement.value = '';
    this.fileToUpload = null;
  }

  getUser(): void {
    this.categoryService.getUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.users = data;
          this.countryUser = this.users.country;
          console.log(data);
          this.setLinkPicture(this.categoryService.baseURLimg + this.users.imageFullPath);
          this.setDataForm();
          this.getChannels(this.users.country);
          this.getRegions(this.users.country);
          this.getStoresChannel(this.users.country, this.users.idChannel);
          
          //this.getStores();
        },
        error => {
          console.log(error);
        });

  }

  getDataUser() {
    let userToSave: UserModel;
    userToSave = new UserModel(
      this.users.id,
      this.users.email,
      this.editUserForm.get('surname')?.value,
      this.editUserForm.get('name')?.value,
      this.editUserForm.get('region')?.value,
      this.editUserForm.get('channel')?.value,
      this.users.region,
      this.users.channel,
      this.users.emailConfirmed,
      this.users.active,
      this.users.imageFullPath,
      this.editUserForm.get('nickname')?.value,
      this.users.country,
      this.users.status,
      this.users.dateRegistration,
      this.users.dateLastLogin,
      this.users.isBanned,
      this.users.point,
      this.users.role,
      this.users.roleName,
      this.users.pointConsumed,
      this.idStore,
      this.UserIdMatricola,
    );
    return userToSave;
  }

  nicKNameExist(): void {
    if (this.nickname != undefined && this.nickname?.value && this.nickname?.value != "") {
      this.userService.nicKNameExist(this.nickname?.value)
        .subscribe(
          data => {
            this.nicknameExist = data;
            if (data == true) {
              this.nickname?.setErrors({ invalid: true });
            }
          },
          error => {
            this.nicknameExist = true;
            this.nickname?.setErrors({ invalid: true });
            console.log(error);
          });
    }
    else {
      this.nicknameExist = false;
    }
    console.warn('nick already exsits?');
    console.warn(this.nicknameExist);
  }

  saveUser(): void {
    this.stores.forEach((element:any) => {
      if(element.id == this.editUserForm.get('store')?.value){
        this.idStore = this.editUserForm.get('store')?.value;
        this.UserIdMatricola = this.editUserForm.get('userid')?.value;
      }     
    });

    this.nicKNameExist();

    if(!this.nicknameExist){
      this.categoryService.saveUserAuth(this.getDataUser(), this.userEmail, this.pass)
      .subscribe(
        data => {
          //this.regions = data;
          console.log(data);
          this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
        },
        error => {
          console.log(error);
        });
    }
  }

  TermsSer() {
    this.popup = true;
    let data: PolicyInput;
    data = new PolicyInput(0, this.country?.value);
    this.categoryService.getPolicyAuth(data, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.Terms = data.body;
          //console.log(this.Terms);
        },
        error => {
          console.log(error);
        });
  }

  handleFileInput(target: any) {
    if (target != null && target.files != null) {
      {
        this.fileToUpload = target.files.item(0);
        var reader = new FileReader();
        reader.readAsDataURL(target.files.item(0));
        reader.onload = (_event) => {
          this.imgURL = reader.result;
          this.setPreviewPicture();
        }
      }

    }
  }

  savePhoto() {
    if (this.fileToUpload == null || this.fileToUpload == undefined)
      return;
    let file: File = this.fileToUpload;
    this.userService.postFile(file, this.userEmail, this.pass)
      .subscribe(
        data => {
          console.log(data);
          this.previewImage = false;
          this.reset();
          this.getUser();
        },
        error => {
          console.log(error);
        });
  }
  fakeButtonClick() {
    //console.log("clicked")
    if (this.myInputVariable != null || this.myInputVariable != undefined)
      this.myInputVariable.nativeElement.click();
  }

  searchContentRegion(keyword: string){
    this.regionSearched = [];
    if (keyword == ""){
      this.regionSearched = this.regions;
    }
    else{
      this.regions.forEach((element: any) => 
          {
            if(element.region.toLowerCase().includes(keyword.toLowerCase()))
              this.regionSearched.push(element);
          });
    }
    console.log(this.regionSearched);
  }

  searchContentStore(keyword: string){
    this.storeSearched = [];
    if (keyword == ""){
      this.storeSearched = this.stores;
    }
    else{
      this.stores.forEach((element: any) => 
          {
            if(element.name.toLowerCase().includes(keyword.toLowerCase()))
              this.storeSearched.push(element);
          });
    }
    console.log(this.storeSearched);
  }

  goToDelete(){
    this.router.navigateByUrl('/mainpage/(sureToDelete//sidebar:news)');
  }

  onSubmit() {
    //todo register user
  }

}
