<img src="../../../assets/images/logo_grande.png" style="width: 440px; margin-top:82px;margin-left: auto; margin-right: auto;" class="p-3 center mb-4">
<div style="max-width: 450px;">
  <p class="center mb-5" style="text-align: center;">{{'registration_boostyourexpertisean' | translate}}</p>

  <xiaomi-button (click)="GoToLogin()"
      class="center mb-4">
      {{'Buttons_login' | translate}}
    </xiaomi-button>
    <xiaomi-button (click)="GoToSignUp()"
      class="center">
      {{'Buttons_signup' | translate}}
    </xiaomi-button>
</div>
<label class="mt-5 mb-5 center" style="text-align: center;">{{'registration_newtoMiAcademySignUp' | translate}}
  <span [routerLink]="['/signup']" routerLinkActive="router-link-active" style="cursor: pointer;">
    <u><b class="ml-1"> {{'Buttons_signup' | translate}}</b></u>
  </span>
</label>
<xiaomi-imprint></xiaomi-imprint>


