 <!--BACK BUTTON-->
 <div style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="container-fluid mx-auto p-0" style="max-width: 850px;">

  <!--Title & points-->
  <div class="row ml-0 mr-0 pl-0 pr-0 title">
    <h1 class="col-sm-12" style="padding-top: 10px;">{{'home_bookmarks' | translate}}</h1>
  </div>
  <!--Tab button-->
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="OnPillTabsChange($event)" animationDuration="0ms">
    <mat-tab [label]="'bookmarks_all' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px"
          *ngIf="bookmarksAll.length > 0">
          <mat-grid-tile *ngFor="let pill of bookmarksAll">
            <mat-card class="example-card">
              <mat-card-content class="position-relative">
                <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
                <span class="pill-title">{{pill.title}}</span>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <p class="mb-0">{{pill.subFamilyName}}</p>
                      <div class="line-clamp"> {{pill.abstractBox}}</div>
                    </div>
                  </mat-grid-tile>
                  <!-- <mat-grid-tile style="overflow: initial;">
                    <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.isCompleted === true && pill?.iD_Type == 0"
                            [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', pill.id], sidebar: ['related', pill.id] } }]">
       
                            <svg-icon *ngIf="pill?.isCompleted === true && pill?.iD_Type == 0 "
                                      [name]="'repeat_arrow'" [svgStyle]="{ 'width.px': '18'}">
                            </svg-icon>
                          
                            {{pill.buttonText}}
                   </button>
                  </mat-grid-tile> -->
                  <mat-grid-tile style="overflow: initial;">
                    <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                      [routerLink]="['/mainpage/pilldetail', pill.id]">
                      <svg-icon *ngIf="pill?.startText == 'REPEAT'"
                      [name]="'repeat_arrow'" [svgStyle]="{ 'width.px': '18'}">
                      </svg-icon>
                      {{pill.startText}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(bookmarksAll.length > 0)" class="text-center mt-2" style="color: var(--main-text-color);">{{'bookmarks_nobookmarks' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'bookmarks_ongoing' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px" *ngIf="onGoing.length > 0">
          <mat-grid-tile *ngFor="let pill of onGoing">
            <mat-card class="example-card">
              <mat-card-content class="position-relative">
                <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
                <span class="pill-title">{{pill.title}}</span>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <p class="mb-0">{{pill.subFamilyName}}</p>
                      <div class="line-clamp"> {{pill.abstractBox}}</div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile style="overflow: initial;">
                    <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                      [routerLink]="['/mainpage/pilldetail', pill.id]">
                      {{pill.startText}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(onGoing.length > 0)" class="text-center mt-2" style="color: var(--main-text-color);">{{'bookmarks_nobookmarks' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'bookmarks_completed' | translate | uppercase">
      <ng-template matTabContent>
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px" *ngIf="completed.length > 0">
          <mat-grid-tile *ngFor="let pill of completed">
            <mat-card class="example-card">
              <mat-card-content class="position-relative">
                <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
                <span class="pill-title">{{pill.title}}</span>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <p class="mb-0">{{pill.subFamilyName}}</p>
                      <div class="line-clamp"> {{pill.abstractBox}}</div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile style="overflow: initial;">
                    <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                      [routerLink]="['/mainpage/pilldetail', pill.id]">
                      {{pill.startText}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="!(completed.length > 0)" class="text-center mt-2" style="color: var(--main-text-color);">{{'bookmarks_nobookmarks' | translate}}</h1>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>