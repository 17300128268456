import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChannelInput, CheckChallengeWinnerInput, CheckRateModel, DiscussionVote, InputAppVersion, PolicyInput, RequestCatalogue, SearchDiscussion, UserClassRoomRequestInput, UserCommentInput, UserPillInput, UserSendRateInput, StartChallengeInput, UserTokenInput, DiscussionAdd, ResponseDiscussionAdd, ChallengeLimitInput, UserLeaderBoardChallengeLimitInput, UserToInviteLimitInput, StoreInputAuth, LanguageInput } from '../model/ModelInput';
import { UserModel } from '../model/UserModel';
import { PasswordModel } from '../model/PasswordModel';
import { CommentsModel } from '../model/CommentsModel';


//EVEREST
//let authorizationData = 'Basic ' + btoa("usertest@ulisse1984.it" + ':' + "P@ssword0");

//PRE-PROD
let authorizationData = 'Basic ' + btoa("giuseppe.cirino@ulisse1984.it" + ':' + "P@ssword0");

/*PROD*/
// let authorizationData = 'Basic ' + btoa("usertest@ulisse1984.it" + ':' + "P@ssword0");

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  //EVEREST
   public baseURL = 'http://151.11.49.215:8090/api/Catalog/';
   public baseURLimg = 'http://151.11.49.215:8090';
  
  //PRE-PROD
  // public baseURL = 'https://xiaomitrainingapp.azurewebsites.net/api/Catalog/';
  // public baseURLimg = 'https://xiaomitrainingapp.azurewebsites.net/';

  /*PROD*/
  // public baseURL = 'https://xiaomiacademy.azurewebsites.net/api/Catalog/';
  // public baseURLimg = 'https://xiaomiacademy.azurewebsites.net';
  constructor(private httpClient: HttpClient) { }

  readAll(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getcategoriesAuth', body, options);
  }

  GetLanguagesAuth(data:LanguageInput, email: string, password: string){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Date": data.Date,
      "Country": data.Country
    };
    return this.httpClient.post(this.baseURL + '/GetLanguagesAuth', body, options);
  }

  GetPillsAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};

    return this.httpClient.post(this.baseURL + '/GetPillsAuth', body, options);
    ;
  }

  getPillsSearchAuth(email: string, password: string, keyword: string | null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Text": keyword
    };

    return this.httpClient.post(this.baseURL + '/GetPillsSearchAuth', body, options);
  }

  DiscussionVoteUserAuth(IdDiscussion: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdDiscussion": IdDiscussion
    };
    return this.httpClient.post(this.baseURL + '/DiscussionVoteUserAuth', body, options);
  }

  addFavoriteToUserAuth(IdPill: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": IdPill
    };
    return this.httpClient.post(this.baseURL + '/addFavoriteToUserAuth', body, options);
  }

  addpopupviewed(PopUp_id: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "PopUp_id": PopUp_id
    };
    return this.httpClient.post(this.baseURL + '/addpopupviewed', body, options);
  }

  CatalogueUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/CatalogueUserAuth', body, options);
  }

  CheckAppVersion(data: InputAppVersion, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "CurrentVersion": data.CurrentVersion,
      "CurrentBuild": data.CurrentBuild
    };
    return this.httpClient.post(this.baseURL + '/CheckAppVersion', body, options);
  }

  /*WARNING*/
  CheckChallengeWinner(IdChallengeStarted: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdChallengeStarted": IdChallengeStarted
    };
    return this.httpClient.post(this.baseURL + '/CheckChallengeWinner', body, options);
  }

  CheckPrivacyPolicyVersion(data: UserModel, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Id": data.Id,
      "Email": data.Email,
      "Surname": data.Surname,
      "Name": data.Name,
      "IdRegion": data.IdRegion,
      "IdChannel": data.IdChannel,
      "Region": data.Region,
      "Channel": data.Channel,
      "EmailConfirmed": data.EmailConfirmed,
      "Active": data.Active,
      "ImageFullPath": data.ImageFullPath,
      "NickName": data.NickName,
      "Country": data.Country,
      "Status": data.Status,
      "DateRegistration": data.DateRegistration,
      "DateLastLogin": data.DateLastLogin,
      "IsBanned": data.IsBanned,
      "Point": data.Point,
      "Role": data.Role,
      "RoleName": data.RoleName,
      "PointConsumed": data.PointConsumed
    };
    return this.httpClient.post(this.baseURL + '/CheckPrivacyPolicyVersion', body, options);
  }

  /*WARNING*/
  checkRatingPill(email: string, password: string, id_pill: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Email": email,
      "IdPill": id_pill
    };
    return this.httpClient.post(this.baseURL + '/checkRatingPill', body, options);
  }

  /*NON USATA*/
  CountChallengeCompleted(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authorizationData
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/CountChallengeCompleted', body, options);
  }

  GetChallengesLimitAuth(data: ChallengeLimitInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": data.Start,
      "Count": data.Count,
      "Order": data.Order
    };
    return this.httpClient.post(this.baseURL + '/getchallengesLimitAuth', body, options);
  }

  GetSingleChallengeAuth(idChallenge: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdChallange": idChallenge
    };
    return this.httpClient.post(this.baseURL + '/getchallengeSingleAuth', body, options);
  }

  GetUserchallengesOnGoingLimitAuth(data: ChallengeLimitInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": data.Start,
      "Count": data.Count,
      "Order": data.Order
    };
    return this.httpClient.post(this.baseURL + '/getUserchallengesOnGoingLimitAuth', body, options);
  }

  GetUserChallengesCompletedLimitAuth(data: ChallengeLimitInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": data.Start,
      "Count": data.Count,
      "Order": data.Order
    };
    return this.httpClient.post(this.baseURL + '/getUserchallengesCompletedLimitAuth', body, options);
  }

  GetChallengeLeaderboard( email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    return this.httpClient.post(this.baseURL + '/LeaderBoardChallengeUserAuth', "", options);
  }

  UsersLeaderBoardChallengeUserLimitAuth(data: UserLeaderBoardChallengeLimitInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": data.Start,
      "Count": data.Count,
      "Position": data.Position,
      "Victory": data.Victory,
      "NumeroUtenti": data.NumeroUtenti
    };
    return this.httpClient.post(this.baseURL + '/UsersLeaderBoardChallengeUserLimitAuth', body, options);
  }

  RefuseChallenge(ChallengeStartedid: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "ChallengeId": ChallengeStartedid
    };
    return this.httpClient.post(this.baseURL + '/refuseChallenge', body, options);
  }

  GetUserToInviteLimitAuth(data: UserToInviteLimitInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": data.Start,
      "Count": data.Count,
      "IdPill": data.IdPill,
      "IdChannel": data.IdChannel,
      "Search": data.Search,
      "SearchChannel": data.SearchChannel,
      "SearchRegion": data.SearchRegion
    };
    return this.httpClient.post(this.baseURL + '/getUserToInviteLimitAuth', body, options);
  }

  CreateDiscussionUserAuth(data: DiscussionAdd, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Title": data.Title,
      "Text": data.Text
    };
    return this.httpClient.post(this.baseURL + '/CreateDiscussionUserAuth', body, options);
  }

  CreateResponseDiscussionUserAuth(data: ResponseDiscussionAdd, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdRefer": data.IdRefer,
      "Text": data.Text
    };
    return this.httpClient.post(this.baseURL + '/CreateResponseDiscussionUserAuth', body, options);
  }

  deleteAllNotificheUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/deleteAllNotificheUserAuth', body, options);
  }

  deleteNotificaUserAuth(idNotifica: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdNotifica": idNotifica
    };
    return this.httpClient.post(this.baseURL + '/deleteNotificaUserAuth', body, options);
  }

  setNotificaAsSeen(idNotifica: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdNotifica": idNotifica
    };
    return this.httpClient.post(this.baseURL + '/setNotificaAsSeen', body, options);
  }

  /*WARNING*/
  ExistEmailAuth(em: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Email": em
    };
    return this.httpClient.post(this.baseURL + '/ExistEmailAuth', body, options);
  }

  getbannersAuth(country: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Country": country
    };
    return this.httpClient.post(this.baseURL + '/getbannersAuth', body, options);
  }

  getalldiscussionAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getalldiscussionAuth', body, options);
  }

  /*NON USATA*/
  getcategoriesAuth2(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authorizationData
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getcategoriesAuth2', body, options);
  }

  getchallengesAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getchallengesAuth', body, options);
  }

  getChallengesSearchAuth(email: string, password: string, keyword: string | null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Text": keyword
    };

    return this.httpClient.post(this.baseURL + '/getchallengesSearchAuth', body, options);
  }


  GetChallengeWaiting(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/GetChallengeWaiting', body, options);
  }

  /*NON USATA*/
  getchannels(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authorizationData
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getchannels', body, options);
  }

  getchannelsAuth(country: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Country": country
    };
    return this.httpClient.post(this.baseURL + '/getchannelsAuth', body, options);
  }

  GetStoresAuth(data: StoreInputAuth, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Country": data.Country,
      "IdChannel": data.IdChannel,
      "IdRegion": data.IdRegion
    };
    return this.httpClient.post(this.baseURL + '/getstoresAuth', body, options);
  }


  getCommentiPills(email: string, password: string, idPill: number): Observable<CommentsModel[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill,
      "Email": email,
      "Comment": ""
    };
    return this.httpClient.post<CommentsModel[]>(this.baseURL + '/getCommentiPills', body, options);
  }

  /*NON FUNZIONA*//*usata come getregionsAuth su webapi.cs in XiaomiApp*/
  getcountriesAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getcountriesAuth', body, options);
  }

  getfamiliesAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getfamiliesAuth', body, options);
  }

  getnewsAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getnewsAuth', body, options);
  }

  getNewsSearchAuth(email: string, password: string, keyword: string | null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Text": keyword
    };

    return this.httpClient.post(this.baseURL + '/getNewsSearchAuth', body, options);
  }

  getnNews(NtoTake: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "NtoTake": NtoTake
    };
    return this.httpClient.post(this.baseURL + 'getnNews', body, options);
  }

  GetNewsSearchBarcode(barcode: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Barcode": barcode
    };
    return this.httpClient.post(this.baseURL + '/GetNewsSearchBarcode', body, options);
  }

  getNotificheUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getNotificheUserAuth', body, options);
  }

  /**
   * GetPillsLimitAuth
   * type of order
   * "" --> by position asc
   * "popular" --> by views asc
   * "ongoing" --> only ongoing pills by by position asc
   * "foryou" --> only favorite pills by by position asc
   */
  GetPillsLimitAuth(skipNPills: number, getNPills: number, order: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": skipNPills,
      "Count": getNPills,
      "Order": order
    };
    return this.httpClient.post(this.baseURL + '/GetPillsLimitAuth', body, options);
  }
  /**
   * GetPillsLimitAuthWithFilter
   * type of order
   * "" --> by position asc
   * "popular" --> by views asc
   * "ongoing" --> only ongoing pills by by position asc
   * "foryou" --> only favorite pills by by position asc
   */
  GetPillsLimitAuthWithFilter(skipNPills: number, getNPills: number, IdCategory: number, IdFamily: number,
    IdSubfamily: number, order: string, email: string, password: string):
    Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": skipNPills,
      "Count": getNPills,
      "IdCategory": IdCategory,
      "IdFamily": IdFamily,
      "IdSubFamily": IdSubfamily,
      "Order": order
    };
    return this.httpClient.post(this.baseURL + '/GetPillsLimitAuth', body, options);
  }

  /**
   * GetPillsLimitAuthWithFilter
   * type of order
   * "" --> by position asc
   * "popular" --> by views asc
   * "ongoing" --> only ongoing pills by by position asc
   * "foryou" --> only favorite pills by by position asc
   */
  GetPillsLimitAuthWithOnlyCatAndFam(skipNPills: number, getNPills: number, IdCategory: number, IdFamily: number,
    order: string, email: string, password: string):
    Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Start": skipNPills,
      "Count": getNPills,
      "IdCategory": IdCategory,
      "IdFamily": IdFamily,
      "Order": order
    };
    return this.httpClient.post(this.baseURL + '/GetPillsLimitAuth', body, options);
  }
  GetPillsSearchBarcode(barcode: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Barcode": barcode
    };
    return this.httpClient.post(this.baseURL + '/GetPillsSearchBarcode', body, options);
  }

  /*WARNING*/
  getPolicyAuth(data: PolicyInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "PolicyType": data.PolicyType,
      "Country": data.Country
    };
    return this.httpClient.post(this.baseURL + '/getPolicyAuth', body, options);
  }

  getpopup(country: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "CountryPrefix": country
    };
    return this.httpClient.post(this.baseURL + '/getpopup', body, options);
  }

  /*WARNING*/
  getRatingPill(idPill: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill
    };
    return this.httpClient.post(this.baseURL + '/getRatingPill', body, options);
  }

  /*NON USATA*/
  getregions(country: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Country": country
    };
    return this.httpClient.post(this.baseURL + '/getregions', body, options);
  }

  getregionsAuth(country: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Country": country
    };
    return this.httpClient.post(this.baseURL + '/getregionsAuth', body, options);
  }

  /*WARNING*/
  GetSinglePillsAuth(id: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Id": id
    };
    return this.httpClient.post(this.baseURL + '/GetSinglePillsAuth', body, options);
  }

  getslidersAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getslidersAuth', body, options);
  }

  getsubfamiliesAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getsubfamiliesAuth', body, options);
  }

  getSurveysAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getSurveysAuth', body, options);
  }

  getSingleSurveysAuth(SurveyId: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "SurveyId": SurveyId
    };
    return this.httpClient.post(this.baseURL + '/getSingleSurveysAuth', body, options);
  }

  /*WARNING*/
  getUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getUserAuth', body, options);
  }

  /*WARNING*/
  GetUsersChallenge(ChallengeId: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "ChallengeId": ChallengeId
    };
    return this.httpClient.post(this.baseURL + '/GetUsersChallenge', body, options);
  }

  /*WARNING*/
  LeaderBoardUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/LeaderBoardUserAuth', body, options);
  }

  pillCounter(idPill: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill
    };
    return this.httpClient.post(this.baseURL + '/pillCounter', body, options);
  }

  removeFavoriteToUserAuth(idPill: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill
    };
    return this.httpClient.post(this.baseURL + '/removeFavoriteToUserAuth', body, options);
  }

  /*NON FUNZIONA*/
  RequestCatalogueUserAuth(IdCatalogo: number, Point: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdCatalogo": IdCatalogo,
      "Point": Point
    };
    return this.httpClient.post(this.baseURL + '/RequestCatalogueUserAuth', body, options);
  }

  saveUserAuth(data: UserModel, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Id": data.Id,
      "Email": data.Email,
      "Surname": data.Surname,
      "Name": data.Name,
      "IdRegion": data.IdRegion,
      "IdChannel": data.IdChannel,
      "Region": data.Region,
      "Channel": data.Channel,
      "EmailConfirmed": data.EmailConfirmed,
      "Active": data.Active,
      "ImageFullPath": data.ImageFullPath,
      "NickName": data.NickName,
      "Country": data.Country,
      "Status": data.Status,
      "DateRegistration": data.DateRegistration,
      "DateLastLogin": data.DateLastLogin,
      "IsBanned": data.IsBanned,
      "Point": data.Point,
      "Role": data.Role,
      "RoleName": data.RoleName,
      "PointConsumed": data.PointConsumed,
      "IdStore": data.IdStore,
      "IdUserStore": data.UserId
    };
    return this.httpClient.post(this.baseURL + '/saveUserAuth', body, options);
  }

  saveUserPasswordAuth(data: PasswordModel, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "CurrentPassword": data.CurrentPassword,
      "NewPassword": data.NewPassword
    };
    return this.httpClient.post(this.baseURL + '/saveUserPasswordAuth', body, options);
  }

  SearchDiscussionResponseUserAuth(idRefer: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdRefer": idRefer
    };
    return this.httpClient.post(this.baseURL + '/SearchDiscussionResponseUserAuth', body, options);
  }

  /*NON FUNZIONA*/
  SearchDiscussionUserAuth(data: SearchDiscussion, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Search": data.Search,
      "Order": data.Order,
      "Take": data.Take,
      "Page": data.Page
    };
    return this.httpClient.post(this.baseURL + '/SearchDiscussionUserAuth', body, options);
  }

  /*NON FUNZIONA*/
  sendClassRoomRequest(data: UserClassRoomRequestInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdClassRoomEvents": data.IdClassRoomEvents,
      "Email": data.Email
    };
    return this.httpClient.post(this.baseURL + '/sendClassRoomRequest', body, options);
  }

  sendComment(idPill: number, comment: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill,
      "Comment": comment,
      "Email": email
    };
    return this.httpClient.post(this.baseURL + '/sendComment', body, options);
  }

  sendLike(id: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": id, // il nome è fuorviante intende l'id del commento
      "Comment": "",
      "Email": email
    };
    return this.httpClient.post(this.baseURL + '/sendLike', body, options);
  }

  sendRatingPill(idPill: number, rate: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPill": idPill,
      "Email": email,
      "Rate": rate
    };
    return this.httpClient.post(this.baseURL + '/sendRatingPill', body, options);
  }

  SetCourseComplete(id: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Id": id
    };
    return this.httpClient.post(this.baseURL + '/SetCourseComplete', body, options);
  }

  SetPolicy(id: string, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdPolicy": id
    };
    return this.httpClient.post(this.baseURL + '/SetPolicy', body, options);
  }

  StartChallenge(data: StartChallengeInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdChallenge": data.IdChallenge,
      "UserIdChallenged": data.UserIdChallenged
    };
    return this.httpClient.post(this.baseURL + '/StartChallenge', body, options);
  }

  UpdateUserTokenAuth(data: UserTokenInput, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Token": data.Token,
      "Android": data.Android,
      "Ios": data.Ios,
      "Email": data.Email
    };
    return this.httpClient.post(this.baseURL + '/UpdateUserTokenAuth', body, options);
  }

  UsersCertificateUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/UsersCertificateUserAuth', body, options);
  }

  GetCertificate(IdPill: number, email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "Id": IdPill
    };
    return this.httpClient.post(this.baseURL + '/getcertificates', body, options);
  }

  UsersLeaderBoardUserAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/UsersLeaderBoardUserAuth', body, options);
  }

  getCruscotti(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/getCruscotti', body, options);
  }

  GetUserCourseDataAuth(email: string, password: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {};
    return this.httpClient.post(this.baseURL + '/GetUserCourseData', body, options);
  }

  GetCorrelati(PillId: number, getNPills: number, email: string, password: string):
    Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "PillId": PillId,
      "nToTake": getNPills
    };
    return this.httpClient.post(this.baseURL + '/GetCorrelati', body, options);
  }


  AcceptChallenge(IdChallengeStarted: number, email: string, password: string):
    Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Basic ' + this.utoa(email + ':' + password)
      })
    };
    const body = {
      "IdChallengeStarted": IdChallengeStarted
    };
    return this.httpClient.post(this.baseURL + '/ChallengeAccept', body, options);
  }

  // base64 encoding using utf-8 character set
  utoa(str: string) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  
}


