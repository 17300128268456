import { Byte } from "@angular/compiler/src/util";
import { ChannelModel } from "./ChannelModel";
import { UserModel } from "./UserModel";

  export class PillInput {

    constructor(
      public CountryPrefix: string,
      public Search: string
    ) {  }
  
  }
  export class PillLimitInput {

    constructor(
      public Start: number,
      public Count: number,
      public IdCategory: number,
      public IdFamily: number,
      public IdSubFamily: string
    ) {  }
  
  }
  export class SliderInput {

    constructor(
      public CountryPrefix: string
    ) {  }
  
  }
  export class BannerInput {

    constructor(
      public CountryPrefix: string
    ) {  }
  
  }
  export class ChannelInput {

    constructor(
      public CountryPrefix: string
    ) {  }
  
  }
  export class PopUpInput {

    constructor(
      public CountryPrefix: string
    ) {  }
  
  }
  export class PillInputAuth {

    constructor(
      public Country: string
    ) {  }
  
  }
  export class SliderInputAuth {

    constructor(
      public Country: string
    ) {  }
  
  }
  export class ChannelInputAuth {

    constructor(
      public Country: string
    ) {  }
  
  }
  export class RegionInputAuth {

    constructor(
      public Country: string
    ) {  }
  
  }
  export class NickNameInput {

    constructor(
      public NickName: string
    ) {  }
  
  }
  export class PillSingleInput {

    constructor(
      public Id: number
    ) {  }
  
  }
  export class PopUpViewedInput {

    constructor(
      public PopUp_id: number
    ) {  }
  
  }
  export class UserPhotoInput {

    constructor(
      public Email: string,
      public Photo: Byte,
      public Ext: string
    ) {  }
  
  }
  export class UserPillInput {

    constructor(
      public IdPill: number
    ) {  }
  
  }
  export class UserSendRateInput {

    constructor(
      public IdPill: number,
      public Email: string,
      public Rate: number
    ) {  }
  
  }
  export class CheckRateModel {

    constructor(
      public Email: string,
      public IdPill: number
    ) {  }
  
  }
  export class UserCommentInput {

    constructor(
      public IdPill: number,
      public Comment: string,
      public Email: string
    ) {  }
  
  }
  export class UserClassRoomRequestInput {

    constructor(
      public IdClassRoomEvents: number,
      public Email: string
    ) {  }
  
  }
  export class UserTokenInput {

    constructor(
      public Token: string,
      public Android: boolean,
      public Ios: boolean,
      public Email: string
    ) {  }
  
  }
  export class ScormInput {

    constructor(
      public Id: number
    ) {  }
  
  }
  export class PolicyInput {

    constructor(
      public PolicyType: number,
      public Country: string
    ) {  }
  
  }
  export class NotificheInput {

    constructor(
      public IdNotifica: number
    ) {  }
  
  }
  export class DiscussionAdd {

    constructor(
      public Title: string,
      public Text: string
    ) {  }
  
  }
  export class ResponseDiscussionAdd {

    constructor(
      public IdRefer: number,
      public Text: string
    ) {  }
  
  }
  export class DiscussionVote {

    constructor(
      public IdDiscussion: number
    ) {  }
  
  }
  export class SearchDiscussionResponse {

    constructor(
      public IdRefer: number
    ) {  }
  
  }
  export class SearchDiscussion {

    constructor(
      public Search: string,
      public Order: string,
      public Take: number,
      public Page: number
    ) {  }
  
  }
  export class RequestCatalogue {

    constructor(
      public IdCatalogo: number,
      public Point: number
    ) {  }
  
  }
  export class InputExistMail {

    constructor(
      public Email: string
    ) {  }
  
  }
  export class InputBarcode {

    constructor(
      public Barcode: string
    ) {  }
  
  }
  export class InputAppVersion {

    constructor(
      public CurrentVersion: string,
      public CurrentBuild: string
    ) {  }
  
  }
  export class GetUsersChallengeInput {

    constructor(
      public ChallengeId: number
    ) {  }
  
  }
  export class CountChallengeCompletedInput {

    constructor(
      public UserId: string
    ) {  }
  
  }
  export class CheckChallengeWinnerInput {

    constructor(
      public IdChallengeStarted: number
    ) {  }
  
  }
  export class SetPolicyInput {

    constructor(
      public IdPolicy: string
    ) {  }
  
  }
  export class CheckChallengeWinnerOutput {

    constructor(
      public idWinner: string,
      public user: UserModel,
      public userChallenged: UserModel,
      public finished: boolean,
      public idWaiting: string
    ) {  }
  
  }
  export class StartChallengeInput {

    constructor(
      public IdChallenge: number,
      public UserIdChallenged: string
    ) {  }
  
  }
  export class GetUsersChallengeOutput {

    constructor(
      public Channel: ChannelModel,
      public User: UserModel,
      public PersonlData: UserModel
    ) {  }
  
  }

  export class ChallengeLimitInput
  {
    constructor(
      public Start: number,
      public Count: number,
      public Order: string
    ) { }
  }

  export class UserLeaderBoardChallengeLimitInput
  {
    constructor(
      public Start: number,
      public Count: number,
      public Position: number,
      public Victory: number,
      public NumeroUtenti: number
    ) { }
  }
  export class UserToInviteLimitInput
  {
    constructor(
      public Start: number,
      public Count: number,
      public IdPill: number,
      public IdChannel: number,
      public Search: string,
      public SearchChannel: boolean,
      public SearchRegion: boolean
    ) { }
  }
  export class StoreInputAuth
  {
    constructor(
      public Country: string,
      public IdChannel: number,
      public IdRegion: number,

    ) { }
  }
  export class LanguageInput
  {
    constructor(
      public Date: Date,
      public Country: string,

    ) { }
  }

