import { Router } from '@angular/router';
import { LeaderBoardModel } from 'src/app/model/LeaderBoardModel';
import { PillModel } from '../../../model/PillModel';
import { FamiliesModel } from '../../../model/FamiliesModel';
import { async } from '@angular/core/testing';
import { Component, ContentChildren, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { TranslateService } from '@ngx-translate/core';
import { MainPageComponent } from '../main-page.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
 
})
export class HomepageComponent implements OnInit {


  popup: boolean = false;
  surveyLink: string | undefined;
  IdPill: any;
  sorry: boolean = false; //mostra popup pill non completata x challenge

  @ViewChildren('fams') fam_filterBtns!: QueryList<MatButtonToggle>;
  @ViewChildren('subfams') subfam_filterBtns!: QueryList<MatButtonToggle>;

  setSelectedFamily(idFamToExclude: number) {
    this.selectedFamily = 
      (idFamToExclude != this.selectedFamily) ? idFamToExclude : -1;
    this.fam_filterBtns.forEach((div: MatButtonToggle) =>
    {
      if(div['value'] != idFamToExclude && idFamToExclude!= -1)
        div.checked = false;
    });
  }

  setSelectedSubFamily(idSubFam: number) {
    this.selectedSubFamily = 
      (idSubFam != this.selectedSubFamily) ? idSubFam : -1;
    this.subfam_filterBtns.forEach((div: MatButtonToggle) =>
    {
      if(div['value'] != idSubFam && idSubFam!= -1)
        div.checked = false;
    });
  }

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  categories: any;
  selectedCategory: number = -1;
  categoryCourseCounter: any;

  families!: any;
  filtered_families!: any;
  selectedFamily: number = -1;

  subfamilies!: any;
  filtered_subfamilies!: any;
  selectedSubFamily: number = -1;

  pills!: any;
  filtered_pills!: any;
  pills_popular!: any;
  pills_foryou!: any;
  pills_ongoing!: any;

  sliders: any;
  leaderboard!: any;
  cruscotto: any;

  isOngoing: boolean = false;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoWidth: true,
    center: false,
    items: 1,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
  };

  activeSlides!: SlidesOutputData;
  isDragging!: boolean;


  constructor(
    private categoryService: CategoryService,
    public translate: TranslateService,
    public mainpage: MainPageComponent,
    public router: Router,
    public commonService: CommonService,) { 
  }

  ngOnInit(): void {

    //subcribe to data coming from slider recidect page
    this.commonService.data_challenge$.subscribe((res: any) =>{
      this.IdPill = res;
      this.sorry = this.IdPill > 0;
      //this.notifications_counter = res
    });

    this.commonService.data_surveyLink$.subscribe((res: string) =>{
      this.surveyLink = res;
      this.popup = this.surveyLink != "";
    });

    this.getPills();
    this.getSliders();
    this.getFamilies();
    this.getSubFamilies();
    this.getUserLeaderBoard();
  }
 
  redirectToCourseToComplete(){
    this.router.navigateByUrl('/mainpage/(pilldetail/' +this.IdPill + '//sidebar:related/'+ this.IdPill +')');
    this.commonService.changeIdChallengeData(0);
  }

  openSurveyLink(){
    this.commonService.changeSurveyLinkData("");
  }

  toggleChangeCategory(event: any) {
    let toggle = event.source;
    if (toggle) {
      let group = toggle.buttonToggleGroup;
      if (event.value.some((item: number) => item == toggle.value)) {
        group.value = [toggle.value];
        this.selectedCategory = toggle.value;
        //prendo le pillole collegate alla categoria selezionata
        this.filtered_pills = this.pills.filter(this.filterPillsByCategory, this.selectedCategory);
        //prendo le famiglie collegate alla categoria selezionata
        this.filtered_families = this.families.filter(this.filterFamiliesByIdCategory, this.selectedCategory);
        // console.warn('families with idcategory = ' + this.selectedCategory);
        // console.log(this.filtered_families);
      }
    }
    else {
      this.selectedCategory = -1;
      this.selectedFamily = -1;
      this.selectedSubFamily = -1;
      this.filtered_pills = this.pills;
    }
  }

  filterPillsByCategory(element: any){
    return element.idCategory === this;
  }

  filterPillsByFamily(element: any){
    return element.idFamily === this;
  }

  filterPillsBySubFamily(element: any){
    return element.idSubFamily === this;
  }

  filterFamiliesByIdCategory(element: any){
    return element.idCategory === this;
  }

  filterSubFamiliesByFamily(element: any){
    return element.idFamily === this;
  }

  onFamilyChange(value: any){
    let temp = value.source;
    this.setSelectedFamily(temp.value);
    if(this.selectedFamily != -1){
      this.filtered_pills = this.pills?.filter(this.filterPillsByFamily, this.selectedFamily);
      this.filtered_subfamilies = this.subfamilies.filter(this.filterSubFamiliesByFamily, this.selectedFamily);
    }
    else
    {
      this.selectedFamily = -1;
      this.selectedSubFamily = -1;
      this.filtered_pills = this.pills?.filter(this.filterPillsByCategory,this.selectedCategory);
    }
  }

  onSubFamilyChange(value: any){
    let temp = value.source;
    this.setSelectedSubFamily(temp.value);
    if(this.selectedSubFamily != -1)
      this.filtered_pills = this.pills?.filter(this.filterPillsBySubFamily, this.selectedSubFamily);
    else
    {
      this.filtered_pills = this.pills?.filter(this.filterPillsByFamily,this.selectedFamily);
    }
  }

  //index = 0 - for you
  //index = 1 - popular
  //index = 2 - ongoing
  OnPillTabsChange(value:any){
    let temp = value.index;

    if(this.selectedCategory != -1){
      if(this.selectedFamily != -1){
        if(this.selectedSubFamily != -1)
          this.filtered_pills = this.pills.filter(this.filterPillsBySubFamily, this.selectedSubFamily);
        else
          this.filtered_pills = this.pills.filter(this.filterPillsByFamily, this.selectedFamily);
        }
      else
        this.filtered_pills = this.pills.filter(this.filterPillsByCategory, this.selectedCategory);
      }
    else
      this.filtered_pills = this.pills;

    switch(temp){
      case 0: //foryou
        this.filtered_pills = this.filtered_pills.sort((a:any, b:any) => (a.position < b.position ? -1 : 1));
        this.isOngoing = false;
        break;
      case 1: //popular
        this.filtered_pills = this.filtered_pills.sort((a:any, b:any) => (a.viewsCounter > b.viewsCounter ? -1 : 1));
        this.isOngoing = false;
        break;
      case 2: //ongoing
        this.filtered_pills = this.filtered_pills.filter((item:any) => {return item.isCompleted === false && item.indexScorm} );
        this.isOngoing = true;
        break;
    }
  }

  //get sliders
  async getSliders(): Promise<void> {
    //this.userEmail,this.pass
    await this.categoryService.getslidersAuth(this.userEmail,this.pass)
      .subscribe(
        data => {

          let tablet = window.innerWidth > 1023;

          data.forEach((element: any) => 
          {
            if(tablet && !element.imageDesktopFullPath.includes("no_image.png"))
              element.sliderImgPath = this.categoryService.baseURLimg + element.imageDesktopFullPath.replace('~','');
            else
              element.sliderImgPath = this.categoryService.baseURLimg + element.imageFullPath.replace('~','');
          });

          this.sliders = data;
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  //get pills
  async getPills(): Promise<void> {
    
    await this.categoryService.GetPillsAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          //console.log(data);
          data.forEach((element: any) =>{
             element.imageFullPath = this.categoryService.baseURLimg + element.imageFullPath.replace('~','');
             switch(element.startText){
              case 'START':
                element.buttonText = this.translate.instant('Buttons_start');
                break;
              case 'DOWNLOAD':
                element.buttonText = this.translate.instant('Buttons_download');
                break;
              case 'REPEAT':
                element.buttonText = this.translate.instant('Buttons_repeat');
                break;
              case 'CONTINUE':
                element.buttonText = this.translate.instant('Buttons_continue');
                break;
              default:
                element.buttonText = element.startText;
                break;
             }
          });
         
          this.pills = data;
          this.filtered_pills = data;
          this.pills_foryou = data.sort((a:any, b:any) => (a.position < b.position ? -1 : 1));
        },
        error => {
          console.log(error);
        },
        () => {
          // 'onCompleted' callback.
          //console.warn(this.filtered_pills);
          this.filtered_pills = this.pills_foryou;
          this.categoryCourseCounter = this.countValuesByKey(this.pills,'idCategory');
          this.readCategories();
        }
      );
  }

  //get categories
  async readCategories(): Promise<void> {

    await this.categoryService.readAll(this.userEmail, this.pass)
      .subscribe(
        data => {
          data.forEach((element: any) => {
              element.imageFullPath = this.categoryService.baseURLimg + element.imageOnFullPath.replace('~','');
              element.coursecounter = (this.categoryCourseCounter[element.id] != undefined) ? this.categoryCourseCounter[element.id] : 0;
            }
          );
          this.categories = data;
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  //get families
  async getFamilies(): Promise<void> {

    await this.categoryService.getfamiliesAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.families = data;
          this.filtered_families = data;
          //console.warn('families');
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  //get subfamilies
  async getSubFamilies(): Promise<void> {

    await this.categoryService.getsubfamiliesAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.subfamilies = data;
          this.filtered_subfamilies = data;
          // console.warn('subfamilies');
          // console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  //get user leaderboard
  async getUserLeaderBoard(): Promise<void> {
    await this.categoryService.LeaderBoardUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.leaderboard = data;
        },
        error => {
          console.log(error);
        },
        ()=>{
          this.getCruscotti();
        });
  }


  async getCruscotti(): Promise<void>{
    this.categoryService.GetUserCourseDataAuth(this.userEmail, this.pass).subscribe(
      data => {
        this.leaderboard.corsi = data.overViewCoursesCounter;
        this.leaderboard.completati = data.overViewCoursesCompletedCounter;
      },
      error => {
        console.log(error);
      });
  }

//input array of data and key to count
  countValuesByKey = (arr: any[], key: string | number) => arr.reduce((r: { [x: string]: any; },c: { [x: string]: string | number; }) => {
    r[c[key]] = (r[c[key]] || 0) + 1
    return r
  }, {});
  


}

function handleClick(arg0: string, handleClick: any) {
  throw new Error('Function not implemented.');
}

