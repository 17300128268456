<div class="col-md-6">
  <div style="margin-left: 15px;">
    <h1>{{'support_trainingteam' | translate}}</h1>
    <svg-icon style="float: left; padding-right: 20px;" [name]="'contatto_mail'" [svgStyle]="{ 'width.px': '30' }"></svg-icon>
    <span class="title">{{'support_email' | translate}}</span><br>
    <span>{{'support_mailtraining' | translate}}</span>
    <div class="mt-2" style="margin-left: 50px;">
      <xiaomi-button mat-raised-button color="primary" (click)="sendEmail()">{{'Buttons_contactus' | translate}}</xiaomi-button><br><br>
    </div>
    <h1>{{'support_customercenter' | translate}}</h1>
    <svg-icon style="float: left; padding-right: 20px;height: 80px;" [name]="'contatto_phone'" [svgStyle]="{ 'width.px': '30' }"></svg-icon>
    <span class="title">{{'support_hotline' | translate}}</span><br>
    <span>{{'support_hotlinenumber' | translate}}</span><br>
    <span class="orari">{{'support_lunedi' | translate}}</span><br>
    <span class="orari">{{'support_sabato' | translate}}</span><br><br>
    <svg-icon style="float: left; padding-right: 20px;" [name]="'contatto_mail'" [svgStyle]="{ 'width.px': '30' }"></svg-icon>
    <span class="title">{{'support_email' | translate}}</span><br>
    <span>{{'support_mailservice' | translate}}</span><br><br>
    <h1>{{'support_MISupport' | translate}}</h1>
    <svg-icon style="float: left; padding-right: 20px;" [name]="'contatto_mail'" [svgStyle]="{ 'width.px': '30' }"></svg-icon>
    <span class="title">{{'support_productauthenti' | translate}}</span><br>
    <span>www.mi.com/global/verify</span><br><br>
    <div style="margin-left: 50px;">
      <xiaomi-button (click)="GoToPage()" mat-raised-button color="primary">{{'Buttons_govisit' | translate}}</xiaomi-button>
    </div>
  </div>
</div>