import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  baseURL = this.categoryService.baseURLimg;

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  CategoriesIdCruscotti = [1,8,14,18,22];

  user: any;
  pills: any;
  leaderboard: any;
  pillsongoing = [] as any;
  seenone: boolean= false;
  seemore: boolean= false;
  seeallpills: boolean= false;
  seetwo: boolean = false;
  seenonecomp: boolean= false;
  seemorecomp: boolean= false;
  seeallpillscomp: boolean= false;
  seetwocomp: boolean = false;
  stillmiss10: any;
  stillmiss15: any;
  stillmiss20: any;
  url10!: string;
  url15!: string;
  url20!: string;
  textmiss10: boolean = false;
  textmiss15: boolean = false;
  textmiss20: boolean = false;
  families: any;
  expertise = [] as any;
  imageUrl?: string;
  userCertificates : any;

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    public mainpage: MainPageComponent
  ) {
    this.getUser();
  }

  ngOnInit() {
  }

  getUser(): void {
    this.categoryService.getUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.user = data;
          //console.log(this.user);8
          this.imageUrl = this.baseURL+ data.imageFullPath + "?" + (new Date()).getTime();
        },
        error => {
          console.log(error);
        },
        ()=> this.getLeaderboard());
  }

  UsersCertificate(): void {
    this.categoryService.UsersCertificateUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.userCertificates = data;
          //console.log(data.length);
        },
        error => {
          console.log(error);
        },
        () => this.getPills());
  }

  getPills(): void {

    this.categoryService.GetPillsAuth( this.userEmail, this.pass)
      .subscribe(
        data => {
          this.pills = data;
          for(let i = 0; i< data.length; i++){

            if(this.pills[i].indexScorm == null)
              this.pills[i].indexScorm = "";

            //CORSI in corso
            if(!this.pills[i].isCompleted && this.pills[i].indexScorm.length !== 0){
              this.pillsongoing.push(this.pills[i]);
            }

          }
          if (this.pillsongoing.length==0)
            this.seenone = true;
          else if (this.pillsongoing.length<=2){
            this.seetwo = true;
            this.seenone = false;
          }
          else{
            this.seemore = true;
            this.seetwo = false;
            this.seenone = false;
          }

          //userCertificates
          if (this.userCertificates.length == 0)
            this.seenonecomp = true;
          else if (this.userCertificates.length <= 2){
            this.seetwocomp = true;
            this.seenonecomp = false;
          }
          else{
            this.seemorecomp = true;
            this.seetwocomp = false;
            this.seenonecomp = false;
          }
          //BADGE
          if(this.leaderboard.completati >= 10){
            this.url10 = "completed_1";
            this.textmiss10 = true;
          }else{
            this.url10 = "uncompleted_1";
            this.textmiss10 = false;
          }

          if(this.leaderboard.completati >= 15){
            this.url15 = "completed_2";
            this.textmiss15 = true;
          }else{
            this.url15 = "uncompleted_2";
            this.textmiss15 = false;
          }

          if(this.leaderboard.completati >= 20){
            this.url20 = "completed_3";
            this.textmiss20 = true;
          }else{
            this.url20 = "uncompleted_3";
            this.textmiss20 = false;
          }
          this.stillmiss10 = 10 - this.leaderboard.completati;
          this.stillmiss15 = 15 - this.leaderboard.completati;
          this.stillmiss20 = 20 - this.leaderboard.completati;
        },
        error => {
          console.log(error);
        },()=> this.getFamilies(this.pills));
  }

  getFamilies(courses: any): void {

    this.categoryService.getfamiliesAuth( this.userEmail, this.pass)
      .subscribe(
        data => {
          this.families = data;
          //console.log(data);
          for(let x = 0; x < this.families.length; x++){
            if(this.CategoriesIdCruscotti.includes(this.families[x].idCategory)){
              let item = [] as any;
              let countertotal = 0;
              let countercompleted = 0;
              let perc = 0;

              // corsi totali e completati di una determinata famiglia
              for(let y = 0; y < courses.length; y++){
                if(courses[y].idFamily == this.families[x].id){
                  countertotal++;
                  if(courses[y].isCompleted)
                    countercompleted++;
                }
              }
              //calcolo percentuale per grafici
              if (countertotal > 0)
                perc = countercompleted / countertotal * 100;
              else
                perc = 0;

              //creazione array per expertise
              item.push(this.families[x].id);
              item.push(this.families[x].text);
              item.push(countertotal);
              item.push(countercompleted);

              if(perc == 0)
                item.push('../../../assets/images/performance_0.png');
              else if(perc>0 && perc<=33)
                item.push('../../../assets/images/performance_1.png');
              else if(perc>33 && perc<=66)
                item.push('../../../assets/images/performance_2.png');
              else if(perc>66 && perc<=99)
                item.push('../../../assets/images/performance_3.png');
              else if(perc==100)
                item.push('../../../assets/images/performance_4.png');

              //controllo se famiglia gia presente accorpale
              let exist = false;
              for(let k=0; k<this.expertise.length; k++){
                if(this.expertise[k][1] == item[1]){
                  this.expertise[k][2] += item[2];
                  this.expertise[k][3] += item[3];
                  exist = true;
                }
              }

              if(!exist && item[2]>0)
                this.expertise.push(item);
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  getLeaderboard(): void {

    this.categoryService.LeaderBoardUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.leaderboard = data;
        },
        error => {
          console.log(error);
        },()=> this.getCruscotti());
  }

  async getCruscotti(): Promise<void>{
    this.categoryService.GetUserCourseDataAuth(this.userEmail, this.pass).subscribe(
      data => {
        this.leaderboard.corsi = data.overViewCoursesCounter;
        this.leaderboard.completati = data.overViewCoursesCompletedCounter;
      },
      error => {
        console.log(error);
      },
      ()=> this.UsersCertificate());
  }

  GoToEdit(){
    this.router.navigateByUrl('/mainpage/(edituser//sidebar:news)');
  }

  GoToRewardsPage(){
    this.router.navigateByUrl('/mainpage/(rewardscomingsoon//sidebar:news)');
  }

  seeall(){
    this.seemore = false;
    this.seeallpills = true;
  }

  seeless(){
    this.seemore = true;
    this.seeallpills = false;
  }

  seeallcomp(){
    this.seemorecomp = false;
    this.seeallpillscomp = true;
  }

  seelesscomp(){
    this.seemorecomp = true;
    this.seeallpillscomp = false;
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.


    // var kek = this.badgeContainer.nativeElement.children[0].firstChild;

    // console.warn(kek.innerHTML);


    //var temp = document.querySelectorAll('svg');
    //console.warn(temp);

    //let badge1 = this.badgeBeginner.nativeElement;

    //console.warn(badge1);
    // var elem = badge1.getElementById('beginner');
    // console.warn(elem);
    //elem['innerText' in elem ? "innerText" : "textContent"] = "some value";
  
   
  }

}
