<div class="sureDelete">
  <div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

    <div backButton style="cursor: pointer;">
      <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
        style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
      <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
    </div>
  </div>

  <div class="col-md-6 mx-auto mt-4" layout="row" layout-align="center center">

    <div>
        <span class="text center" style="font-size: 30px;">{{'profile_deletemyaccount' | translate}}</span><br>
        <svg-icon class="center mt-3 mb-3" [name]="'cancellare_dati_profilo'" [svgStyle]="{ 'width.px': '80' }"></svg-icon><br>
        <div class="mx-auto" style="max-width: 250px;">
          <span class="text center" style="text-align: center;line-height: 1.2">{{'profile_areyousureOncey' | translate}}</span>
         </div>
    </div>
    <div class="center pt-4">
      <xiaomi-button backButton mat-raised-button color="primary">{{'Buttons_noIwanttostay' | translate}}</xiaomi-button>
    </div>
    <div class="center pt-4 mb-5">
      <xiaomi-button mat-raised-button color="primary" (click)="deleteUser()">{{'Buttons_yesproceed' | translate}}</xiaomi-button>
    </div>
  </div>


  <div class="overlayBadge" *ngIf="isSureDelete" style="z-index: 400 !important;">
    <div class="divBadge" >
      <div class="popupBadge" style="max-width: 300px; height: 578px; background-color: var(--main-text-color);">
        <svg-icon style="cursor: pointer; float: right;" class="icon-white mt-4 mr-4" [name]="'esc'" [svgStyle]="{ 'width.px': '40' }" (click)="goToWelcomePage()"></svg-icon><br>
        <p class="center ml-3 mr-3" style="color: white; margin-top: 70px; font-size: 30px;text-align: center;line-height: 1.2">{{'profile_youarenowleavin' | translate}}</p>

        <svg-icon class="center mt-5 mb-5" [name]="'bye'" [svgStyle]="{ 'width.px': '150' }"></svg-icon>

        <span class="center ml-3 mr-3" style="text-align: center;color: white; font-size: 30px;line-height: 1.2">{{'profile_Ifchangeyourmin' | translate}}</span>
        
        <p class="center mt-4 ml-3 mr-3" style="text-align: center;color: white; font-size: 20px;line-height: 1.2">{{'registration_checkyourinbox' | translate}}</p>
      </div>
    </div>
  </div>
</div>