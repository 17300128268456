import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-sureToDelete',
  templateUrl: './sureToDelete.component.html',
  styleUrls: ['./sureToDelete.component.css']
})
export class SureToDeleteComponent implements OnInit {

  isSureDelete: boolean = false;

  constructor(public router: Router,
              public usersService: UsersService) { }

  ngOnInit() {
  }

  deleteUser(){
    this.usersService.DeleteUser(localStorage.getItem('user')!)
    .subscribe(
      data => {
        console.log(data);
      },
      error => {
        console.log(error);
      });

    this.isSureDelete = true;
    localStorage.removeItem('user');
    localStorage.removeItem('pass');
    localStorage.removeItem('n_news');
    localStorage.removeItem('for_you');  
  }

  goToWelcomePage(){
    this.isSureDelete = false;
    this.router.navigateByUrl('/welcome');
  }

}
