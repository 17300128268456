import { MatSidenav } from '@angular/material/sidenav';
import { async } from '@angular/core/testing';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  @ViewChild('sidenav') hambueger_menu!: MatSidenav;

  baseURL = this.categoryService.baseURLimg;

  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;

  keywordSearch: string = '';

  desktop!: boolean; // > 1300px
  tablet!: boolean; // 425px < x <= 1023px
  mobile!: boolean; // <= 425px
  screenWidth!: number;

  notifications_list: any;
  notifications_counter: number = 0;
  notification_icon_name: string = 'notification';
  popUpShare: string = '0';
  dropdownVisible: boolean = false;

  //gestione voce menu' attivo
  active_tab: string = 'homepage';

  activationDate = new Date();
  date = new Date(this.activationDate.getUTCFullYear(),
  this.activationDate.getUTCMonth(),
  this.activationDate.getUTCDate(),
  this.activationDate.getUTCHours(),
  this.activationDate.getUTCMinutes(),
  this.activationDate.getUTCSeconds()
  );
  today: any = [];
  week: any = [];
  month: any = [];
  year: any = [];
  other: any = [];

  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    this.screenWidth$.next(event.target.innerWidth);
  }

  constructor(private service: CommonService,
              public translate: TranslateService, 
              private categoryService: CategoryService,
              private router: Router) {
    this.screenWidth = 0;
    this.desktop = false;
    console.log(this.date);

   
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event:any) => {
     
      let curr_page_name = event.url.substring(event.url.indexOf('(') + 1, event.url.indexOf('//'));
      //console.warn(curr_page_name);
      this.active_tab = curr_page_name;

      //chiude il menu' hambuerger appena scelgo un item (solo mobile)
      if(this.hambueger_menu && this.mobile)
        this.hambueger_menu.close();

      //se non sono loggato reindirizzami alla pagina di login
      if(localStorage.getItem('user') === null && this.active_tab != ''){
        this.router.navigateByUrl('/login');  
      }

    });
  }

  getLocalStorageSize(){
    var allStrings = '';
    for(var key in window.localStorage){
        if(window.localStorage.hasOwnProperty(key)){
            allStrings += window.localStorage[key];
        }
    }
    return allStrings ? 3 + ((allStrings.length*16)/(8*1024)) + ' KB' : 'Empty (0 KB)';
  };

  ngOnInit() {
    this.getUserData();
    this.getNotifications();
    //console.warn(this.getLocalStorageSize());

    //subcribe to data coming from notificationarchive page
    this.service.data$.subscribe((res: any) =>{
       this.notifications_counter = res;
    });

    this.screenWidth$.subscribe(width => {
      this.screenWidth = width;
      if(width >= 1280){
        this.desktop = true;
        this.tablet = !this.desktop;
        this.mobile = !this.desktop;
      }else if(width > 425)
      {
        this.tablet = true;
        this.desktop = !this.tablet;
        this.mobile = !this.tablet;
      }
      else{
        this.mobile = true;
        this.desktop = !this.mobile;
        this.tablet = !this.mobile;
      }
    });
  }

  async getUserData(){
   //init translations
   await this.categoryService.getUserAuth(this.userEmail,this.pass)
   .subscribe(
     data => {
       this.translate.use(data.country.toLowerCase());
       localStorage.setItem('country', data.country);
       //console.log(data.country);
     },
     error => {
       console.log(error);
     });
  }

  async getNotifications(){
    await this.categoryService.getNotificheUserAuth(this.userEmail, this.pass)
      .subscribe(
        data => {
          this.notifications_list = data;
          this.notification_icon_name =  data.length > 0 ? 'notification_active' : 'notification';

          data.forEach((element: any) => {
            //console.log(element.dateSend);
            let app: Date = new Date(element.dateSend.split("-")[0], element.dateSend.split("-")[1] - 1, element.dateSend.split("-")[2].substring(0, 2));
            //console.log(app);
            let dateforminH: Date = new Date(element.dateSend.split("-")[0], element.dateSend.split("-")[1] - 1, element.dateSend.split("-")[2].substring(0, 2), element.dateSend.split("-")[2].substring(3,5), element.dateSend.split("-")[2].substring(6,8));
            //console.log(dateforminH.valueOf());

            element.dateSend = Math.floor((this.date.valueOf() - app.valueOf()) / 1000 / 60 / 24 / 60);
            //console.log(element.dateSend)
            if(element.dateSend < 1){
              let min = Math.floor((this.date.valueOf() - dateforminH.valueOf()) / 1000 / 60);
              let hour = Math.floor((this.date.valueOf() - dateforminH.valueOf()) / 1000 / 60/ 60);
              //console.log(min);
              if(min < 60){
                element.dateSend = min + "min"
                this.today.push(element);
              }else{
                element.dateSend = hour + "h"
                this.today.push(element);
              }
            }
            else if ((element.dateSend <= 7) && (this.today.length + this.week.length < 3)){
                element.dateSend = element.dateSend + "d";
                this.week.push(element);
            }
            else if ((element.dateSend <= 30) && (this.today.length + this.week.length + this.month.length < 3)){
                element.dateSend = element.dateSend + "d";
                this.month.push(element);
            }
            else if ((element.dateSend <= 365) && (this.today.length + this.week.length + this.month.length + this.year.length < 3)){
                element.dateSend = element.dateSend + "d";
                this.year.push(element);
            }
            else if (this.today.length + this.week.length + this.month.length + this.year.length + this.other.length < 3){
                element.dateSend = element.dateSend + "d";
                this.other.push(element);
            }

            if(!element.seen)
              this.notifications_counter++
            
          });

         // console.warn(data);
        },
        error => {
          console.log(error);
        });
  }

  searchContent(keyword: string){
    if(!keyword.match('^\\s*$'))
      this.router.navigate(['/mainpage', { outlets: { primary: ['search', keyword], sidebar: ['news'] }}]);
    else{
       alert('empty string');
       this.keywordSearch = '';
    }
  }

  PopUpShare(){
    this.popUpShare = '1';
  }

  logOutUser(){
    localStorage.removeItem('user');
    localStorage.removeItem('pass');
    localStorage.setItem('n_news','empty');
    localStorage.setItem('for_you', 'empty');
    this.router.navigateByUrl('/welcome');
  }

  notificationDropDown(){
    this.dropdownVisible = !this.dropdownVisible;
    this.active_tab = this.dropdownVisible === true ? 'notificationsarchive' : '';
  }
  closeNotificationDropdown(){
    this.dropdownVisible = false;
  }

  GoToDetail(idNot: number) {
    console.log(idNot);
    let i = 0;
    let found = false;
    while (!found && i < this.notifications_list.length) {
      if (this.notifications_list[i].idNotifica == idNot) {
        found = true;
      }
      else {
        i++;
      }
    }
    if (this.notifications_list[i].pageId != 0) {
      this.setNotificationAsSeen(idNot);
      this.router.navigateByUrl('/mainpage/redirectPage/' + this.notifications_list[i].pageId + '/' + this.notifications_list[i].contentId);
    }
  }

  setNotificationAsSeen(idNot: number) {
    this.categoryService.setNotificaAsSeen(idNot, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.getNotifications();
          
          // for (let i = 0; i < this.notifications_list.length; i++) {
          //   if (this.notifications_list[i].id == idNot) {
          //     this.notifications_list[i].seen = true;
          //     break;
          //   }
          // }

          console.log('notification ' + idNot + ' set as seen');
        
          this.notifications_counter--;
        },
        error => {
          console.log(error);
        });
  }
}
