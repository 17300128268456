import { CategoryService } from 'src/app/services/category.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpParameterCodec } from "@angular/common/http";
import { element } from 'protractor';
import { CommentsModel } from 'src/app/model/CommentsModel';
import { TranslateService } from '@ngx-translate/core';
import { MainPageComponent } from '../../main-page.component';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-pilldetail',
  templateUrl: './pilldetail.component.html',
  styleUrls: ['./pilldetail.component.scss']
})
export class PilldetailComponent implements OnInit {
  user!: string;
  pass!: string;
  countryUser!: string;
  urlSafe: SafeResourceUrl | undefined;
  pill_id!: number;
  pill_progress!: number;
  pill_rating!: number;
  user_rate!: number;
  pill: any;
  pill_state_changed = false;
  popup = false;
  rowHeight: number = 100;
  commenti: CommentsModel[] = [];
  commentiViewed: CommentsModel[] = [];
  hide: boolean = true;
  Rate1selected: boolean = false;
  Rate2selected: boolean = false;
  Rate3selected: boolean = false;
  Rate4selected: boolean = false;
  Rate5selected: boolean = false;
  clicked: boolean = false;
  isLiked: boolean = false;
  allcomment: boolean = false;
  myRate: boolean = false;
  ratesend: boolean = false;

  cruscotto:any;
  popupBadge:boolean = false;
  urlBadgePopup!: string;
  popupCertificate:boolean = false;
  certificate: any | boolean = false;

  sliceOptions: {
    end: number | undefined,
    start: number,
    default: number

  } = {
      end: 50,
      start: 0,
      default: 50
    }
  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public translate: TranslateService,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() {

    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    let countryapp = localStorage.getItem('country');
    if (userapp != null && passapp != null && countryapp != null) {
      this.user = userapp;
      this.pass = passapp;
      this.countryUser = countryapp;
    }

    this.route.params.subscribe(
      params => {
        this.pill_id = +params['id'];
        this.getPill();
        this.getRate();
        this.getCommentiPills();
        this.checkRate();
        console.log("Hello");
      }
    );
    console.log(this.pill_id);

  }

  onExpandText(evt: any, id: number): void {
    console.log(evt);
    let i: number = 0;
    let found: boolean = false;
    while (i < this.commenti.length) {
      if (this.commenti[i].id == id) {
        found = true;
        this.commenti[i].vote = this.commenti[i].vote == 2 ? 3 : 2;
        this.commenti[i].end = this.commenti[i].end ? undefined : this.sliceOptions.default;
      }
      i++;
    }


  }

  mouseEnter(rate_num: number) {
    //console.log("mouse enter");
    switch (rate_num) {
      case 1: {
        this.Rate1selected = true;
        break;
      }
      case 2: {
        this.Rate2selected = true;
        break;
      }
      case 3: {
        this.Rate3selected = true;
        break;
      }
      case 4: {
        this.Rate4selected = true;
        break;
      }
      case 5: {
        this.Rate5selected = true;
        break;
      }
      default: {
        this.Rate1selected = true;
        break;
      }
    }

  }

  mouseLeave(rate_num: number) {
    // console.log("mouse leave");
    switch (rate_num) {
      case 1: {
        this.Rate1selected = false;

        break;
      }
      case 2: {
        this.Rate2selected = false;
        break;
      }
      case 3: {
        this.Rate3selected = false;
        break;
      }
      case 4: {
        this.Rate4selected = false;
        break;
      }
      case 5: {
        this.Rate5selected = false;
        break;
      }
      default: {
        this.Rate1selected = false;
        break;
      }
    }
  }
  addToBookmarks() {
    if (!this.isLiked) {
      this.addFavoriteToUserAuth();
    }
    else {
      this.removeFavoriteToUserAuth();
    }
  }

  async addFavoriteToUserAuth(): Promise<void> {
    await this.categoryService.addFavoriteToUserAuth(this.pill_id, this.user, this.pass)
      .subscribe(
        data => {
          this.isLiked = true;

        },
        error => {
          console.log(error);
        });
  }
  async removeFavoriteToUserAuth(): Promise<void> {
    await this.categoryService.removeFavoriteToUserAuth(this.pill_id, this.user, this.pass)
      .subscribe(
        data => {
          this.isLiked = false;
        },
        error => {
          console.log(error);
        });
  }

  //get pills
  async getPill(): Promise<void> {
    
    
    await this.categoryService.GetSinglePillsAuth(this.pill_id, this.user, this.pass)
      .subscribe(
        data => {
          if (data.indexScorm == null || data.indexScorm.length === 0)
            this.pill_progress = 0;
          else
            this.pill_progress = 50;

          if (data.isCompleted) this.pill_progress = 100;
          data.imageFullPath = this.categoryService.baseURLimg + data.imageFullPath.replace('~', '');
          this.isLiked = data.favourite;
         
          if(this.pill != null)
            this.pill_state_changed = this.pill.isCompleted != data.isCompleted;
          this.pill = data;

          switch(data.startText){
            case 'START':
              data.buttonText = this.translate.instant('Buttons_start');
              break;
            case 'DOWNLOAD':
              data.buttonText = this.translate.instant('Buttons_download');
              break;
            case 'REPEAT':
              data.buttonText = this.translate.instant('Buttons_repeat');
              break;
            case 'CONTINUE':
              data.buttonText = this.translate.instant('Buttons_continue');
              break;
            default:
              data.buttonText = data.startText;
              break;
           }
          console.log(data);
        },
        error => {
          console.log(error);
        },()=>{
          if(this.pill_state_changed && this.pill.isCompleted){
            this.getCruscotti();
          }
        });
  }
  async getRate(): Promise<void> {
    await this.categoryService.getRatingPill(this.pill_id, this.user, this.pass)
      .subscribe(
        data => {
          this.pill_rating = Math.round(data * 10) / 10;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  async checkRate(): Promise<void> {
    await this.categoryService.checkRatingPill(this.user, this.pass, this.pill_id)
      .subscribe(
        data => {
          this.user_rate = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  async getCommentiPills(): Promise<void> {
    await this.categoryService.getCommentiPills(this.user, this.pass, this.pill_id)
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            data[i].vote = 2;
            data[i].userProPicPath = this.categoryService.baseURLimg + data[i].userProPicPath;
            data[i].end = this.sliceOptions.default;
            if (data[i].date != null) {
            }
          }

          this.commenti = data;
          if (this.allcomment || data.length < 2) {
            this.commentiViewed = data;
          }
          else {
            this.commentiViewed = data.slice(0, 2);
          }

          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  async sendLike(id: number): Promise<void> {
    await this.categoryService.sendLike(id, this.user, this.pass)
      .subscribe(
        data => {
          this.getRate();
          this.getCommentiPills();
        },
        error => {
          console.log(error);
        });
  }

  async sendRatingPill(rate: number): Promise<void> {

    await this.categoryService.sendRatingPill(this.pill_id, rate, this.user, this.pass)
      .subscribe(
        data => {
          this.getPill();
          this.getRate();
          this.getCommentiPills();
          this.checkRate();
        },
        error => {
          console.log(error);
        });
  }
  async sendComment(comment: string): Promise<void> {

    await this.categoryService.sendComment(this.pill_id, comment, this.user, this.pass)
      .subscribe(
        data => {
          this.getPill();
          this.getRate();
          this.getCommentiPills();
          this.checkRate();
          this.clicked = false;
        },
        error => {
          console.log(error);
        });
  }

  Like(id: number): void {
    //console.log(id);
    let i: number = 0;
    let found: boolean = false;
    while (i < this.commenti.length && !found) {
      if (this.commenti[i].id == id) {
        found = true;
      }
      else {
        i++;
      }

    }
    this.commenti[i].isLiked = !this.commenti[i].isLiked;
    this.sendLike(id);

  }
  SendComment(comment: string): void {
    //console.log(id);
    this.clicked = true;
    //console.log(comment)

    if (comment.length <= 217) {
      this.sendComment(comment);
    }
    else {
      this.clicked = false;
      console.log("Commento troppo lungo");
    }

  }

  Rate(rate: number) {
    this.sendRatingPill(rate);
    this.ratesend = true;


  }
  initPill() {

  }

  seeAllComment(): void {
    this.allcomment = !this.allcomment;
    if (this.allcomment || this.commenti.length < 2) {
      this.commentiViewed = this.commenti;
    }
    else {
      this.commentiViewed = this.commenti.slice(0, 2);
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getCruscotti(): Promise<void>{
    this.categoryService.getCruscotti(this.user, this.pass).subscribe(
      data => {
        this.cruscotto = data;
        //console.log(this.cruscotto.completati);
      },
      error => {
        console.log(error);
      },()=>{
        this.CheckBadge();
      });
  }

  async CheckBadge(): Promise<void>{

    if (this.pill.iD_Type == 0){
      if(this.cruscotto.completati == 10){
        switch(this.countryUser){
          case "UK":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1.jpg";
            break;
          case "IT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1_IT.jpg";
            break;
          case "ES":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1_ES.jpg";
            break;
          case "FR":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1_FR.jpg";
            break;
          case "DE":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1_DE.jpg";
            break;
          case "BN":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1_NL.jpg";
            break;
          case "PT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_1.jpg";
            break;
          default:
            this.urlBadgePopup = "../../../assets/images/popup_badge_1.jpg";
            break;
        }
        this.popupBadge = true;
      }
      else if(this.cruscotto.completati == 15){
        switch(this.countryUser){
          case "UK":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2.jpg";
            break;
          case "IT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2_IT.jpg";
            break;
          case "ES":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2_ES.jpg";
            break;
          case "FR":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2_FR.jpg";
            break;
          case "DE":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2_DE.jpg";
            break;
          case "BN":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2_NL.jpg";
            break;
          case "PT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_2.jpg";
            break;
          default:
            this.urlBadgePopup = "../../../assets/images/popup_badge_2.jpg";
            break;
        }
        this.popupBadge = true;
      }
      else if(this.cruscotto.completati == 20){
        switch(this.countryUser){
          case "UK":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3.jpg";
            break;
          case "IT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3_IT.jpg";
            break;
          case "ES":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3_ES.jpg";
            break;
          case "FR":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3_FR.jpg";
            break;
          case "DE":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3_DE.jpg";
            break;
          case "BN":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3_NL.jpg";
            break;
          case "PT":
            this.urlBadgePopup = "../../../assets/images/popup_badge_3.jpg";
            break;
          default:
            this.urlBadgePopup = "../../../assets/images/popup_badge_3.jpg";
            break;
        }
        this.popupBadge = true;
      }
    }
  }

  async getCertificate(): Promise<void>{
    this.categoryService.GetCertificate(this.pill_id,this.user, this.pass).subscribe(
      data => {
        console.warn('data certificato');
        console.log(data);
        console.warn('data di oggi UTC');

        var date = new Date();

        var date5min = date.setMinutes(date.getMinutes() - 5);
        var dateiso = new Date(date5min);
        console.log(data.date);
        console.log(dateiso.toISOString());
        console.log(data.date>=dateiso.toISOString());

        if(data != -1){
          this.certificate = data;
          //todo confornto certificate date minore di data attuale in utc - 5 min 
          if(data.date >= dateiso.toISOString())
            this.popupCertificate = true;
        }
      },
      error => {
        console.log(error);
      });
     
  }

  async CloseCourse(): Promise<void> {

    this.popup = false;
    await this.delay(500);
    this.getPill();
    this.getRate();
    this.getCommentiPills();
    this.checkRate();
    this.getCertificate();
  }

  GoToCourse(): void {
    let url = encodeURI(this.categoryService.baseURLimg + "/Player/Index?Id=" + this.pill_id + "&Email=" + this.user + "&Password=" + this.pass);
    console.log(url);
    //window.location.href =
    //window.open(url,'_blank');
    //window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');

    if (this.pill.iD_Type == 0 || this.pill.iD_Type == 1) {
      this.popup = true;

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else if (this.pill.iD_Type == 2){

      var win = window.open(url, '_blank');
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert('Please allow popups for this website');
      }
    }
  }

  GoToRate(){
    if(this.myRate == false)
      this.myRate = true;
    else
      this.myRate = false;
  }
}

