import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-n-related-pills',
  templateUrl: './n-related-pills.component.html',
  styleUrls: ['./n-related-pills.component.scss']
})
export class NRelatedPillsComponent implements OnInit {
  pills!: any;
  user!: string;
  pass!:string;
  pill_id!:number;
  screenHeight!: number;
  constructor(private categoryService: CategoryService,
    private route: ActivatedRoute,
    public translate: TranslateService) {

  }

  ngOnInit() {
    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');
    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    this.route.params.subscribe(
      params => {
        this.screenHeight = window.innerHeight;
        this.pill_id = +params['id'];
        this.getPills();
      }
    );

  }

  getPills(){

    var pills_to_take = 0;
    const pill_height = 270;
    const header = 55;
    const pill_label = 64;
    this.screenHeight = this.screenHeight - (header + pill_label);

    pills_to_take = Math.floor(this.screenHeight / pill_height);
    console.warn('pills to take are: ' + pills_to_take);


    this.categoryService.GetCorrelati(this.pill_id,pills_to_take,this.user, this.pass)
      .subscribe(
        data => {

          data.forEach((element: any) =>{
            element.imageFullPath = this.categoryService.baseURLimg + element.imageFullPath.replace('~','')
            switch(element.startText){
              case 'START':
                element.buttonText = this.translate.instant('Buttons_start');
                break;
              case 'DOWNLOAD':
                element.buttonText = this.translate.instant('Buttons_download');
                break;
              case 'REPEAT':
                element.buttonText = this.translate.instant('Buttons_repeat');
                break;
              case 'CONTINUE':
                element.buttonText = this.translate.instant('Buttons_continue');
                break;
              default:
                element.buttonText = element.startText;
                break;
             }
          });
          this.pills = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
}
