import { CategoryService } from 'src/app/services/category.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-redirect-slider-page',
  templateUrl: './redirect-slider-page.component.html',
  styleUrls: ['./redirect-slider-page.component.css']
})
export class RedirectSliderPageComponent implements OnInit {

  user!: string;
  pass!: string;

  ExternalLinkActive!: string | null;
  ExternalLink!: string | null;
  LinkType!: string | null;
  Link!: string | null;
 
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    public commonService: CommonService,
    public translate: TranslateService,
  ) { }

  ngOnInit() {

    this.ExternalLinkActive = this.route.snapshot.params.ExternalLinkActive;
    this.ExternalLink = this.route.snapshot.params.ExternalLink;
    this.LinkType = this.route.snapshot.params.LinkType;
    this.Link = this.route.snapshot.params.Link;
   
    console.log('pagina redirect slider');

    console.log(this.ExternalLinkActive);
    console.log(this.ExternalLink);
    console.log(this.LinkType);
    console.log(this.Link);

    let userapp = localStorage.getItem('user');
    let passapp = localStorage.getItem('pass');

    if (userapp != null && passapp != null) {
      this.user = userapp;
      this.pass = passapp;
    }
    else {
      this.router.navigateByUrl('/login');
    }


    console.warn(this.ExternalLinkActive);

    if(this.ExternalLinkActive === "true")
    {
      //open link new tab
      this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');

      if(this.ExternalLink != null)
        window.open(this.ExternalLink!!, "_blank");
    }
    else{

      //se c'e' la pagina e il suo dettaglio
      if((this.LinkType != null && this.LinkType != "0") && (this.Link != null && this.Link != "0"))
      {
        switch(this.LinkType){
          case "1": //pill detail
            this.router.navigateByUrl('/mainpage/(pilldetail/' + this.Link + '//sidebar:related/'+ this.Link +')');
            break;
          case "2": //news detail
            this.router.navigateByUrl('/mainpage/(newsdetail/' + this.Link + '//sidebar:foryou)');
            break;
          case "3": //rewards
            this.router.navigateByUrl('/mainpage/(rewardscomingsoon//sidebar:foryou)');
            break;
          case "4": //forum detail
            //TODO da gentire quando ci saranno i forum
            this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
            break;
          case "5": //challenge detail
            this.checkChallengePillCompleted( Number(this.Link));
            break;
          case "6": //survey detail
            this.getSingleSurvey(Number(this.Link))
            break;

          default: break;
        }
      }
      //se c'e' la pagina senza il dettaglio
      else if(this.LinkType != null && this.LinkType != "0"){


        switch(this.LinkType){
          case "1": //pill page
            this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
            break;
          case "2": //news page
            this.router.navigateByUrl('/mainpage/(news//sidebar:foryou)');
            break;
          case "3": //rewards
            this.router.navigateByUrl('/mainpage/(rewardscomingsoon//sidebar:foryou)');
            break;
          case "4": //forum page
            //TODO da gentire quando ci saranno i forum
            this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
            break;
          case "5": //challenge page
            this.router.navigateByUrl('/mainpage/(challenges//sidebar:news)?section=0' );
            break;
          case "6": //survey page
            this.router.navigateByUrl('/mainpage/(surveys//sidebar:news)');
            break;

          default: break;
        }

      }else
      this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');



    }
  



    // if (this.pageId == 1) {
    //   //newCourse;
    //   this.router.navigateByUrl('/mainpage/(pilldetail/' + this.contentId + '//sidebar:related/'+ this.contentId +')' );

    // }
    // else if (this.pageId == 2) {
    //   //newSurvey;
    //   this.router.navigateByUrl('/mainpage/(surveys//sidebar:news)' );

    // } else if (this.pageId == 3) {
    //   //newNews;
    //   this.router.navigateByUrl('/mainpage/(newsdetail/' + this.contentId + '//sidebar:foryou)' );

    // } else if (this.pageId == 4) {
    //   //newTOS;
    //   this.router.navigateByUrl('/mainpage/(termscondition//sidebar:news)' );

    // }
    // else if (this.pageId == 5) {
    //   //NewPrivacy;
    //   this.router.navigateByUrl('/mainpage/(privacypolicy//sidebar:news)' );

    // } else if (this.pageId == 6) {
    //   //Badge;
    //   this.router.navigateByUrl('/mainpage/(userprofile//sidebar:news)' );
    // }else if (this.pageId == 7) {
    //   //Leaderboard;
    //   this.router.navigateByUrl('/mainpage/(leaderboard//sidebar:news)' );
    // }else if (this.pageId == 8) {
    //   //challengestarted;
    //   this.router.navigateByUrl('/mainpage/(challenges//sidebar:news)?section=1' );
    // }else if (this.pageId == 9) {
    //   //Leaderboard;
    //   this.router.navigateByUrl('/mainpage/(challenges//sidebar:news)?section=2' );
    // }


  }


  async checkChallengePillCompleted(id:number): Promise<void> {
    await this.categoryService.GetSingleChallengeAuth(id, this.user, this.pass)
      .subscribe(
        data => {

          console.warn('challenge single');
          console.log(data);

          data.imageFullPath = this.categoryService.baseURLimg + data.imageFullPath.replace('~','');

          if(data.pillCompleted == true){
            //redirect to search opponent page
            this.router.navigateByUrl('/mainpage/(challengesearchopponent/' + data.id + '//sidebar:news)');
          }
          else{
            this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');
            //mostrare il popup del corso da completare
            this.commonService.changeIdChallengeData(data.pillsId);

            //alert(this.translate.instant('challenges_youneedcomplete'));
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  async getSingleSurvey(id:number): Promise<void> {

    await this.categoryService.getSingleSurveysAuth(id, this.user, this.pass)
      .subscribe(
        data => {
        
          //console.log('single survey');
          //console.log(data);
          //redirect SurveyLink 
          
          //open link new tab
          this.router.navigateByUrl('/mainpage/(homepage//sidebar:news)');

          if(data[0].surveyLink != null){

            var win = window.open(data[0].surveyLink, "_blank");
            if (win) {
              //Browser has allowed it to be opened
              win.focus();
            } else {
              //Browser has blocked it
              alert('Please allow popups for this website');
            }
            //this.commonService.changeSurveyLinkData(data[0].surveyLink);
          
          }
        },
        error => {
          console.log(error);
        }
      );
  }

}
