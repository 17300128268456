<div class="registrationContainer">
<!--<img src="https://loghi-famosi.com/wp-content/uploads/2020/05/Xiaomi-Logo.png"
  style="width: auto; height: 140px;" class="p-3">-->

<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px; width: 100vw;"
  [hidden]="currentPage>=4">

  <div (click)="BackToPage()" style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>

<form [formGroup]="userRegistrationForm" (ngSubmit)="onSubmit()" style="width: 100vw;" class="pl-3 pr-3"
  [hidden]="currentPage>=4">
  <img src="../../../assets/images/logo_grande.png" class="logo p-3">
  <ng-template [ngIf]="currentPage===1">

    <div style="max-width: 900px; margin: auto">
      <br><br><span class="title"
        style="padding-top: 7px; font-weight: bold;">{{'registration_signup' | translate}}</span><br><br>
      <span class="number">1/3</span><br><br>
      <span class="title">{{'registration_pleaseselecttheCount' | translate}}</span><br><br>
      <div class="contentCountry mx-auto">
        <mat-button-toggle-group formControlName="country" class="w-100 mr-3" required vertical="true">
          <mat-button-toggle *ngFor="let country of countries" color="accent" [value]="country.code"
            style="padding: 5px;">
            {{country.name}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

  </ng-template>

  <ng-template [ngIf]="currentPage===2">
    <div style="max-width: 600px; margin: auto">
      <br><br>
      <span class="title" style="padding-top: 7px; font-weight: bold;">{{'registration_signup' | translate}}</span>
      <br><br>
      <span class="number">2/3</span><br><br>
      <span class="title">{{'registration_pleasecompletethefie' | translate}}</span><br><br>
      <div class="mx-auto">

        <div class="row" style="max-width: 600px; margin: auto">
          <div class="pass">
            <mat-form-field class="d-block">
              <label class="uppercase">{{'registration_name' | translate}}</label>
              <input matInput type="text" formControlName="name" required autofocus />
              <mat-error *ngIf="name?.hasError('pattern')">{{'registration_Plaeseinsertavalidna' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="confpass">
            <mat-form-field class="d-block">
              <label class="uppercase">{{'registration_surname' | translate}}</label>
              <input matInput type="text" formControlName="surname" required />
              <mat-error *ngIf="surname?.hasError('pattern')">{{'registration_Plaeseinsertavalidsu' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mail">
          <mat-form-field class="d-block">
            <label class="uppercase">{{'registration_e-mailaddress' | translate}}</label>
            <input matInput type="email" formControlName="email" required (change)="checkEmailExist()" />
            <mat-error [hidden]="emailExist">{{'registration_Plaeseinsertavalidem' | translate}}</mat-error>
            <mat-error [hidden]="!emailExist">{{'registration_emailalreadyexisting' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="row" style="max-width: 600px; margin: auto">
          <div class="pass">
            <mat-form-field class="d-block">
              <label class="uppercase">{{'registration_password' | translate}}</label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
              <button mat-icon-button matSuffix [disableRipple]="true" 
                     (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <svg-icon [name]="hide ? 'hide' : 'see'"
                          [svgStyle]="{ 'width.px': '20' }"
                          [svgClass]="'eyeicon'"></svg-icon>
              </button>
              <mat-error *ngIf="password?.hasError('pattern')"> {{'registration_Thepassworddoesntmee' | translate}}
              </mat-error>
            </mat-form-field>
            <div matTooltip="{{'PASSWORD.passwordcontain' | translate}}
              {{'registration_passwordlength' | translate}}
              {{'registration_passwordonelower' | translate}}
              {{'registration_passwordonenumber' | translate}}
              {{'registration_passwordonespecial' | translate}}
              {{'registration_passwordoneupper' | translate}}" matTooltipPosition="right" style="margin-left: -5px;"
              matTooltipClass="my-tooltip">
              <svg-icon [name]="'info'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
              <b>{{'registration_rulesforpasswordcrea' | translate}}</b>
            </div>
          </div>
          <div class="confpass">
            <mat-form-field class="d-block">
              <label class="uppercase">{{'registration_confirmationpassword' | translate}}</label>
              <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="confirm_password" required />
              <button mat-icon-button matSuffix [disableRipple]="true"
                      (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide2">
                <svg-icon [name]="hide2 ? 'hide' : 'see'"
                          [svgStyle]="{ 'width.px': '20'}"
                          [svgClass]="'eyeicon'"></svg-icon>
              </button>
              <mat-error *ngIf="confirm_password?.hasError('confirmedValidator')">
                {{'registration_Passworddontmatch' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mail">
          <mat-form-field class="d-block">
            <label class="uppercase">{{'registration_channel' | translate}}</label>
            <mat-select formControlName="channel" (ngModelChange)="getStores()" required>
              <mat-option *ngFor="let channel of channels" [value]="channel.id">
                <b>{{channel.canale}}</b> |
                <span style="color: orangered; font-weight: bold;">{{channel.sottoCanale}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="channel?.hasError('required')">{{'registration_pleaseselectthechannel' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="row mb-2" style="max-width: 600px; margin: auto">
          <div class="pass">
            <mat-form-field class="d-block">
              <label class="uppercase">{{'registration_region' | translate}}</label>
              <mat-select formControlName="region" (ngModelChange)="getStores()" required>
                <div class="inputContainerRegistration search-desktopRegistration d-inline-block">
                  <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
                  <input class="FieldRegistration" [(ngModel)]="keywordSearch" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchContentRegion(keywordSearch)" type="text" placeholder="Search"/>
                </div>
                <div style="height: 1px;background-color: #B9B6B6;margin-top: 10px;"></div>
                <mat-option *ngFor="let region of regionSearched" [value]="region.id">
                  <b>{{region.region}}</b>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="region?.hasError('required')">{{'registration_pleaseselecttheregion' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="confpass">
            <mat-form-field class="d-block" *ngIf="stores?.length > 0">
              <label class="uppercase">Store</label>
              <mat-select formControlName="store">
                <div class="inputContainerRegistration search-desktopRegistration d-inline-block">
                  <svg-icon [name]="'search_input'"[svgStyle]="{ 'width.px': '22'}"></svg-icon>
                  <input class="FieldRegistration" [(ngModel)]="keywordSearch" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchContentStore(keywordSearch)" type="text" placeholder="Search"/>
                </div>
                <div style="height: 1px;background-color: #B9B6B6;margin-top: 10px;"></div>
                <mat-option *ngFor="let store of storeSearched" [value]="store.id">
                  <b>{{store.name}}</b>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="countrysel === 'ZZZ'">
          <div class="mail" *ngIf="stores?.length > 0">
            <mat-form-field class="d-block">
              <label class="uppercase">User ID</label>
              <input matInput type="text" formControlName="userid"/>
            </mat-form-field>
          </div>
        </div>
        <div class="policy row p-md-3">
          <div class="linkterms">
            <mat-slide-toggle class="mr-2" color='primary' formControlName="tos" required></mat-slide-toggle>
            <div class="d-inline">{{'registration_iagreetomiacademyste' | translate}} <a class="link"
                (click)="TermsSer()">{{'menu_termsofuse' | translate}}</a></div>
          </div>
          <div class="center pt-5">
            <div class="d-inline">
              {{'registration_iagreetomiacademypr' | translate}}
              <a class="link" (click)="PriPol()">{{'registration_privacypolicy' | translate}}</a> 
              {{'registration_iagreetomiacademypr2' | translate}}
            </div>
          </div>

        </div>

        <div class="overlay" *ngIf="popup" style="z-index: 99;">
          <div class="popup">
            <h2>{{'menu_termsofuse' | translate}}</h2>
            <a class="close" (click)="popup = false">&times;</a>
            <!--<div [innerHTML]="Terms"></div>-->
            <iframe [srcdoc]="Terms"></iframe>
          </div>
        </div>

        <div class="overlay" *ngIf="popuppol" style="z-index: 99;">
          <div class="popup">
            <h2>{{'menu_privacy' | translate}}</h2>
            <a class="close" (click)="popuppol = false">&times;</a>
            <!--<div [innerHTML]="Terms"></div>-->
            <iframe [srcdoc]="Policy"></iframe>
          </div>
        </div>

      </div>
    </div>
  </ng-template>


  <ng-template [ngIf]="currentPage===3">
    <div style="max-width: 600px; margin: auto">
      <br><br><span class="title"
        style="padding-top: 7px; font-weight: bold;">{{'Buttons_signup' | translate}}</span><br><br>
      <span class="number">3/3</span><br><br>
      <span class="title">{{'registration_beforestartingletsyo' | translate}}</span><br><br>
      <div class="row">

        <mat-form-field class="col-sm-5">
          <label class="uppercase">{{'registration_nickname' | translate}}</label>
          <input matInput type="text" formControlName="nickname" (change)="nicKNameExist()" />
          <mat-error>{{'registration_correctnickname' | translate}}</mat-error>
        </mat-form-field>


        <div class="col-sm-7">

          <img [src]="imgURL" class="AvatarImgBig" *ngIf="imgURL">

          <span style="padding-left: 30px;padding-top: 10px;" (click)="fakeButtonClick()">
            <div class="row">
              <div class="form-group col-xs-6">
                <svg-icon class="rotated45 hand" [name]="'esc'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
                &nbsp;&nbsp;
                <label class="hand uppercase">{{'registration_taptoaddyourpicture' | translate}}</label>
              </div>
            </div>

          </span>
          <input #file class="fileUpload" type="file" accept='.jpg' (change)="preview(file.files)" />
        </div>
      </div>
    </div>
  </ng-template>
</form>
<div class="container centered-container" style="max-width: 500px;" [hidden]="!(currentPage>=4)">
  <div class="row endregistrationRow">
    <div class="col-lx-4"></div>
    <div class="col-lx-4"> <img src="../../../assets/images/logo_grande.png"></div>
    <div class="col-lx-4"></div>
  </div>
  <div class="row endregistrationRow">
    <div class="col-lx-4"></div>
    <div class="col-lx-4 testo-xiaomi">
      <span>{{'registration_wearewaitingforyouto' | translate}}</span><br>
      <span>{{'registration_toconfirmyouremail' | translate}}</span><br>
      <span>{{'registration_checkyourinbox' | translate}}</span><br>
      <span>{{'registration_andcompleteyour' | translate}}</span><br>
      <span>{{'registration_registration' | translate}}</span></div>
    <div class="col-lx-4"></div>
  </div>
  <div class="row endregistrationRow">
    <div class="col-lx-4"></div>
    <div class="col-lx-4 testo-xiaomi">{{'registration_makesuretocheck' | translate}}</div>
    <div class="col-lx-4"></div>
  </div>


</div>

<!-- DEBUG
  <pre>{{userRegistrationForm.get('country')?.valid}}</pre>

  <pre>{{userRegistrationForm.get('step2.name')?.valid}}</pre>
  <pre>{{userRegistrationForm.get('step2.surname')?.valid}}</pre>
  <pre>{{userRegistrationForm.get('step2.email')?.valid}}</pre>
  <pre>{{userRegistrationForm.get('step2.password')?.valid}}</pre>
  <pre>{{userRegistrationForm.get('step2.confirm_password')?.valid}}</pre>
  Form status : {{userRegistrationForm.status}}
  -->


<ng-template [ngIf]="currentPage===1">
  <div class="center pt-4">
    <!-- <button mat-raised-button color="primary"
      (click)="nextPage()"
      [disabled]="!country?.valid">CONFIRM</button> -->
    <xiaomi-button (click)="nextPage()" [disabled]="!country?.valid">
      {{'Buttons_confirm' | translate}}
    </xiaomi-button>
  </div>
</ng-template>

<ng-template [ngIf]="currentPage===2">
  <!-- <div class="center pt-4 linkprivacy">
    <div style="max-width: 600px; text-align: center;" class="d-inline ml-2">
      {{'registration_iagreetomiacademypr' | translate}}
      <a class="link" (click)="PriPol()">{{'registration_privacypolicy' | translate}}</a> 
      {{'registration_iagreetomiacademypr2' | translate}}
    </div>
  </div> -->

  <div class="center pt-4">
    <xiaomi-button mat-raised-button color="primary" (click)="nextPage()"
      [disabled]="(!userRegistrationForm?.valid) || wait">
      {{'Buttons_confirm' | translate}}</xiaomi-button>
  </div>
</ng-template>

<ng-template [ngIf]="currentPage===3">
  <div class="center pt-4">
    <xiaomi-button mat-raised-button color="primary" (click)="SubmitForm()"
      [disabled]="(!userRegistrationForm?.valid) || wait">{{'Buttons_confirm' | translate}}</xiaomi-button>
  </div>
</ng-template>
<div class="container centered-container">
  <label class="mt-1" [hidden]="currentPage>=4">{{'registration_alreadyhaveanaccount' | translate}}
    <span [routerLink]="['/login']" routerLinkActive="router-link-active" style="cursor: pointer;">
      <u><b>{{'Buttons_login' | translate}}</b></u>
    </span>
  </label>
  <xiaomi-imprint></xiaomi-imprint>
</div>
</div>
