<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">
  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class="mx-auto container p-lg-4 p-3">
  <p class="text-responsive">
    {{'search_searchresults' | translate}}
  </p>

  <p class="text-responsive">
    "{{searchKeyword}}"
  </p>

  <!-- search result tabs -->
  <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="OnTabChange($event)" animationDuration="0ms">
    <mat-tab [label]="'home_courses' | translate">
      <ng-template matTabContent>
        <!-- pills list -->
        <mat-grid-list [gridCols]="{xs: 1, sm: 1, md: 1, lg: 2, xl: 2}" rowHeight="285px"
                       *ngIf="coursesFound?.length > 0">
          <mat-grid-tile *ngFor="let pill of coursesFound">
            <mat-card class="example-card">
              <mat-card-content class="position-relative">
                <img class="pill-image" mat-card-image src="{{pill.imageFullPath}}">
                <span class="pill-title">{{pill.title}}</span>
                <svg-icon [name]="pill.favourite ? '4_bookmarks_orange_filled' : '4_bookmarks_orange'" [svgStyle]="{ 'width.px': '30' }" class="pill-heart-icon icon-white"></svg-icon>
                <mat-grid-list cols="3">
                  <mat-grid-tile colspan="2">
                    <div class="text-left w-100">
                      <p class="mb-0">{{pill.subFamilyName}}</p>
                      <div class="line-clamp"> {{pill.abstractBox}}</div>
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile style="overflow: initial;">
                    <button class="btn btn-outline shadow-none" [class.button-repeat]="pill?.startText == 'REPEAT'"
                            [routerLink]="['/mainpage',{ outlets: { primary: ['pilldetail', pill.id], sidebar: ['related', pill.id] } }]">
                      {{pill.startText}}
                    </button>
                  </mat-grid-tile>
                </mat-grid-list >
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <h1 *ngIf="coursesFound?.length == 0" class="text-center mt-5" style="color:var(--main-text-color);">{{'home_sorry' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'menu_challenges' | translate">
      <ng-template matTabContent>
        <h1 class="text-center mt-5" style="color:var(--main-text-color);">{{'home_sorry' | translate}}</h1>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'menu_news' | translate">
      <!-- news list -->
      <ng-template matTabContent>
        <div *ngIf="newsFound?.length > 0" >
          <div *ngFor="let notice of newsFound" [id]="notice.id" class="col-md-6 col-12 mx-auto mt-3"
              layout="row" layout-align="center center"
              [routerLink]="['/mainpage/newsdetail', notice.id]">
            <div style="width: 50%;float:left;height: 100px;">
              <img src="{{baseURL}}{{notice.imageLargeFullPath}}" style="max-width: 120px; max-height: 80px;border-radius: 15%;">
            </div>
            <div style="height: 110px;">
              <span class="date">{{notice.dateText}}</span>
              <p class="title">{{notice.title}}</p>
              <span class="category">{{notice.category}}</span>
              <p class="category">{{notice.subCategory}}</p>
            </div>
            <div style="height: 1px;background-color: #B9B6B6;"></div> 
          </div>
        </div>
       
        <h1 *ngIf="newsFound?.length == 0" class="text-center mt-5" style="color:var(--main-text-color);">{{'home_sorry' | translate}}</h1>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
