<div class="bg-danger h-100" (click)="mainpage.dropdownVisible = false">
  <div class="row ml-0 mr-0 p-4">

    <div class="col-10 text-left p-0 text-light">
      <span class="text-capitalize display-4"> {{'search_search' | translate}} </span> 
    </div>

    <div class="col-2 text-right p-0 mt-4 text-light">
      <svg-icon backButton [name]="'esc'" style="cursor: pointer;"
                [svgStyle]="{ 'width.px': '30' }" class="icon-white"></svg-icon>
    </div>

  </div>

  <div class="row ml-0 mr-0">
    <div class="col-8 mx-auto border-circle">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label></mat-label>
        <input matInput placeholder="Placeholder"
               (keyup.enter)="searchContent()"
               [(ngModel)]="keywordSearch">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

  
