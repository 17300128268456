<svg-icon backButton style="cursor: pointer; float: right;" class="mt-3 mr-5" [name]="'esc'" [svgStyle]="{ 'width.px': '40' }"></svg-icon><br>
<div class="col-md-6 mx-auto mt-5" layout="row" layout-align="center center">

  <div>
        <!-- logo xiaomi -->
        <div class="center mb-5">
          <img src="../../../assets/images/logo_grande.png"
              style="width: 250px;" class="p-3">
        </div>

        <!-- contenitore default -->
        <div *ngIf="show_default_popup == true; else elseBlock">
          <span class="text center">{{'menu_rewardscatalogu' | translate}}</span><br>
          <span class="text center" style="text-transform: uppercase;">{{'rewards_comingsoon' | translate}}</span><br>
          <svg-icon class="center" [name]="'rewards_not_available'" [svgStyle]="{ 'width.px': '80' }"></svg-icon><br>
          <div class="mx-auto" style="max-width: 220px;">
            <span class="text center" style="text-align: center;line-height: 1.2">{{'rewards_rewadswillbeadd' | translate}}</span>
          </div>
        </div>

        <!-- contenitore per NL -->
        <ng-template #elseBlock>

          <!-- <svg-icon class="center" [name]="'rewards_not_available'" [svgStyle]="{ 'width.px': '80' }"></svg-icon><br> -->
          <div class="center">
            <img src="/assets/images/rewards_badge_nl1.png" style="width: 100px;">
          </div>
       
          <p class="mx-auto text-center mt-5" style="max-width: 220px; text-align: center; font-size: medium;">
           <span>Behaal de </span>
           <b>expert badge </b>
           <span>door 16 cursussen te voltooien en ontvang geweldige </span>
           <b>Xiaomi-goodies!!</b>
          </p>
        </ng-template>
         
        
         
       
  </div>
 
</div>