import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  selector: 'xiaomi-badge',
  templateUrl: './xiaomi-badge.component.html'
})
export class XiaomiBadgeComponent implements OnChanges {

  @Input() name!: string;

  public svgTemplate!: SafeHtml;
  private badgeType = 0;
  private badgeTranslation = '';
  private badgeTranslationKey = '';

  changeLog: any = [];

  constructor(
      private http: HttpClient,
      private domSanitizer: DomSanitizer,
      public translate: TranslateService
  ) {
  }

  // ngOnInit() {
  //    //get svg badge type
  //    if(this.name){
  //         this.badgeType = +this.name.charAt(this.name.length - 1) ; 
  //    this.setBadge();
  //    }
  // }

  setBadge() {
    if(this.name){
        setTimeout(() => {
        this.badgeType = +this.name.charAt(this.name.length - 1) ; 
        switch(this.badgeType){
          case 1 : 
            this.badgeTranslation = this.translate.instant('badge_beginner');
            this.badgeTranslationKey = 'beginner';
            break;
          case 2 : 
            this.badgeTranslation = this.translate.instant('badge_pro');
            this.badgeTranslationKey = 'pro';
            break;
          case 3 : 
            this.badgeTranslation = this.translate.instant('badge_expert');
            this.badgeTranslationKey = 'expert';
            break;
        }
        //get svg
        this.http.get(`assets/svg/Badge/${this.name}.svg`, {responseType: 'text'}).subscribe(svg => {
        svg = svg.replace('>' + this.badgeTranslationKey + '<', '>' + this.badgeTranslation + '<');
        this.svgTemplate = this.domSanitizer.bypassSecurityTrustHtml(svg);
      });
      }, 100);
    }
   
  }


  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {
      const chng = changes[propName];
      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);

      if(this.name){
        this.setBadge();
      }

      this.changeLog.push(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }

    //console.warn('changes made: ');
    console.log(this.changeLog);
  }

}
