import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {

  traduzioni: any;

  constructor(private router: Router,
    public translate: TranslateService,
    private categoryService: CategoryService) {



    //check if new language file is available

    var browserLang = translate.getBrowserLang();

    //var o = translate.getTranslation(browserLang);
    //console.warn(o);

    // translate.setTranslation(browserLang, {
    //   HELLO: 'hello {{value}}'
    // });

    //console.log(browserLang);
    switch (browserLang) {
      case 'it':
        translate.use('it');
        break;
      case 'es':
        translate.use('es');
        break;
      case 'fr':
        translate.use('fr');
        break;
      case 'de':
        translate.use('de');
        break;
      case 'bn':
        translate.use('bn');
        break;
      case 'pt':
        translate.use('pt');
        break;
      default:
        translate.use('uk');
        browserLang = 'uk';
        break;
    }
    

    /*

    let dateString: string;

    translate.getTranslation(browserLang).subscribe((res: any) => {
      dateString = res["dateLastModify"];
      console.log(dateString);
      let newDate = new Date(dateString);
      let data = new LanguageInput(newDate, browserLang);
    this.categoryService.GetLanguagesAuth(data, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          this.traduzioni = data;
          console.log(this.traduzioni);
          this.traduzioni.forEach((element:any) => {
            res[element.chiave] = element.valore;

            translate.setTranslation(browserLang, res);
          });
        },
        error => {
          console.log(error);
        });
    });*/
   
    //console.log(newDate);
    /*let data = new LanguageInput(newDate, "");
    this.categoryService.GetLanguagesAuth(data, "usertest@ulisse1984.it", "P@ssword0")
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        });*/
    /*
    translate.getTranslation(browserLang).subscribe((res: any) => {
      res["BUTTON"]["login"] = "lolm";

      translate.setTranslation('uk', res);
      //=> 'hello world'
    });
 */   
  }

  GoToLogin(){
    this.router.navigateByUrl('/login');
  }

  GoToSignUp(){
    this.router.navigateByUrl('/signup');
  }


  ngOnInit() {
  }

}

