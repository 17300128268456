import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PolicyInput } from 'src/app/model/ModelInput';
import { CategoryService } from 'src/app/services/category.service';
import { MainPageComponent } from '../main-page.component';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  userEmail = localStorage.getItem("user")!;
  pass = localStorage.getItem("pass")!;
  country = localStorage.getItem("country")!;

  Privacy: any;

  constructor(
    private categoryService: CategoryService,
    public mainpage: MainPageComponent
  ) { }

  ngOnInit() { 
    this.PrivacyPolicy();
  }

  PrivacyPolicy(){
    let data: PolicyInput;
    data = new PolicyInput(1,this.country);
    this.categoryService.getPolicyAuth(data, this.userEmail, this.pass)
      .subscribe(
        data => {
          this.Privacy = data.body;
          console.warn(data);
        },
        error => {
          console.log(error);
        });
  }

}
