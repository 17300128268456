import { NNewsComponent } from './components/right-container/n-news/n-news.component';
import { LoaderService } from './services/loader/loader.service';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SvgIconRegistryService } from 'angular-svg-icon';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoading!: Observable<boolean>;

  constructor(
    public loaderService: LoaderService,
    private iconReg: SvgIconRegistryService) {

    this.iconReg.loadSvg(
      "../assets/svg/logo.svg",
      "logo"
    );

    this.iconReg.loadSvg(
      "../assets/svg/see.svg",
      "see"
    );

    this.iconReg.loadSvg(
      "../assets/svg/hide.svg",
      "hide"
    );

    this.iconReg.loadSvg(
      "../assets/svg/barcode_scan.svg",
      "barcode_scan"
    );

    this.iconReg.loadSvg(
      "../assets/svg/cancel.svg",
      "cancel"
    );

    this.iconReg.loadSvg(
      "../assets/svg/cancella_singolo.svg",
      "cancella_singolo"
    );

    this.iconReg.loadSvg(
      "../assets/svg/cancella_tutti.svg",
      "cancella_tutti"
    );

    this.iconReg.loadSvg(
      "../assets/svg/certificate_template.svg",
      "certificate_template"
    );

    this.iconReg.loadSvg(
      "../assets/svg/challenge.svg",
      "challenge"
    );

    this.iconReg.loadSvg(
      "../assets/svg/challenge_orange.svg",
      "challenge_orange"
    );

    this.iconReg.loadSvg(
      "../assets/svg/contatti.svg",
      "contatti"
    );

    this.iconReg.loadSvg(
      "../assets/svg/contatto_mail.svg",
      "contatto_mail"
    );

    this.iconReg.loadSvg(
      "../assets/svg/contatto_phone.svg",
      "contatto_phone"
    );

    this.iconReg.loadSvg(
      "../assets/svg/duration.svg",
      "duration"
    );

    this.iconReg.loadSvg(
      "../assets/svg/edit.svg",
      "edit"
    );

    this.iconReg.loadSvg(
      "../assets/svg/edit_my_data.svg",
      "edit_my_data"
    );

    this.iconReg.loadSvg(
      "../assets/svg/esc.svg",
      "esc"
    );

    this.iconReg.loadSvg(
      "../assets/svg/esc-01.svg",
      "esc-01"
    );

    this.iconReg.loadSvg(
      "../assets/svg/expand.svg",
      "expand"
    );

    this.iconReg.loadSvg(
      "../assets/svg/forum.svg",
      "forum"
    );

    this.iconReg.loadSvg(
      "../assets/svg/forumAdd.svg",
      "forumAdd"
    );

    this.iconReg.loadSvg(
      "../assets/svg/forumAdd1.svg",
      "forumAdd1"
    );

    this.iconReg.loadSvg(
      "../assets/svg/home.svg",
      "home"
    );

    this.iconReg.loadSvg(
      "../assets/svg/how.svg",
      "how"
    );

    this.iconReg.loadSvg(
      "../assets/svg/info.svg",
      "info"
    );

    this.iconReg.loadSvg(
      "../assets/svg/leaderboard.svg",
      "leaderboard"
    );

    this.iconReg.loadSvg(
      "../assets/svg/leave_rate.svg",
      "leave_rate"
    );

    this.iconReg.loadSvg(
      "../assets/svg/like.svg",
      "like"
    );

    this.iconReg.loadSvg(
      "../assets/svg/likes_active.svg",
      "likes_active"
    );

    this.iconReg.loadSvg(
      "../assets/svg/likes_active_my.svg",
      "likes_active_my"
    );

    this.iconReg.loadSvg(
      "../assets/svg/location.svg",
      "location"
    );

    this.iconReg.loadSvg(
      "../assets/svg/log_out.svg",
      "log_out"
    );

    this.iconReg.loadSvg(
      "../assets/svg/news.svg",
      "news"
    );

    this.iconReg.loadSvg(
      "../assets/svg/notifications.svg",
      "notifications"
    );

    this.iconReg.loadSvg(
      "../assets/svg/notifications_active.svg",
      "notifications_active"
    );

    this.iconReg.loadSvg(
      "../assets/svg/notifications_active-white.svg",
      "notifications_active-white"
    );

    this.iconReg.loadSvg(
      "../assets/svg/notifications-white.svg",
      "notifications-white"
    );

    this.iconReg.loadSvg(
      "../assets/svg/points.svg",
      "points"
    );

    this.iconReg.loadSvg(
      "../assets/svg/points_blu.svg",
      "points_blu"
    );

    this.iconReg.loadSvg(
      "../assets/svg/points_white.svg",
      "points_white"
    );

    this.iconReg.loadSvg(
      "../assets/svg/profile.svg",
      "profile"
    );

    this.iconReg.loadSvg(
      "../assets/svg/punti_catalogo.svg",
      "punti_catalogo"
    );

    this.iconReg.loadSvg(
      "../assets/svg/reward.svg",
      "reward"
    );

    this.iconReg.loadSvg(
      "../assets/svg/rewards_not_available.svg",
      "rewards_not_available"
    );

    this.iconReg.loadSvg(
      "../assets/svg/cancellare_dati_profilo.svg",
      "cancellare_dati_profilo"
    );

    this.iconReg.loadSvg(
      "../assets/svg/bye.svg",
      "bye"
    );

    this.iconReg.loadSvg(
      "../assets/svg/search_input.svg",
      "search_input"
    );

    this.iconReg.loadSvg(
      "../assets/svg/share.svg",
      "share"
    );

    this.iconReg.loadSvg(
      "../assets/svg/sottofondo.svg",
      "sottofondo"
    );

    this.iconReg.loadSvg(
      "../assets/svg/surveys.svg",
      "surveys"
    );

    this.iconReg.loadSvg(
      "../assets/svg/surveys_orange.svg",
      "surveys_orange"
    );

    this.iconReg.loadSvg(
      "../assets/svg/wait.svg",
      "wait"
    );

    this.iconReg.loadSvg(
      "../assets/svg/write.svg",
      "write"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/completed_1.svg",
      "completed_1"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/completed_2.svg",
      "completed_2"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/completed_3.svg",
      "completed_3"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/uncompleted_1.svg",
      "uncompleted_1"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/uncompleted_2.svg",
      "uncompleted_2"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Badge/uncompleted_3.svg",
      "uncompleted_3"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_1.svg",
      "rate_1"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_1_tap.svg",
      "rate_1_tap"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_2.svg",
      "rate_2"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_2_tap.svg",
      "rate_2_tap"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_3.svg",
      "rate_3"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_3_tap.svg",
      "rate_3_tap"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_4.svg",
      "rate_4"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_4_tap.svg",
      "rate_4_tap"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_5.svg",
      "rate_5"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_5_tap.svg",
      "rate_5_tap"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_circle_empty.svg",
      "rate_circle_empty"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_circle_full.svg",
      "rate_circle_full"
    );

    this.iconReg.loadSvg(
      "../assets/svg/CourseRating/rate_circle_half.svg",
      "rate_circle_half"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/1_home.svg",
      "1_home"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/Google_Play_Store.svg",
      "google_play"
    );

    this.iconReg.loadSvg(
      "../assets/svg/repeat_arrow.svg",
      "repeat_arrow"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/1_home-active.svg",
      "1_home-active"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/2_profile.svg",
      "2_profile"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/2_profile_hover.svg",
      "2_profile_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/3_leaderboard.svg",
      "3_leaderboard"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/3_search.svg",
      "3_search"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/3-leaderboard_hover.svg",
      "3-leaderboard_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/4_bookmarks.svg",
      "4_bookmarks"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/4_bookmarks_orange.svg",
      "4_bookmarks_orange"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/4_bookmarks_orange_filled.svg",
      "4_bookmarks_orange_filled"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/4_reward.svg",
      "4_reward"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/4-reward_hover.svg",
      "4-reward_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/5_news.svg",
      "5_news"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/5_news_hover.svg",
      "5_news_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/5_profile.svg",
      "5_profile"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/6_forum.svg",
      "6_forum"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/6_forum_hover.svg",
      "6_forum_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/7_challenge.svg",
      "7_challenge"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/7-challenge_hover.svg",
      "7-challenge_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/8_surveys.svg",
      "8_surveys"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/8_surveys_hover.svg",
      "8_surveys_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/9_how.svg",
      "9_how"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/9_how_hover.svg",
      "9_how_hover"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/10_contatti.svg",
      "10_contatti"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Menu/log_out.svg",
      "log_out"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Wizard/wizard_1.svg",
      "wizard_1"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Wizard/wizard_2.svg",
      "wizard_2"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Wizard/wizard_3.svg",
      "wizard_3"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Wizard/wizard_4.svg",
      "wizard_4"
    );

    this.iconReg.loadSvg(
      "../assets/svg/Wizard/wizard_5.svg",
      "wizard_5"
    );

  }


  ngOnInit(): void {
    
    //variabili localSorageche indicano se i component NNewsComponent e NForyouPillsComponent 
    //sono gia' stati chiamati
    localStorage.setItem('n_news', 'empty');
    localStorage.setItem('for_you', 'empty');
  }
}
