<div class="col-md-10 mx-auto mt-3">
  <table class="table table-responsive text-center table-borderless" style="display: table;">
    <tbody>
      <tr>
        <td style="vertical-align: middle; text-align: start;">
          <h1 class="col-sm-12" style="padding-top: 10px;padding-left: 0px;">
            {{'notification_notificationarc' | translate}}</h1>
        </td>
        <td style="vertical-align: middle; text-align: start;">
          <div style="cursor: pointer;" (click)="deleteAllNotification()">
            <svg-icon [name]="'cancella_tutti'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
            <span class="deleteall"> {{'notification_deleteall' | translate}}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div id="notifications_container" *ngIf="notifications?.length > 0">
  <!--TODAY-->
  <div *ngIf="today?.length > 0">
    <div class="col-md-10 mx-auto mt-3">
      <p class="day col-sm-12">{{'notification_today' | translate}}</p>
    </div>
    <div style="padding-left: 30px;padding-right: 30px;" class="col-md-10 mx-auto mt-3" *ngFor="let notify of today"
      [id]="notify.idNotifica">
      <span class="title" [ngClass]="{'hand': notify.pageId != 0 || !notify.seen, 'boldtext': !notify.seen}"
        (click)="GoToDetail(notify)">{{notify.title}}</span>
      <p class="text" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}" (click)="GoToDetail(notify)">
        {{notify.dateText}}
      </p>
      <span class="date">{{notify.dateSend}}</span>
      <div style="float: right;cursor: pointer;" (click)="deleteSingleNotification(notify.idNotifica)">
        <svg-icon class="deleteicon" [name]="'cancella_singolo'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
        <span class="deletetext"> {{'notification_delete' | translate}}</span>
      </div>
      <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
    </div>
  </div>

  <!--WEEK-->
  <div *ngIf="week?.length > 0">
    <div class="col-md-10 mx-auto mt-3">
      <p class="day col-sm-12">{{'notification_thisweek' | translate}}</p>
    </div>
    <div style="padding-left: 30px;padding-right: 30px;" class="col-md-10 mx-auto mt-3" *ngFor="let notify of week"
      [id]="notify.idNotifica">
      <span class="title" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}"
        (click)="GoToDetail(notify)">{{notify.title}}</span>
      <p class="text" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}" (click)="GoToDetail(notify)">
        {{notify.dateText}}
      </p>
      <span class="date">{{notify.dateSend}}</span>
      <div style="float: right;cursor: pointer;" (click)="deleteSingleNotification(notify.idNotifica)">
        <svg-icon class="deleteicon" [name]="'cancella_singolo'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
        <span class="deletetext"> {{'notification_delete' | translate}}</span>
      </div>
      <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
    </div>
  </div>

  <!--MONTH-->
  <div *ngIf="month?.length > 0">
    <div class="col-md-10 mx-auto mt-3">
      <p class="day col-sm-12">{{'notification_thismonth' | translate}}</p>
    </div>
    <div style="padding-left: 30px;padding-right: 30px;" class="col-md-10 mx-auto mt-3" *ngFor="let notify of month"
      [id]="notify.idNotifica">
      <span class="title" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}"
        (click)="GoToDetail(notify)">{{notify.title}}</span>
      <p class="text" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}" (click)="GoToDetail(notify)">
        {{notify.dateText}}</p>
      <span class="notify.idNotifica!=0 ? 'text hand' : 'text'">{{notify.dateSend}}</span>
      <div style="float: right;cursor: pointer;" (click)="deleteSingleNotification(notify.idNotifica)">
        <svg-icon class="deleteicon" [name]="'cancella_singolo'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
        <span class="deletetext"> {{'notification_delete' | translate}}</span>
      </div>
      <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
    </div>
  </div>

  <!--YEAR-->
  <div *ngIf="year?.length > 0">
    <div class="col-md-10 mx-auto mt-3">
      <p class="day col-sm-12">{{'notification_thisyear' | translate}}</p>
    </div>
    <div style="padding-left: 30px;padding-right: 30px;" class="col-md-10 mx-auto mt-3" *ngFor="let notify of year"
      [id]="notify.idNotifica">
      <span class="title" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}"
        (click)="GoToDetail(notify)">{{notify.title}}</span>
      <p class="text" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}" (click)="GoToDetail(notify)">
        {{notify.dateText}}
      </p>
      <span class="date">{{notify.dateSend}}</span>
      <div style="float: right;cursor: pointer;" (click)="deleteSingleNotification(notify.idNotifica)">
        <svg-icon class="deleteicon" [name]="'cancella_singolo'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
        <span class="deletetext"> {{'notification_delete' | translate}}</span>
      </div>
      <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
    </div>
  </div>

  <!--OTHER-->
  <div *ngIf="other?.length > 0">
    <div class="col-md-10 mx-auto mt-3">
      <p class="day col-sm-12">{{'notification_other' | translate}}</p>
    </div>
    <div style="padding-left: 30px;padding-right: 30px;" class="col-md-10 mx-auto mt-3" *ngFor="let notify of other"
      [id]="notify.idNotifica">
      <span class="title" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}"
        (click)="GoToDetail(notify)">{{notify.title}}</span>
      <p class="text" [ngClass]="{'hand': notify.pageId != 0  || !notify.seen, 'boldtext': !notify.seen}" (click)="GoToDetail(notify)">
        {{notify.dateText}}
      </p>
      <span class="date">{{notify.dateSend}}</span>
      <div style="float: right;cursor: pointer;" (click)="deleteSingleNotification(notify.idNotifica)">
        <svg-icon class="deleteicon" [name]="'cancella_singolo'" [svgStyle]="{ 'width.px': '20' }"></svg-icon>
        <span class="deletetext"> {{'notification_delete' | translate}}</span>
      </div>
      <div class="mt-3" style="height: 1px;background-color: #B9B6B6;"></div>
    </div>
  </div>
</div>
<h1 *ngIf="notifications?.length < 1" class="text-center" style="color: var(--main-text-color);">{{'notification_nonenotific' | translate}}</h1>