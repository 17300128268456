<h1 class="ml-3" style="text-transform: uppercase;">{{'menu_news' | translate}}</h1>
<div *ngFor="let notice of news; last as isLast" [id]="notice.id" class="row ml-0 mr-0"
  [routerLink]="['/mainpage',{ outlets: { primary: ['newsdetail', notice.id], sidebar: ['foryou'] } }]">
  <div class="container-fluid" style="height: 100px;">
    <div class="row">
      <div class="col-sm-5" style=" display: inline-flex;">
        <img src="{{baseURL}}{{notice.imageLargeFullPath}}"
          style="border-radius: 1rem; height: 95px; object-fit: cover;">
      </div>
      <div class="col-sm-7 d-inline-block align-top">
        <div class="container-fluid">
          <div class="row">
            <span class="date">{{notice.dateText}}</span>
          </div>
          <div class="row" style=" display: inline-flex;">

            <span class="title">
              {{notice.title}}
            </span>
          </div>
          <div class="row">
            <span class="category">{{notice.category}}</span>
          </div>
          <div class="row">
            <span class="category">{{notice.subCategory}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="w-100 mt-3 mb-3" *ngIf="!isLast"></mat-divider>
</div>
