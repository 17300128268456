<div class="surveyContainer">
<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<h1 class="col-md-6 mx-auto mt-3 titlesurvey">{{'menu_surveys' | translate}}</h1>
<div *ngIf="surveys?.length > 0">
<div *ngFor="let survey of surveys" [id]="survey.id" class="col-md-6 mx-auto mt-5" layout="row" layout-align="center center">
  <div style="width: 60%;float: left;">
    <p>{{survey.title}}</p>
    <span>{{survey.text}}</span>
  </div>
    <xiaomi-button mat-raised-button color="primary"
    (click)="GoToLink(survey.surveyLink)">{{'Buttons_go' | translate}}</xiaomi-button>
    <div style="height: 1px;background-color: #B9B6B6; margin-top:30px;"></div>
    <div class="overlay" *ngIf="popup" style="z-index: 99 !important;">
      <div class="popup">      
        <h2>{{'menu_surveys' | translate}}</h2>
        <a class="close" (click)="popup = false">&times;</a>
        <!--<div [innerHTML]="Terms"></div>-->
        <iframe [src]="urlSafe"></iframe>
      </div>
    </div>
</div>
</div>
<h1 *ngIf="surveys?.length == 0"  class="text-center" style="color: var(--main-text-color);">{{'home_sorry' | translate}}</h1>
</div>