import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xiaomi-imprint',
  templateUrl: './xiaomi-imprint.component.html',
  styleUrls: ['./xiaomi-imprint.component.scss']
})
export class XiaomiImprintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
