<app-news (newItemEvent)="addItem($event)" style="display: none;"></app-news>
<div class="row ml-0 mr-0 pl-0 pr-0" style="background-color: black;height: 40px;">

  <div backButton style="cursor: pointer;">
    <svg-icon class="icon-white rotated" [name]="'expand'" [svgStyle]="{ 'width.px': '20' }"
      style="margin-left: 20px; margin-top: 5px;margin-right: 3px;"></svg-icon>
    <label style="color: white;margin-top: 10px;">{{'Buttons_back' | translate}}</label>
  </div>
</div>
<div class ="mx-auto">
  <img src="{{baseURL}}{{notice?.imageLargeFullPath}}" style="max-height: 300px; object-fit: contain;">
  <div class="p-4">
    <p style="font-size: 25px; color:var(--main-text-color);margin-top: 20px;line-height: 1.2;">{{notice?.title}}</p>
    <div [innerHtml]="notice?.description"></div>
    <iframe *ngIf="linkAct" width="560" height="315" [src]="safeVideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>