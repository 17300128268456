import { LoaderService } from './loader.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { ComplexOuterSubscriber } from 'rxjs/internal/innerSubscribe';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    setTimeout(() => {
      this.loaderService.IsLoading.next(true);
      //console.warn('Loading started')
    }, 1);

    setTimeout(() => {
      this.loaderService.IsLoading.next(false);
      //console.warn('Loading finished')
    }, 3000);
    

    return next.handle(req).pipe(
      finalize(
        () =>{
          this.loaderService.IsLoading.next(false);
        }
      )
    );

  }

}
